
import React, {useEffect, useState} from 'react';
import ReactPlayer from 'react-player'
import { useParams } from "react-router-dom";
import firebase from "firebase";
import { Button, Spinner, Container, Row, Col, Tabs, Tab, Form, Modal } from "react-bootstrap";
import { firestore, storage } from "../services/firebase";
import Masonry from 'react-masonry-css'
import moment from 'moment';
import * as _ from "lodash";
import StarRatings from 'react-star-ratings';
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { NavLink, useHistory } from "react-router-dom";
import DatePicker from 'react-datepicker'; 
import "react-datepicker/dist/react-datepicker.css";

import { useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faCheckCircle } from "@fortawesome/free-solid-svg-icons/index";

import logoImg from '../assets/images/Logo.png';
import logoDark from '../assets/images/Logo_.png';
import axios from 'axios';

import Select from 'react-select';

import arrowBack from '../assets/images/arrow-back.png';
import intro from '../assets/images/breakers/intro.png';

import './Profile.scss';
import { ClearAll, rudderAnalytics } from '../services/trackers';

const goalOptions = [
  { value: 'Weight loss', label: "Weight loss (Lose fat and build muscle)" },
  { value: 'Muscle gain', label: "Muscle gain (Build mass and strength)" },
  { value: 'Healthy lifestyle', label: "Healthy lifestyle (Eat healthy and stay fit)" }
]
const fitnessOptions = [
  { value: 'Beginner', label: "Beginner (I don't exercise at all)" },
  { value: 'Intermediate', label: "Intermediate (I exercise frequently at home)" },
  { value: 'Professional', label: "Professional (Exercise is essential part of my routine)" }
]
const pregnantOptions = [
  { value: 'Pregnant', label: "I'm pregnant" },
  { value: 'Breast Feeding', label: "I'm breastfeeding" },
  { value: 'No', label: "I'm not pregnant or breastfeeding" }
]
const trimesterOptions = [
  { value: 'first', label: "1st trimester" },
  { value: 'second', label: "2nd trimester" },
  { value: 'third', label: "3rd trimester" }
]
const breastfeedingOptions = [
  { value: 'lessthan3months', label: "Less than 3 months" },
  { value: 'lessthan1year', label: "Less than 1 year" },
  { value: 'lessthan2year', label: "Less than 2 year" }
]
const dietOptions = [
  { value: 'Standard', label: "Standard (I enjoy eating everything)" },
  { value: 'Plant Based', label: "Plant Based (I avoid animal products)" },
  { value: 'Pescatarian', label: "Pescatarian (I avoid meat but i eat fish)" },
  { value: 'Low Carb', label: "Low Carb (I minimize carb intake)" },
  { value: 'Keto', label: "Keto (I avoid any carb intake)" }
]
const exerciseOptions = [
  { value: 'home', label: "I exercise at home" },
  { value: 'equipment', label: "I exercise at home using equipment" },
  { value: 'gym', label: "I exercise at the Gym" },
]
const medicalOptions = [
  { value: 'none', label: "none" },
  { value: 'Throid disease', label: "Throid disease" },
  { value: 'Pre-diabetes', label: "Pre-diabetes" },
  { value: 'Heart diabete', label: "Heart diabete" },
  { value: 'Polycystic ovaries', label: "Polycystic ovaries" },
  { value: 'Diabetes', label: "Diabetes" },
]
const allergiesOptions = [
  { value: 'none', label: "none" },
  { value: 'Tree nuts', label: "Tree nuts" },
  { value: 'Wheat', label: "Wheat" },
  { value: 'Peanuts', label: "Peanuts" },
  { value: 'Soy', label: "Soy" },
  { value: 'Eggs', label: "Eggs" },
  { value: 'Fish', label: "Fish" },
  { value: "Cow's milk", label: "Cow's milk" },
  { value: 'Shellfish', label: "Shellfish" },
]


export default function Profile(props) {

    const [kyc, setKyc] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    const [isSubmitLoaderKyc, setIsSubmitLoaderKyc] = useState(false);
    const [isImageChanged, setIsImageChanged] = useState(false);
    const [formValue, setFormValue] = useState({});
    const [kycValue, setKycValue] = useState({});
    const [location, setLocation] = useState();
    const [imageFile, setImageFile] = useState(false);
    const [imageError, setImageError] = useState('');
    const [userImage, setUserImage] = useState('https://firebasestorage.googleapis.com/v0/b/diet-challenge-amr.appspot.com/o/images%2Fdefault-image.png?alt=media&token=4e702cb6-9c5b-438b-b542-d9b3be9275de');
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('userDetails')));
    const [challenge, setChallenge] = useState(JSON.parse(localStorage.getItem('selectedChallenge')));
    const [isCheckout, setIsCheckout] = useState(useLocation().pathname.includes('checkout'));
    const [fitnessRequired, setFitnessRequired] = useState()
    const [goalRequired, setGoalRequired] = useState()
    const [required, setRequired] = useState()
    const [KYC, setKYC] = useState([])
    const [wizard, setWizard] = useState(1);


    const getLocation = async () => {

        if(user && user.country) return setLocation(user.country)

        try {
            const resp = await axios.get('https://ipapi.co/json/');
            setLocation(resp.data.country_code)

        }
        catch (err) {

            console.error(err);
            setLocation('N/A')
        }
    };

    useEffect(() => {

        var location = getLocation();

        firestore("constants").doc('kyc').get().then(snapshot => {

            var data = snapshot.data()
            var KYC = []
            Object.keys(data).map(property => {

                var obj = data[property]
                obj.name = property
                if(obj.values) obj.values = obj.values.map(d => {return {value: d, label: d}})
                KYC.push(obj)
            })
            console.log(KYC)
            setKYC(KYC.sort((a,b) => a.order-b.order))
        })

        return () => {

            console.log('Do some cleanup');
        }

    }, []);

    useEffect(() => {

        var obj = {}

        if(!user) return
        if(user.image) setUserImage(user.image);
        if(user.image) obj.image = user.image
        if(user.gender) obj.gender = user.gender
        if(user.birthday) obj.birthday = user.birthday
        if(user.height) obj.height = user.height
        if(user.weight) obj.weight = user.weight
        if(user.targetWeight) obj.targetWeight = user.targetWeight

        setFormValue(obj)

        var labels = {}
        for(var o in goalOptions) labels[goalOptions[o].value] = goalOptions[o].label
        for(var o in fitnessOptions) labels[fitnessOptions[o].value] = fitnessOptions[o].label
        for(var o in pregnantOptions) labels[pregnantOptions[o].value] = pregnantOptions[o].label
        for(var o in trimesterOptions) labels[trimesterOptions[o].value] = trimesterOptions[o].label
        for(var o in breastfeedingOptions) labels[breastfeedingOptions[o].value] = breastfeedingOptions[o].label
        for(var o in dietOptions) labels[dietOptions[o].value] = dietOptions[o].label
        for(var o in exerciseOptions) labels[exerciseOptions[o].value] = exerciseOptions[o].label
        for(var o in allergiesOptions) labels[allergiesOptions[o].value] = allergiesOptions[o].label
        for(var o in medicalOptions) labels[medicalOptions[o].value] = medicalOptions[o].label

        var kycValue = {
            selectedGoal: user.healthInfo && user.healthInfo.selectedGoal && user.healthInfo.selectedGoal.length? user.healthInfo.selectedGoal.map(d=>{return {value:d, label:labels[d]}}) : null,
            selectedFitness: user.healthInfo && user.healthInfo.selectedFitness && user.healthInfo.selectedFitness.length? user.healthInfo.selectedFitness.map(d=>{return {value:d, label:labels[d]}}) : null,
            isPregnantOrBreastfeeding: user.healthInfo && user.healthInfo.isPregnantOrBreastfeeding && user.healthInfo.isPregnantOrBreastfeeding.length? user.healthInfo.isPregnantOrBreastfeeding.map(d=>{return {value:d, label:labels[d]}}) : null,
            pregnancyTrimester: user.healthInfo && user.healthInfo.pregnancyTrimester? [{value:user.healthInfo.pregnancyTrimester, label:labels[user.healthInfo.pregnancyTrimester]}] : null,
            breastFeedingFrom: user.healthInfo && user.healthInfo.breastFeedingFrom? [{value:user.healthInfo.breastFeedingFrom, label:labels[user.healthInfo.breastFeedingFrom]}] : null,
            dietType: user.healthInfo && user.healthInfo.dietType? [{value:user.healthInfo.dietType.replace(/\s+$/, ''), label:labels[user.healthInfo.dietType.replace(/\s+$/, '')]}] : null,
            exerciseType: user.healthInfo && user.healthInfo.exerciseType? [{value:user.healthInfo.exerciseType.replace(/\s+$/, ''), label:labels[user.healthInfo.exerciseType.replace(/\s+$/, '')]}] : null,
            medicalConditions: user.healthInfo && user.healthInfo.medicalConditions && user.healthInfo.medicalConditions.length? user.healthInfo.medicalConditions.map(d=>{return {value:d, label:labels[d]}}) : null,
            selectedAllergies: user.healthInfo && user.healthInfo.selectedAllergies && user.healthInfo.selectedAllergies.length? user.healthInfo.selectedAllergies.map(d=>{return {value:d, label:labels[d]}}) : null,
        }

        setKycValue(user.kyc || {})

        return () => {

            console.log('Do some cleanup');
        }

    }, user);


    const [userDetails, setUserDetails] = useState({});


    //let { challengeId } = useParams();


    function fbImage(imageLink){

        if(!imageLink) return imageLink
        var query = imageLink.split('asid=')[1]
        if(!query) return imageLink
        var fbId = query.split('&')[0]
        if(!fbId) return imageLink
            
        return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`
    }

    async function logout() {
        rudderAnalytics.track('User_Sign_Out');
        localStorage.clear();
        await firebase.auth().signOut();
        ClearAll();
    }

    async function submitForm(e) {

        e.stopPropagation();
        e.preventDefault();

        setIsSubmitLoader(true);

        if (isImageChanged && imageFile) {
            const uploaded = await storage().ref().child(`/users/${user.id}`).put(imageFile, {cacheControl: 'public,max-age=31536000'});
            const child = await storage().ref().child(uploaded.metadata.fullPath);
            formValue.image = await child.getDownloadURL();
        } else if (!imageFile && !user.image) {
            //setImageError('image is required');
            //return;
        }
        
        await firestore('users').doc(user.id).update(formValue);
        setIsSubmitLoader(false);

        //setKyc(true)
    }

    async function submitKyc(e) {
        e.stopPropagation();
        e.preventDefault();

        for(var property of KYC){ if(!kycValue[property.name]) return setRequired(property.name) }

        setIsSubmitLoaderKyc(true);

        var healthInfo = {

            selectedGoal: kycValue.selectedGoal? kycValue.selectedGoal.map(d=>d.value) : [],
            selectedFitness: kycValue.selectedFitness? kycValue.selectedFitness.map(d=>d.value) : [],
            isPregnantOrBreastfeeding: kycValue.isPregnantOrBreastfeeding? kycValue.isPregnantOrBreastfeeding.map(d=>d.value) : [],
            pregnancyTrimester: kycValue.pregnancyTrimester && kycValue.pregnancyTrimester[0]? kycValue.pregnancyTrimester[0].value : '',
            breastFeedingFrom: kycValue.breastFeedingFrom && kycValue.breastFeedingFrom[0]? kycValue.breastFeedingFrom[0].value : '',
            dietType: kycValue.dietType && kycValue.dietType[0]? kycValue.dietType[0].value : '',
            exerciseType: kycValue.exerciseType && kycValue.exerciseType[0]? kycValue.exerciseType[0].value : '',
            medicalConditions: kycValue.medicalConditions? kycValue.medicalConditions.map(d=>d.value) : [],
            selectedAllergies: kycValue.selectedAllergies? kycValue.selectedAllergies.map(d=>d.value) : [],
        }

        await firestore('users').doc(user.id).update({kyc: kycValue});
        setIsSubmitLoaderKyc(false);

        if(window.location.href.includes('profile')){

            //just close kyc modal
            setKyc(false)
        }
        else{

            //reload to route after kyc filled
            window.location.reload();
        }
    }

    function onImageUpload(e) {
        fileSelectHandler(e)
    }

    function parseFile(file) {

        // var fileType = file.type;
        var imageName = file.name;

        var isGood = (/\.(?=gif|jpg|png|jpeg)/gi).test(imageName);
        if (isGood) {
            // Thumbnail Preview
            document.getElementById('file-image').classList.remove("hidden");
            document.getElementById('file-image').src = URL.createObjectURL(file);
        } else {
            document.getElementById('file-image').classList.add("hidden");
            document.getElementById('start').classList.remove("hidden");
            document.getElementById('response').classList.add("hidden");
            document.getElementById("file-upload-form").reset();
        }
    }

    async function fileSelectHandler(e) {
        // Fetch FileList object
        var files = e.target.files || e.dataTransfer.files;

        // Process all File objects
        for (var i = 0, f; f = files[i]; i++) {
            try {
                const compressedFile = f//await imageCompression(f, options);
                setImageError('');
                setImageFile(compressedFile);
                parseFile(compressedFile);
            } catch (error) {
                console.log('error fileSelectHandler', error);
                setImageError('please upload a valid image file');
            }
        }
        setIsImageChanged(true);
    }

  return (<>

        <div id="Header" className="dark">
            <Container fluid="md">
                <Row className="header justify-content-md-left">
                    <NavLink key={'/'} to={'/'} style={{cursor:'pointer'}}>
                        <div className="main">
                            <img height="60" src={logoDark}/>
                        </div>
                    </NavLink>
                    <div className="account">

                        <NavLink key={'/profile'} to={'/profile'} style={{cursor:'pointer'}}>
                            <img className="user-image" style={{cursor:'pointer'}}
                                height="90"
                                src={fbImage(user.image)}
                            />
                            <div className="user-name">{user.name}</div>
                        </NavLink>

                        <Button className="sign-out" size="sm" variant="primary" onClick={() => logout()}>Sign out</Button>

                    </div>
                </Row>
            </Container>
        </div>

        <div id="profile">
            <Container fluid="md" style={{display: user.name === 'Asaleya Amr'? 'block' : 'block'}}>

                {loader ? <div className="loader text-center"><Spinner animation="border" size={'sm'} role="status" /></div> : 

                <Form className="form" onSubmit={(e) => {
                    submitForm(e)
                }}>
                    <Form.Group>

                        <div className="d-flex photo-container" style={{position: 'relative', cursor: 'pointer'}}>

                            <input id="file-upload" type="file" hidden name="fileUpload" accept="image/*" onChange={(e) => onImageUpload(e)} />

                            <label htmlFor="file-upload" style={{position: 'relative', cursor: 'pointer'}}>
                                <img id="file-image" src={fbImage(userImage)} className="user-image hidden" />
                                <span style={{color: 'cadetblue', fontSize: '30px',position: 'absolute',right: 0,bottom: 0, pointerEvents:'none'}}>
                                    <FontAwesomeIcon icon={faCamera} className="mr-2" />
                                </span>
                            </label>
                        </div>
                        {imageError && <div className="m-2 text-center text-danger">{imageError}</div>}
                        <br/>

                        <Form.Row>
                        <Form.Label>Gender</Form.Label>
                            <div className="selectWrapper">
                                <Form.Control required as="select"
                                    value={formValue.gender} onChange={e => {
                                        const formValueClone = _.clone(formValue);
                                        formValueClone.gender = e.target.value;
                                        setFormValue(formValueClone);
                                    }}>
                                    <option value="" disabled selected hidden>gender</option> 
                                    <option value={'male'} key={'male'}>{'male'}</option>
                                    <option value={'female'} key={'female'}>{'female'}</option>
                                </Form.Control>
                            </div>
                        </Form.Row>

                        <Form.Row>
                        <Form.Label>Birthday</Form.Label>
                        <DatePicker 
                            required
                            placeholderText="birthday" 
                            showMonthDropdown
                            showYearDropdown
                            scrollableYearDropdown
                            scrollableMonthDropdown
                            yearDropdownItemNumber={50}
                            maxDate={moment().subtract('years', 10).toDate()} 
                            selected={formValue.birthday?moment(formValue.birthday).toDate():''} 
                            onChange={date => {
                            const formValueClone = _.clone(formValue);
                            formValueClone.birthday = date? moment(date).format('MMM DD, YYYY') : '';
                            setFormValue(formValueClone);
                        }}/>
                        </Form.Row>

                        <Form.Row>
                        <Form.Label>Height</Form.Label>
                        <Form.Control placeholder="height" required type="number" min="0" max="300" value={formValue.height} onChange={e => {
                            const formValueClone = _.clone(formValue);
                            formValueClone.height = +e.target.value;
                            setFormValue(formValueClone);
                        }} />
                        </Form.Row>

                        <Form.Row>
                        <Form.Label>Weight</Form.Label>
                        <Form.Control placeholder="weight kg" required type="number" min="0" max="500" value={formValue.weight} onChange={e => {
                            const formValueClone = _.clone(formValue);
                            formValueClone.weight = +e.target.value;
                            setFormValue(formValueClone);
                        }} />
                        </Form.Row>

                        <Form.Row>
                        <Form.Label>Target weight</Form.Label>
                        <Form.Control placeholder="weight kg" required type="number" min="0" max="500" value={formValue.targetWeight} onChange={e => {
                            const formValueClone = _.clone(formValue);
                            formValueClone.targetWeight = +e.target.value;
                            setFormValue(formValueClone);
                        }} />
                        </Form.Row>

                    </Form.Group>
                    <Button variant="info" type="submit" className="float-right submit-button">
                        {isSubmitLoader ? <Spinner animation="border" size={'sm'} role="status" /> : isCheckout?'Next':'Save'}
                    </Button>
                </Form>}
            </Container>


            <Modal show={kyc} onHide={() => setKyc(false)}>

                <Form className="form kyc" onSubmit={(e) => {submitKyc(e)}}>
                    <Form.Group>

                        {KYC.map(property => <Form.Row>
                            <Form.Label>{property.title}{required === property.name && <span style={{color: '#a00', marginLeft: '10px'}}>* required</span>}</Form.Label>
                            {property.type === 'date'? 
                            <DatePicker 
                                required
                                placeholderText="birthday" 
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                scrollableMonthDropdown
                                yearDropdownItemNumber={50}
                                maxDate={moment().subtract('years', 10).toDate()} 
                                selected={formValue.birthday?moment(formValue.birthday).toDate():''} 
                                onChange={date => {
                                const formValueClone = _.clone(formValue);
                                formValueClone[property.name] = date? moment(date).format('MMM DD, YYYY') : '';
                                setFormValue(formValueClone);
                            }}/> :
                            property.values && kycValue[property.name] && kycValue[property.name].map ? <Select options={property.values} className="select" value={kycValue[property.name] && kycValue[property.name].map(d => {return {label: d, value: d}})} onChange={e => {
                                const formValueClone = _.clone(kycValue);
                                formValueClone[property.name] = [e.value];
                                setKycValue(formValueClone);
                            }}/> :

                            <Form.Control placeholder="" value={kycValue[property.name]} onChange={e => {
                                const formValueClone = _.clone(formValue);
                                formValueClone[property.name] = [e.target.value];
                                setFormValue(formValueClone);
                            }} />}

                        </Form.Row>)}

                    </Form.Group>
                    <Button variant="info" type="submit" className="float-right submit-button">
                        {isSubmitLoaderKyc ? <Spinner animation="border" size={'sm'} role="status" /> : isCheckout?'Next':'Save'}
                    </Button>
                </Form>
            </Modal>

            {/*<div id="kyc" style={{ width: '400px', margin: '10px auto'}}>

                <div style={{display: 'flex', justifyContent: 'space-evenly', height: '40px'}}>
                    <span style={{padding: 0, fontWeight: 'bold', cursor: 'pointer'}} className="float-right submit-button" onClick={() => {setWizard(wizard-1)}}>
                        </span>
                    <div style={{margin: '0 auto', textAlign: 'center', fontVariant: 'all-small-caps', fontSize: '20px', color: '#0F3058', opacity: .8, marginTop: '-5px'}}>{KYC[wizard-1] && KYC[wizard-1].category}</div>
                    <span style={{float: 'right'}}><span style={{color: '#0CADA1'}}>{wizard}</span> / {KYC.length}</span>
                </div>

                <div style={{marginTop: '10px', width: '100%', height: '5px', background: '#f0f0f0', borderRadius: '3px'}}>
                    
                    <div style={{width: ((wizard/KYC.length)*100)+'%', height: '5px', background: '#0CADA1', borderRadius: '3px'}}>
                        
                    </div>
                </div>

                <Form className="form kyc" style={{marginTop: '0px'}} onSubmit={(e) => {
                    //submitKyc(e)
                }}>
                    <Form.Group>

                        {KYC.map((property, i) => <Form.Row style={{display: wizard === i+1? 'block' : 'none'}}>
                            {property.type === 'breaker' &&
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <img src = {{intro: intro}[property.name]} style={{width: 'auto', margin: '20px auto 20px', boxShadow: '0 0 30px 30px #F9FAFB', pointerEvents: 'none'}}/>
                            </div>}
                            <Form.Label style={{width: '100%', textAlign: 'center', fontSize: '20px', fontWeight: 'bold', fontVariant: 'normal', color: '#0F3058'}}>{property.title}{required === property.name && <span style={{color: '#0CADA1', marginLeft: '10px'}}>* required</span>}</Form.Label>
                            <div style={{marginBottom: '20px', textAlign: 'center', fontVariant: 'normal', color: '#4F7098'}}>{property.tagline}</div>
                            {property.type === 'breaker'? <div>

                                {property.values && property.values.map((val, i) => <div style={{width: '100%', textAlign: i%2? 'right':'left'}}>
                                    <div style={{display: 'inline-block', boxShadow: '0 3px 6px 3px #f3f3f3', width: '80%', textAlign: 'left', borderRadius: '5px', margin: '10px', padding: '10px', color: '#777', fontSize: '12px', fontWeight: 'bold'}}><FontAwesomeIcon icon={faCheckCircle} className="mr-2" style={{fontSize: '20px', color: '#0A9086', verticalAlign: 'middle'}} />
                                        {val.value}
                                    </div>
                                </div>)}
                            </div> : 
                            property.type === 'date'? 
                            <DatePicker 
                                required
                                placeholderText="birthday" 
                                showMonthDropdown
                                showYearDropdown
                                scrollableYearDropdown
                                scrollableMonthDropdown
                                yearDropdownItemNumber={50}
                                maxDate={moment().subtract('years', 10).toDate()} 
                                selected={formValue.birthday?moment(formValue.birthday).toDate():''} 
                                onChange={date => {
                                const formValueClone = _.clone(formValue);
                                formValueClone[property.name] = date? moment(date).format('MMM DD, YYYY') : '';
                                setFormValue(formValueClone);
                            }}/> :
                            property.values && kycValue[property.name] && kycValue[property.name].map ? <Select options={property.values} className="select" value={kycValue[property.name] && kycValue[property.name].map(d => {return {label: d, value: d}})} onChange={e => {
                                const formValueClone = _.clone(kycValue);
                                formValueClone[property.name] = [e.value];
                                setKycValue(formValueClone);
                            }}/> :

                            <Form.Control placeholder="" value={kycValue[property.name]} onChange={e => {
                                const formValueClone = _.clone(formValue);
                                formValueClone[property.name] = [e.target.value];
                                setFormValue(formValueClone);
                            }} />}

                        </Form.Row>)}

                    </Form.Group>

                    <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                        
                        <Button variant="secondary" style={{background: '#0CADA1', border: 'none', borderRadius: '30px', width: '100%'}} className="float-right submit-button" onClick={(e) => {wizard === KYC.length? submitForm(e) : (KYC[wizard-1].type == 'breaker' || kycValue[KYC[wizard-1].name]) ? setWizard(wizard+1) : alert('please enter required fields')}}>
                            {isSubmitLoader ? <Spinner animation="border" size={'sm'} role="status" /> : wizard==KYC.length?(isCheckout?'Next':'Submit') : wizard== 1? 'Get Started':'Continue'}
                        </Button>
                    </div>
                    
                </Form>
            </div>*/}

        </div>
  </>);
}