/** @format */

import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Spinner, Col, Form } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faAngleUp,
    faAngleDown,
    faInfoCircle,
    faCheckSquare,
    faCommentDots,
    faHeadset,
    faImage,
    faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import * as firebase from 'firebase';
import imageCompression from "browser-image-compression";
import { firestore, storage } from "../services/firebase";
import StarRatings from "react-star-ratings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { ReactComponent as Support } from '../../assets/icons/support.svg';
import * as _ from "lodash";

import moment from "moment";

export function Support(props) {
    const totalDays = 30;
    const [isImageChanged, setIsImageChanged] = useState(false);
    const [questionImage, setquestionImage] = useState("");
    const [imageFile, setImageFile] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    const [isCloseLoader, setIsCloseLoader] = useState(false);
    const [expand, setExpand] = useState(false);
    const [imageError, setImageError] = useState("");
    const [newMessage, setNewMessage] = useState("");
    const [user, setUser] = useState(
        JSON.parse(localStorage.getItem("userDetails"))
    );
    const initFormValue = {
        challengeId: "",
        challengeName: "",
        question: "",
        answer: "",
    };
    const [formValue, setFormValue] = useState(initFormValue);
    const fileInput = useRef(null);

    useEffect(() => {
        var unsub = setFormData();

        setTimeout(() => {
            if (document.getElementById("user-info"))
                document.getElementById("user-info").style.display = "block";
        }, 1000);
        setTimeout(() => {
            if (document.getElementById("user-info"))
                document.getElementById("user-info").style.left = 0;
        }, 2000);

        return () => {
            //unsub()
        };
    }, []);

    useEffect(() => {
        scrollBottom();

        return () => {};
    }, [expand, formValue]);

    function scrollBottom() {
        var objDiv = document.getElementById("ticket-thread");
        if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;

        setTimeout(() => {
            var objDiv = document.getElementById("ticket-thread");
            if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
        }, 1000);

        setTimeout(() => {
            var objDiv = document.getElementById("ticket-thread");
            if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
        }, 3000);
    }

    function addMessage(message) {
        console.log(message);
        setNewMessage({
            message: message,
            isWeb: true,
        });
    }

    async function setFormData() {
        if (!user || !user.id) return;
        setLoader(true);

        // console.log(user)
        var thread = await firestore("users_support").doc(user.id).get();
        var questionData = thread.exist ? thread.data() : {};
        questionData.id = user.id;

        //var snapshot = await firestore('users_support').doc(user.id).collection('messages').get()
        //questionData.messages = snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : []

        var msgs = firestore("users_support")
            .doc(user.id)
            .collection("messages")
            .onSnapshot(function (snapshot) {
                questionData.messages = snapshot.docs.length
                    ? snapshot.docs.map((d) => {
                            var obj = d.data();
                            obj.id = d.id;
                            return obj;
                      })
                    : [];
                const method = function (item) {
                    return moment.utc(item.datetime).format();
                };
                questionData.messages = _.orderBy(questionData.messages, method, "asc");

                //setQuestionData(questionData);
                setFormValue(questionData);
                addMessage("");
                scrollBottom();
                setLoader(false);
            });

        //var snapshot = await firestore('users').doc(user.id).get()
        //if(snapshot.exists) questionData.user = snapshot.data()

        return msgs;
    }

    async function closeTicket() {
        setIsSubmitLoader(true);

        await firestore("users_support")
            .doc(props.questionData.key)
            .update({ closed: true });

        setIsCloseLoader(false);
        props.handleClose(true);
    }

    async function submitForm(e) {
        e.stopPropagation();
        e.preventDefault();

        setIsSubmitLoader(true);
        //if (props.questionData.key) {

        //const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        //formValue.answerBy = 'nutritionist';
        //formValue.answerById = userDetails.id;
        newMessage.datetime = moment.utc().format();
        newMessage.created = firebase.firestore.FieldValue.serverTimestamp();
        await firestore("users_support")
            .doc(formValue.id)
            .collection("messages")
            .add(newMessage);

        /*} else {
            const userDetails = JSON.parse(localStorage.getItem('userDetails'));
            formValue.nutritionistId = userDetails.id;
            formValue.datetime = moment().format();
            await firestore('questions').add(formValue);
        }*/

        setIsSubmitLoader(false);
        //props.handleClose(true);

        // } else {
        //     setImageError('image is required');
        // }
    }

    async function handleFileUpload(e) {
        e.stopPropagation();
        e.preventDefault();

        setIsSubmitLoader(true);

        console.log(e.target.files[0].name);

        const fileName = e.target.files[0].name + "-" + moment().unix();
        const imageFile = (e.target.files || e.dataTransfer.files)[0];

        const uploaded = await storage()
            .ref()
            .child(`/support/${fileName}`)
            .put(imageFile, { cacheControl: "public,max-age=31536000" });
        const child = await storage().ref().child(uploaded.metadata.fullPath);
        const image = await child.getDownloadURL();

        var _newMessage = _.clone(newMessage);
        _newMessage.image = image;
        _newMessage.datetime = moment.utc().format();
        _newMessage.created = firebase.firestore.FieldValue.serverTimestamp();
        await firestore("users_support")
            .doc(formValue.id)
            .collection("messages")
            .add(_newMessage);
            

        setIsSubmitLoader(false);
    }

    function fbImage(imageLink) {
        if (!imageLink) return imageLink;
        var query = imageLink.split("asid=")[1];
        if (!query) return imageLink;
        var fbId = query.split("&")[0];
        if (!fbId) return imageLink;

        return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`;
    }

    return (
        <>
            <div
                id="contact-support"
                className="contact-support"
                onClick={() => {
                    setExpand(!expand);
                }}
            >
                <FontAwesomeIcon icon={expand ? faAngleDown : faHeadset} size="1x" />
            </div>
            {expand && (
                <div className={"support-float" + (expand ? " expanded" : "")}>
                    <div className="shading"></div>
                    <Modal.Header
                        closeButton={false /*expand*/}
                        onClick={() => {
                            setExpand(!expand);
                        }}
                    >
                        <Modal.Title className="h6">{expand && "Support"}</Modal.Title>
                        {expand && "X"}
                    </Modal.Header>
                    <Modal.Body id="ticket-thread">
                        {formValue.user && (
                            <div
                                id="user-info"
                                style={{
                                    transition: ".3s",
                                    display: "none",
                                    left: "-400px",
                                    position: "fixed",
                                    top: "0",
                                    padding: "2em",
                                    background: "beige",
                                    borderBottomRightRadius: "2em",
                                    border: "1px solid #aaa",
                                }}
                            >
                                <div
                                    style={{ fontSize: ".9em", opacity: ".5", padding: ".5em 0" }}
                                >
                                    <FontAwesomeIcon
                                        icon={faInfoCircle}
                                        size="1x"
                                        className=""
                                        style={{ opacity: ".3" }}
                                    />{" "}
                                    user info{" "}
                                </div>
                                <div>
                                    {/*<img src={fbImage(formValue.user.image)} style={{maxWidth:'60px', maxHeight:'60px'}}/>*/}
                                    {formValue.user.name}
                                </div>
                                <div>{formValue.user.email}</div>
                                <div>
                                    {formValue.user.dielCode}
                                    {formValue.user.mobileNumber}
                                </div>

                                <br />
                                {formValue.user.deviceDetails && (
                                    <>
                                        <div
                                            style={{
                                                fontSize: ".9em",
                                                opacity: ".5",
                                                padding: ".5em 0",
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                size="1x"
                                                className=""
                                                style={{ opacity: ".3" }}
                                            />{" "}
                                            device info{" "}
                                        </div>
                                        <div>
                                            {formValue.user.deviceDetails.brand} (
                                            {formValue.user.deviceDetails.version})
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                        {loader ? <Spinner animation="border" size={"sm"} role="status" style={{margin: '100px auto', display: 'block'}} /> : 
                        <Form className="form">
                            {formValue.messages && formValue.messages.length
                                ? formValue.messages.map((message) => (
                                        <Form.Group
                                            key={message.id}
                                            className={
                                                message.isSupport
                                                    ? "support-msg from-support"
                                                    : "support-msg"
                                            }
                                            //style={!message.isSupport?
                                            //{borderRadius: '1em', background:'#e0e0e0', padding:'1em', marginRight:'3em'} :
                                            //{borderRadius: '1em', background:'#42A2F9', padding:'1em', marginLeft:'3em', color: '#fff'}}
                                        >
                                            <div className="datetime">
                                                {moment
                                                    .utc(message.datetime)
                                                    .fromNow()
                                                    .replace("a few seconds ago", "now")}
                                            </div>
                                            <div>{message.message}</div>
                                            {message.image && (
                                                <img
                                                    src={message.image}
                                                    style={{ maxWidth: "100%", marginTop: "1em" }}
                                                />
                                            )}
                                        </Form.Group>
                                  ))
                                : null}
                        </Form>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Form
                            className="form"
                            onSubmit={(e) => {
                                submitForm(e);
                            }}
                        >
                            <div style={{ display: "flex" }}>
                                <Form.Control
                                    as="textarea"
                                    rows="2"
                                    required
                                    style={{ borderColor: "#eee", borderRadius: "10px" }}
                                    placeholder={"send message to support"}
                                    value={newMessage.message}
                                    onChange={(e) => {
                                        addMessage(e.target.value);
                                    }}
                                />

                                <input
                                    ref={fileInput}
                                    onChange={handleFileUpload}
                                    type="file"
                                    style={{ display: "none" }}
                                    multiple={false}
                                />

                                <Button
                                    variant="light"
                                    className="float-left"
                                    onClick={() => fileInput.current.click()}
                                    style={{ border: "1px solid #ddd" }}
                                >
                                    <>
                                        <FontAwesomeIcon icon={faImage} size="1x" />
                                    </>
                                </Button>

                                <Button
                                    variant="light"
                                    type="submit"
                                    className={
                                        "float-right submit-button" +
                                        (newMessage.message && newMessage.message.length
                                            ? " show"
                                            : "")
                                    }
                                >
                                    {isSubmitLoader ? (
                                        <Spinner animation="border" size={"sm"} role="status" />
                                    ) : (
                                        <>
                                            <FontAwesomeIcon
                                                icon={faPaperPlane}
                                                size="1x"
                                                className=""
                                            />
                                        </>
                                    )}
                                </Button>
                            </div>
                        </Form>
                    </Modal.Footer>
                </div>
            )}
        </>
    );
}

export default Support;
