
import React, {useEffect, useState} from 'react';
//import ReactPlayer from 'react-player'

import { Button, Spinner, Container, Modal, Form, Row, Col } from "react-bootstrap";
import { firestore, storage } from "../services/firebase";
import Masonry from 'react-masonry-css'
import moment from 'moment';
import * as _ from "lodash";
import firebase from "firebase";
import { NavLink, useHistory } from "react-router-dom";

import Slider from "react-slick";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faComments} from '@fortawesome/free-solid-svg-icons'
import logoImg from '../assets/home/Header/welnes-Logo.png';
import logoImgAr from '../assets/home/Header/Welnes Arabic Logo-01.png';
//import logoImg from '../assets/images/Logo.png';
import appImg from '../assets/images/app.png';
import like from '../assets/images/dc-like-grey.png';
import likeActive from '../assets/images/dc-like.png';
import facebook from '../assets/images/fb.png';
import googlePlay from '../assets/home/Header/_play.png';
import appleStore from '../assets/home/Header/_store.png';
import comingsoon from '../assets/images/comingsoon.png';
import './Start.scss';


import Img11 from '../assets/images/upload_plan.png';
import Img12 from '../assets/images/create_program.png';
import Img13 from '../assets/images/follow_up.png';
import Img21 from '../assets/images/group.png';
import Img22 from '../assets/images/measurements.png';
import Img23 from '../assets/images/leaderboard.png';

import Img_clients from '../assets/icons/clients.png';
import Img_countries from '../assets/icons/countries.png';
import Img_meals from '../assets/icons/meals.png';
import Img_exercises from '../assets/icons/exercises.png';
import Img_water_cups from '../assets/icons/water_cups.png';

import Img_capsules from '../assets/icons/capsules.png';
import Img_answer from '../assets/icons/answer.png';
import Img_quiz from '../assets/icons/quiz.png';

import HeaderBg from '../assets/home/Header/Header-Image.png';
import HeaderBgAr from '../assets/home/Header/Header-Image-ar.png';
import HeaderApp from '../assets/home/Header/Phone-Header-Image.png';
import Img_3 from '../assets/box/525 X 525_Welnes Box.png';

import why_1 from '../assets/home/Why Welnes Icons/Guy-with-an-apple.png';
import why_2 from '../assets/home/Why Welnes Icons/Hand-with-stars.png';
import why_3 from '../assets/home/Why Welnes Icons/Discount.png';
import why_4 from '../assets/home/Why Welnes Icons/Plan.png';
import why_5 from '../assets/home/Why Welnes Icons/Gym-01.png';
import why_6 from '../assets/home/Why Welnes Icons/Community.png';

import how_1 from '../assets/home/How it works Illustration/Step-one.png';
import how_2 from '../assets/home/How it works Illustration/Step-Two.png';
import how_3 from '../assets/home/How it works Illustration/Step-Three.png';

import Products_1 from '../assets/home/Our Products/apple-iphone-14-pro-2022-medium.png';
import Products_2 from '../assets/home/Our Products/apple-iphone-14-pro-2022-medium.png';
import Products_3 from '../assets/home/Our Products/apple-iphone-14-pro-2022-medium.png';

import Products_box from '../assets/home/Our Products/Welnes-Box.png';

import Reviews_1 from '../assets/home/Review/Review-01.png';
import Reviews_2 from '../assets/home/Review/Review-02.png';

import Coaches_1 from '../assets/home/Coaches/Fathy.png';
import Coaches_2 from '../assets/home/Coaches/Amira.png';
import Coaches_3 from '../assets/home/Coaches/Waad.png';
import Coaches_4 from '../assets/home/Coaches/Bahaa.png';
import Coaches_5 from '../assets/home/Coaches/Kareem.png';

import PreFooterBg from '../assets/home/Pre Footer/Image.png';
import footerapple from '../assets/home/Pre Footer/apple.png';
import footergoogle from '../assets/home/Pre Footer/google.png';

import Social_1 from '../assets/home/Social Media Icons/Facebook.png';
import Social_2 from '../assets/home/Social Media Icons/Linkedin-01.png';
import Social_3 from '../assets/home/Social Media Icons/Instagram-01.png';
import Social_4 from '../assets/home/Social Media Icons/Youtube-01.png';


import './Home.scss';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export default function Home(props) {

    const [loader, setLoader] = useState(true);
    const [post, setPosted] = useState();

    const [feed, setFeed] = useState([]);
    const [challenges, setChallenges] = useState([]);
    const [nutritionists, setNutritioinists] = useState([]);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('userDetails')));


    const [wizard, setWizard] = useState(0);
    const [nSteps, setnSteps] = useState(2);
    const [formValue, setFormValue] = useState({});

    const [imageFile, setImageFile] = useState([]);
    const [imageError, setImageError] = useState('');
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    const [isImageChanged, setIsImageChanged] = useState(false);
    const [nutritionistStatus, setNutritionistStatus] = useState();

    useEffect(() => {

        if (window.location.href.indexOf("register") > -1) openWizard()

        if(user) getUserData()

        return () => {

            console.log('Do some cleanup');
            //unsubscribes.map((unsubscribe) => unsubscribe())
        }

    }, []);


    async function getUserData() {

        const querySnapshot = await firestore('users').doc(user.id).get();

        setFormValue({
            name: querySnapshot.data().name || '',
            mobileNumber: querySnapshot.data().mobileNumber || '',
            nutritionistTitle: querySnapshot.data().nutritionistTitle || '',
            nutritionistBio: querySnapshot.data().nutritionistBio || '',
            //assistantName: querySnapshot.data().assistantName || '',
        })

        setNutritionistStatus(querySnapshot.data().nutritionist || '')
    }


    function fbImage(imageLink){

        if(!imageLink) return imageLink
        if(!imageLink.split) return imageLink
        var query = imageLink.split('asid=')[1]
        if(!query) return imageLink
        var fbId = query.split('&')[0]
        if(!fbId) return imageLink
            
        return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`
    }

    async function logout() {
        localStorage.clear();
        await firebase.auth().signOut();
    }




    function onImageUpload(e) {
        fileSelectHandler(e)
    }

    async function submitForm(e) {
        e.stopPropagation();
        e.preventDefault();

        if(!imageFile.length) return alert('please upload certificates')

        setIsSubmitLoader(true)

        const dataToSubmit = _.cloneDeep(formValue);

        dataToSubmit.nutritionist = 'pending'
        dataToSubmit.nutritionistDatetime = moment.utc().format()

        Promise.all(imageFile.map(async(f, i) => {

            const fileName = dataToSubmit.name.replaceAll(' ','_')+'/'+f.name;
            const uploaded = await storage().ref().child(`/certificates/${fileName}`).put(f, {cacheControl: 'public,max-age=31536000'});
            const child = await storage().ref().child(uploaded.metadata.fullPath);
            return await child.getDownloadURL();
        }))
        .then(async(values) => {

            dataToSubmit.certificates = values

            console.log(dataToSubmit)

            await firestore('users').doc(user.id).update(dataToSubmit);

            setNutritionistStatus('pending')
            setIsSubmitLoader(false)
        });
    }

    function fileDragHover(e) {
        var fileDrag = document.getElementById('file-drag');
        e.stopPropagation();
        e.preventDefault();
        fileDrag.className = (e.type === 'dragover' ? 'hover' : 'modal-body file-upload');
    }

    async function fileSelectHandler(e) {
        // Fetch FileList object
        var files = e.target.files || e.dataTransfer.files;
        // Cancel event and hover styling
        fileDragHover(e);


        var m = document.getElementById('messages');
        //m.innerHTML = '';

        var _files = _.clone(imageFile)

        // Process all File objects
        for (var i = 0, f; f = files[i]; i++) {
            var options = {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 400
            }
            try {

                _files.push(f)

                setImageError('');
                m.innerHTML += '<strong>' + encodeURI(f.name) + '</strong><br/>';

                //document.getElementById('start').classList.add("hidden");
                document.getElementById('response').classList.remove("hidden");

            } catch (error) {
                console.log('error fileSelectHandler', error);
                setImageError('please upload a valid image file');
            }
        }

        setImageFile(_files);
        setIsImageChanged(true);
    }

    function openWizard(){

        if(!user) return window.location = '/register';
        setWizard(1)
    }

    //return (<div style={{textAlign: 'center'}}><img src={comingsoon} style={{padding: '10em'}}/></div>)

    /*return (
        <div className="page-container">
            <div className="data-container w-100 h-100 d-flex text-center justify-content-center flex-column">
                <div className="login-container shadow-lg">
                    <img
                        height="150"
                        src={logoImg}
                        alt="Welnes"
                    />
                    <div className="welcome-message-container">Welcome to Welnes!</div>

                </div>
            </div>
        </div>
    );*/

    return (<>
        <div fluid="md" id="Home" className="" style={{}}>

            <div className="resize" id="header" style={{background: '#fff', padding: '3em 14%'}}>

                <NavLink key={'/'} to={'/'} style={{cursor:'pointer', marginRight: '3em'}}>
                    <span className="logo"><img height="60" style={{margin: '-10px'}} src={props.ar? logoImgAr : logoImg}/></span>
                </NavLink>

                <span className="links-container">
                    <span className="link" onClick={()=>{document.getElementById('home-about').scrollIntoView({ behavior: "smooth" });}}>About us</span>
                    <span className="link" onClick={()=>{document.getElementById('home-products').scrollIntoView({ behavior: "smooth" });}}>Product</span>
                    <span className="link" onClick={()=>{document.getElementById('home-box').scrollIntoView({ behavior: "smooth" });}}>Welnes Box</span>
                </span>

                <span className="links-container" style={{float: 'right', marginRight: '1em'}}>
                    <a href="/download" target="_blank" className="button-link"><Button variant="dark">Download App</Button></a>
                    <a href="/box" target="_blank" className="button-link"><Button variant="dark">Buy Weines Box</Button></a>
                </span>

            </div>

            <div className="resize" style={{position: 'relative', color: '#fff', padding: '10% 14%', borderTop: '1px solid #eee', borderBottom: '1px solid #eee', backgroundImage: props.ar? `url("${HeaderBgAr}")` : `url("${HeaderBg}")`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'right'}}>

                <div style={{marginTop: '5em'}}>
                    <h1 style={{fontSize: '60px', fontWeight: 'bold', textShadow: '0px 0px 20px #333'}}>{props.ar? 'حياة صحية بين يديك' : 'Healthy Life at'}</h1>
                    <h1 style={{fontSize: '60px', fontWeight: 'bold', textShadow: '0px 0px 20px #333'}}>{props.ar? 'احصل على الجسم المثالي مع أنظمة غذائية وتمارين رياضية و وجبات' : 'your Finger tips'}</h1>
                    <p style={{fontSize: '16px', fontWeight: 'bold', textShadow: '0px 0px 20px #333', marginTop: '20px', marginBottom: '30px'}}>{props.ar? 'صحية معدة خصيصاً لك' : 'Get in shape with customized fitness and nutrition plans.'}</p>

                    <a href="/download" target="_blank" className="button-link"><Button variant="dark">{props.ar? 'حمل تطبيق ويلنس' : 'Join Our Community Now!'}</Button></a>
                </div>

                <div className="download welnes-links">
                    <span style={{cursor: 'pointer'}} onClick={() => {window.open('https://play.google.com/store/apps/details?id=com.welnes&hl=en')}}><img height="60" src={googlePlay}/></span>
                    <span style={{cursor: 'pointer'}} onClick={() => {window.open('https://apps.apple.com/eg/app/welnes/id1513854681')}}><img height="60" src={appleStore}/></span>
                </div>

                <img src={HeaderApp} style={{position: 'absolute', right: '50px', bottom: '-150px'}}/>

            </div>

            <div className="resize" style={{background: '#fff', color: '#1B3D54', padding: '100px 14%', textAlign: 'center'}} id="home-about">

                <h2 style={{fontWeight: 'bold'}}>{props.ar? 'ما هو تطبيق ويلنس؟' : 'What Is Welnes App?'}</h2>

                <div className="resize-block" style={{display: 'inline-block', marginTop: '20px', display: 'flex', justifyContent: 'space-evenly', color: '#38CE91'}}>
                    <p style={{fontSize: '18px'}}>
                        {props.ar? 'ويلنس هو تطبيق للياقة البدنية والتغذية والأكل الصحي' : 'A community app for fitness, nutrition, and healthy food'} 
                    </p>
                </div>
            </div>

            <div className="resize" style={{background: '#fff', color: '#1B3D54', padding: '100px 14%', textAlign: 'center'}}>

                <h2 style={{fontWeight: 'bold'}}>{props.ar? 'لماذا عليك إختيار تطبيق ويلنس؟' : 'Why Welnes?'}</h2>

                <div className="resize-block" style={{display: 'inline-block', marginTop: '7%', display: 'flex', justifyContent: 'space-between', fontWeight: 'normal'}}>
                    <div style={{flexBasis: 0, flexGrow: .333}}>
                        <img height="100px" src={why_1}/>
                        <p>
                            {props.ar? 'نتائج حقيقية وموثوقة للمشتركين' : 'Verified Reviews & Trusted Results' }
                        </p>
                    </div>
                    <div style={{flexBasis: 0, flexGrow: .333}}>
                        <img height="100px" src={why_2}/>
                        <p>
                            {props.ar? 'طرق مختلفة للدفع بالإضافة إلى خصومات مستمرة' : 'Different Payment Plans and Discounts' }
                        </p>
                    </div>
                    <div style={{flexBasis: 0, flexGrow: .333}}>
                        <img height="100px" src={why_3}/>
                        <p>
                            {props.ar? 'فريق متكامل من خبراء التغذية والمدربين المحترفين' : 'Experienced, Qualified Nutritionists and Coaches' }
                        </p>
                    </div>
                </div>
                <div className="resize-block" style={{display: 'inline-block', marginTop: '7%', display: 'flex', justifyContent: 'space-between', fontWeight: 'normal'}}>
                    <div style={{flexBasis: 0, flexGrow: .333}}>
                        <img height="100px" src={why_4}/>
                        <p>
                            {props.ar?  'برنامج غذائي ورياضي مصمم لك خصيصًا ليناسب أهدافك' : 'Custmized Plans Booed on Your Needs'}
                        </p>
                    </div>
                    <div style={{flexBasis: 0, flexGrow: .333}}>
                        <img height="100px" src={why_5}/>
                        <p>
                            {props.ar? 'تمارين من المنزل أو من الجيم' : 'Suitable with Gyms and Home Training' }
                        </p>
                    </div>
                    <div style={{flexBasis: 0, flexGrow: .333}}>
                        <img height="100px" src={why_6}/>
                        <p>
                            {props.ar? 'دعم مستمر من مجتمع مهتم باللياقة البدنية والحياة الصحية' : 'Community Support and Engagement'  }
                        </p>
                    </div>
                </div>
            </div>


            <div className="resize" style={{background: '#fff', color: '#1B3D54', padding: '100px 14%', textAlign: 'center'}}>

                <h2 style={{fontWeight: 'bold'}}>{props.ar? 'كيف يعمل تطبيق ويلنس؟' : 'How It Works?'}</h2>
                <h6>{props.ar? 'يساعدك تطبيق ويلنس في المتابعة مع أفضل متخصصي التغذية والمدربين' : 'We connect you with qualified fitness and nutrition coaches'} </h6>

                {/*<div className="resize-block" style={{display: 'inline-block', marginTop: '7%', display: 'flex', justifyContent: 'space-between'}}>
                    <div className="resize-block-div" style={{textAlign: 'center', paddingRight: '3.5em', width: '50%'}}>
                        <h4 style={{marginBottom: '1em'}}>1. Upload your plan</h4>
                        <p>
                            Create your own recipes or use recipes from Welnes database, then create your meal plans, and select exercises to create your workout plans.
                        </p>
                        <img width="100%" src={Img11} style={{display: 'inline-block', borderRadius: '5px'}}/>
                    </div>
                    <div className="resize-block-div" style={{textAlign: 'center', paddingLeft: '3.5em', width: '50%'}}>
                        <br className="resize-block-swap-show" style={{display: 'none'}}/>
                        <br className="resize-block-swap-show" style={{display: 'none'}}/>
                        <br className="resize-block-swap-show" style={{display: 'none'}}/>
                        <img className="resize-block-swap-hide" width="100%" src={Img12} style={{display: 'inline-block', borderRadius: '5px'}}/>
                        <h4 style={{marginTop: '1em'}}>2. Create a program</h4>
                        <p>
                            Start a group challenge with a specific start and end date, or follow up 1 to 1 on your clients for 2 months, or 3 months.
                        </p>
                        <img className="resize-block-swap-show" width="100%" src={Img12} style={{display: 'none', borderRadius: '5px'}}/>
                    </div>
                </div>*/}
                <div className="resize-block" style={{display: 'block', marginTop: '12%', display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <div className="resize-block-div" style={{textAlign: 'left', paddingRight: '2em', width: '50%'}}>
                        <h4 style={{marginBottom: '1em'}}><span style={{background: '#38CE91', borderRadius: '10px', fontSize: '20px', fontWeight: 'bold', padding: '10px 15px', marginRight: '10px'}}>{props.ar? '١' : '1'}</span>{props.ar? 'اختر برنامج جماعي أو متابعة فردية' : 'Choose a program or 1 to 1 consultation'} </h4>
                    </div>
                    <div className="resize-block-div" style={{textAlign: 'center', paddingLeft: '2em', width: '50%'}}>
                        <img width="100%" src={how_1} style={{display: 'inline-block', borderRadius: '5px'}}/>
                    </div>
                </div>
                <div className="resize-block reverse" style={{display: 'block', marginTop: '12%', display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <div className="resize-block-div" style={{textAlign: 'center', paddingRight: '2em', width: '50%'}}>
                        <img width="100%" src={how_2} style={{display: 'inline-block', borderRadius: '5px'}}/>
                    </div>
                    <div className="resize-block-div" style={{textAlign: 'left', paddingLeft: '2em', width: '50%'}}>
                        <h4 style={{marginBottom: '1em'}}><span style={{background: '#38CE91', borderRadius: '10px', fontSize: '20px', fontWeight: 'bold', padding: '10px 15px', marginRight: '10px'}}>{props.ar? '٢' : '2'}</span>{props.ar? ' حدد نظامك الغذائي وطريقة التمرين' : 'Select your workout and meal plan'} </h4>
                    </div>
                </div>
                <div className="resize-block" style={{display: 'block', marginTop: '12%', display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <div className="resize-block-div" style={{textAlign: 'left', paddingRight: '2em', width: '50%'}}>
                        <h4 style={{marginBottom: '1em'}}><span style={{background: '#38CE91', borderRadius: '10px', fontSize: '20px', fontWeight: 'bold', padding: '10px 15px', marginRight: '10px'}}>{props.ar? '٣' : '3'}</span>{props.ar? 'ادفع وابدأ رحلتك لتحقيق هدفك!' : 'Pay and start your fitness journey'} </h4>
                    </div>
                    <div className="resize-block-div" style={{textAlign: 'center', paddingLeft: '2em', width: '50%'}}>
                        <img width="100%" src={how_3} style={{display: 'inline-block', borderRadius: '5px'}}/>
                    </div>
                </div>
            </div>

            <div className="resize" style={{background: '#38CE91', color: '#1B3D54', padding: '30px 14%', textAlign: 'center'}}>

                <h2 style={{fontWeight: 'bold'}}>{props.ar? 'ويلنيس بالأرقام' : 'Welnes in Numbers'} </h2>

                <div className="resize-block" style={{display: 'inline-block', marginTop: '30px', display: 'flex', justifyContent: 'space-evenly', fontWeight: 'bold'}}>
                    <div>
                        {/*<img height="100px" src={Img_clients}/>*/}
                        <h4 style={{color: '#fff'}}><span style={{color: '#1B3D54'}}>+</span>{props.ar? '٢٥٬٠٠٠' : '25,000'}</h4>
                        <p>
                            {props.ar? 'مستخدم' : 'User'}
                        </p>
                    </div>
                    <div>
                        <h4 style={{color: '#fff'}}><span style={{color: '#1B3D54'}}>+</span>{props.ar? '١٥٬٠٠٠' : '15,000'}</h4>
                        <p>
                            {props.ar? 'كيلو جرام مفقود' : 'Kg Lost'}
                        </p>
                    </div>
                    <div>
                        <h4 style={{color: '#fff'}}><span style={{color: '#1B3D54'}}>+</span>{props.ar? '٥٥٬٠٠٠' : '55,000'}</h4>
                        <p>
                            {props.ar? 'تمرين تم لعبه' : 'Exercises Played'}
                        </p>
                    </div>
                    <div>
                        <h4 style={{color: '#fff'}}><span style={{color: '#1B3D54'}}>+</span>{props.ar? '٢٦٦٬٠٠٠' : '266,000'}</h4>
                        <p>
                            {props.ar? 'صورة وجبات تم رفعها' : 'Meals Uploaded'}
                        </p>
                    </div>
                    <div>
                        <h4 style={{color: '#fff'}}><span style={{color: '#1B3D54'}}>+</span>{props.ar? '٩٥٬٠٠٠' : '95,000'}</h4>
                        <p>
                            {props.ar? 'سؤال تم طرحه من المشتركين والإجابة عليه' : 'Questions Asked '}
                        </p>
                    </div>
                    <div>
                        <h4 style={{color: '#fff'}}><span style={{color: '#1B3D54'}}>+</span>{props.ar? '١٬٤٠٠٬٠٠٠' : '1,400,000'}</h4>
                        <p>
                            {props.ar? 'تعليق' : 'Comments '}
                        </p>
                    </div>
                </div>
            </div>

            <div className="resize" style={{background: '#fff', color: '#1B3D54', padding: '100px 14%', textAlign: 'center'}} id="home-products">

                <h2 style={{fontWeight: 'bold'}}>{props.ar? 'ماذا نقدم؟' : 'Our Products'}</h2>

                <div className="resize-block" style={{display: 'block', marginTop: '12%', display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <div className="resize-block-div" style={{textAlign: 'left', paddingRight: '2em', width: '50%'}}>
                        <h4 style={{marginBottom: '2em'}}>Group Challenges <span style={{color: '#38CE91'}}>&</span> Programs </h4>
                        <p style={{marginBottom: '3em'}}>Lose Weight suport and connect with others. Welnes offers you group challenges to arcrise de community members to lose weight by powinggroup support meals 
                        and workout plan food care anino tracking and performance feedbacks from coaches 
                        </p>
                        <a href="/download" target="_blank" className="button-link"><Button variant="dark">Download App</Button></a>
                    </div>
                    <div className="resize-block-div" style={{textAlign: 'center', paddingLeft: '2em', width: '50%'}}>
                        <img max-width="100%" height="300px" src={Products_1} style={{display: 'inline-block', borderRadius: '5px'}}/>
                    </div>
                </div>
                <div className="resize-block reverse" style={{display: 'block', marginTop: '12%', display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <div className="resize-block-div" style={{textAlign: 'left', paddingRight: '2em', width: '50%'}}>
                        <h4 style={{marginBottom: '2em'}}><span style={{color: '#38CE91'}}>1 To 1</span> Follow-up </h4>
                        <p style={{marginBottom: '3em'}}>Looking for more personalized plans and customized 
                            plan to 
                            follow up with our nutritionists and coches will be 
                            1- Live calls to GISCUSS your current state of nutrition and answer any sumight have 
                            2. Cuscomised meal and workout plan medited as needed 3- Direct chat with your nutritionists and coaches "Progress tracking 
                        </p>
                        <a href="/download" target="_blank" className="button-link"><Button variant="dark">Download App</Button></a>
                    </div>
                    <div className="resize-block-div" style={{textAlign: 'center', paddingLeft: '2em', width: '50%'}}>
                        <img max-width="100%" height="300px" src={Products_2} style={{display: 'inline-block', borderRadius: '5px'}}/>
                    </div>
                </div>
                <div className="resize-block" style={{display: 'block', marginTop: '12%', display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <div className="resize-block-div" style={{textAlign: 'left', paddingRight: '2em', width: '50%'}}>
                        <h4 style={{marginBottom: '2em'}}>Rooms </h4>
                        <p style={{marginBottom: '3em'}}>Publis rooms 
                            Publico 
                            0 
                            Be a pan of our ress communky Rooms are the place where you can chat with other participants, nooract with the nutritionists and coaches and ask them are questions that you might have 

                        </p>
                        <a href="/download" target="_blank" className="button-link"><Button variant="dark">Download App</Button></a>
                    </div>
                    <div className="resize-block-div" style={{textAlign: 'center', paddingLeft: '2em', width: '50%'}}>
                        <img max-width="100%" height="300px" src={Products_3} style={{display: 'inline-block', borderRadius: '5px'}}/>
                    </div>
                </div>
            </div>

            <div className="resize box_components" id="home-box" style={{padding: '2% 14% 7em', backgroundColor: '#38CE91', color: '#1B3D54', position: 'relative', backgroundImage: `url("${Products_box}")`, backgroundRepeat: 'no-repeat', backgroundPosition: '86%', backgroundSize: 'contain'}}>

                <div style={{direction: 'rtl', textAlign: 'left'}}>

                    <h1 style={{fontWeight: 'bold', lineHeight: '1.5', margin: '2em auto 0', textAlign: 'left', fontSize: '60px'}}>Welnes Box</h1>
                    <br/>
                    <p style={{fontSize: '17px', textAlign: 'left', direction: 'ltr'}}>
                    <div>
                        <div>No time for meal planning and grocery shopping?</div>
                        <div>Welnes box is your friend!</div>
                        <div>Welnes Box delivers weekly ready-to-cook recipes and</div>
                        <div>pre-portioned ingredients right to your doorstep.</div>
                        <div>based on 2 main diet plans (Balanced Diet & Keto Diet)</div>
                    </div>
                    {/*<div>
                        <div>وجبات متبلة و مجهزة على الطبخ من قبل خبراء التغذية الصحية مصحوبة بمطبوعات لإرشادات الطبخ.</div>
                        <div>أفضل المكونات الموسمية عالية الجودة مختارة خصيصاً لك.</div>
                        <div>مكونات مقاسة بدقة لكل وجبة وحسب احتياجك.</div>
                        <div>عبوات أقل لإعادة التدوير وقابلة لإعادة الإستخدام وصديقة للبيئة.</div>
                    </div>*/}
                    </p>
                    <br/>
                
                    <a style={{fontSize: '17px'}} href="/box" target="_blank" className="button-link"><Button variant="dark" style={{paddingRight: '40px', paddingLeft: '40px', background: '#1B3D54', color: '#fff'}}>Buy Now</Button></a>

                </div>

            </div>

            <div className="resize" style={{background: '#fff', color: '#1B3D54', padding: '100px 14%', textAlign: 'center'}}>

                <h2 style={{fontWeight: 'bold'}}>Feedback and Reviews</h2>
                <div style={{marginTop: '50px'}}>
                    <Slider {...{
                          dots: true,
                          infinite: false,
                          speed: 500,
                          slidesToShow: 2,
                          slidesToScroll: 2
                        }}>
                      <div>
                        <div style={{width: 'auto', height: '400px', backgroundImage: `url("${Reviews_1}")`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center'}}></div>
                      </div>
                      <div>
                        <div style={{width: 'auto', height: '400px', backgroundImage: `url("${Reviews_2}")`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center'}}></div>
                      </div>
                    </Slider>
                </div>
            </div>
            <div className="resize" style={{background: '#fff', color: '#1B3D54', padding: '100px 14%', textAlign: 'center'}}>

                <h2 style={{fontWeight: 'bold'}}>Our coaches and nutritionists</h2>
                <div style={{fontWeight: 'bold', fontSize: '14px', marginTop: '100px'}}>
                    <Slider {...{
                          dots: true,
                          infinite: false,
                          speed: 500,
                          slidesToShow: 5,
                          slidesToScroll: 5
                        }}>
                      <div style={{textAlign: 'center'}}>
                        <div style={{width: '200px', height: '200px', borderRadius: '100px', backgroundColor: '#EDEDED', backgroundImage: `url("${Coaches_1}")`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain', margin: '0 auto'}}></div>
                        <span style={{display: 'block', marginTop: '20px'}}>Ahmed Fathi</span>
                        <span style={{display: 'block', marginTop: '5px', color: '#38CE91'}}>Strength & Conditioning Coach</span>
                      </div>
                      <div style={{textAlign: 'center'}}>
                        <div style={{width: '200px', height: '200px', borderRadius: '100px', backgroundColor: '#EDEDED', backgroundImage: `url("${Coaches_2}")`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain', margin: '0 auto'}}></div>
                        <span style={{display: 'block', marginTop: '20px'}}>Amira Seeed</span>
                        <span style={{display: 'block', marginTop: '5px', color: '#38CE91'}}>Clinical Nutrition Specialist</span>
                      </div>
                      <div style={{textAlign: 'center'}}>
                        <div style={{width: '200px', height: '200px', borderRadius: '100px', backgroundColor: '#EDEDED', backgroundImage: `url("${Coaches_3}")`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain', margin: '0 auto'}}></div>
                        <span style={{display: 'block', marginTop: '20px'}}>Dr. Waad Amin</span>
                        <span style={{display: 'block', marginTop: '5px', color: '#38CE91'}}>Fitness Nutritionist</span>
                      </div>
                      <div style={{textAlign: 'center'}}>
                        <div style={{width: '200px', height: '200px', borderRadius: '100px', backgroundColor: '#EDEDED', backgroundImage: `url("${Coaches_4}")`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain', margin: '0 auto'}}></div>
                        <span style={{display: 'block', marginTop: '20px'}}>Bahaa Elbrolesy</span>
                        <span style={{display: 'block', marginTop: '5px', color: '#38CE91'}}>Strength & Conditioning Coach</span>
                      </div>
                      <div style={{textAlign: 'center'}}>
                        <div style={{width: '200px', height: '200px', borderRadius: '100px', backgroundColor: '#EDEDED', backgroundImage: `url("${Coaches_5}")`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain', margin: '0 auto'}}></div>
                        <span style={{display: 'block', marginTop: '20px'}}>Kareem Gamal</span>
                        <span style={{display: 'block', marginTop: '5px', color: '#38CE91'}}>Specialist of Behavioral Nuttrition</span>
                      </div>
                    </Slider>
                </div>
                <a style={{display: 'block', fontSize: '15px', color: '#1E516F', textDecoration: 'underline', marginTop: '2em', fontWeight: 'bold'}} href="/start" target="_blank" className="button-link">Join us as a coach</a>
            </div>

            <div className="resize" style={{position: 'relative', color: '#fff', padding: '10% 14%', borderTop: '1px solid #eee', borderTop: '1px solid #eee', backgroundImage: `url("${PreFooterBg}")`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'right'}}>

                <div style={{marginTop: '5em', textAlign: 'center'}}>
                    <h1 style={{fontSize: '60px', fontWeight: 'bold', textShadow: '0px 0px 20px #333', textAlign: 'center'}}>Start Your Fitness Journey</h1>

                    <a href="/download" target="_blank" className="button-link"><Button variant="dark">Download App</Button></a>
                </div>

                <div className="download welnes-links" style={{textAlign: 'center'}}>
                    <span style={{cursor: 'pointer'}} onClick={() => {window.open('https://play.google.com/store/apps/details?id=com.welnes&hl=en')}}><img height="60" src={/*footergoogle*/googlePlay}/></span>
                    <span style={{cursor: 'pointer'}} onClick={() => {window.open('https://apps.apple.com/eg/app/welnes/id1513854681')}}><img height="60" src={/*footerapple*/appleStore}/></span>
                </div>

            </div>

            <div className="resize" style={{background: '#0E5070', color: '#fff', padding: '1.2em 14% 1.2em 14%', height: '150px'}}>

                <div className="resize-block" style={{float: 'left', marginTop: '10px'}}>
                    <h3>Want to provide your employees</h3>
                    <h3>with health and wellness services?</h3>
                </div>

                <div className="resize-block" style={{float: 'right', marginTop: '10px'}}>
                    <div><span style={{color: '#38CE91'}}>Contact us:</span>  info@welnes.app</div>
                    <span style={{margin: '.5em 0', cursor: 'pointer'}} onClick={()=>{window.open('https://www.facebook.com/welnesapp')}}><img src={Social_1} height="50px"/></span>
                    <span style={{margin: '.5em 0', cursor: 'pointer'}} onClick={()=>{window.open('https://www.linkedin.com/company/welnes-app')}}><img src={Social_2} height="50px"/></span>
                    <span style={{margin: '.5em 0', cursor: 'pointer'}} onClick={()=>{window.open('https://www.instagram.com/welnes.app')}}><img src={Social_3} height="50px"/></span>
                    <span style={{margin: '.5em 0', cursor: 'pointer'}} onClick={()=>{window.open('https://www.youtube.com/channel/UC5reWbzONPeSQBXm-NNCv9Q')}}><img src={Social_4} height="50px"/></span>
                </div>
            </div>

        </div>

    </>)
}
