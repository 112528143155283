
import * as firebase from 'firebase';
import { firestore, storage } from "../services/firebase";

export const events = {

    add: (event, cb) => {

        event.created = firebase.firestore.FieldValue.serverTimestamp()
        event.datetime = new Date().toISOString()
        firestore('events').add(event).then(()=>{if(cb)cb();})
    }
}