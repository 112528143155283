import React, { useState, useEffect, useRef } from "react";
import { Button, Spinner, Form } from "react-bootstrap";
import * as firebase from "firebase";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { events } from "../components/events";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { firestore, storage } from "../services/firebase";
import Timer from "react-compound-timer";
import StarRatings from "react-star-ratings";
import moment from "moment";
import vodafoneCash from "../assets/images/vodafone-cash.png";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input/mobile";
import { rudderAnalytics } from "../services/trackers";

export default function CheckoutForm(props) {
  //const [isFree, setIsFree] = useState(props.isFree);
  const [succeeded, setSucceeded] = useState(false);
  const [appLink, setAppLink] = useState();
  const [appLink2, setAppLink2] = useState();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [processingImage, setProcessingImage] = useState();
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const [startDate, setStartDate] = useState();
  const [nutritionist, setNutritioinist] = useState();
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [src, setSrc] = useState(localStorage.getItem("src"));
  const [dif, setDif] = useState();
  const stripe = useStripe();
  const elements = useElements();

  const [isImageChanged, setIsImageChanged] = useState(false);
  const [imageFile, setImageFile] = useState(false);
  const [imageError, setImageError] = useState("");
  const fileInput = useRef(null);
  const openWelnes = useRef(null);
  const [phoneNo, setPhoneNo] = useState("");
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [validInternationalPhoneNumber, setValidInternationalPhoneNumber] =
    useState("");
  const [iframeUrl, setiframeUrl] = useState();
  const [startMonitoring, setStartMonitoring] = useState(false);
  const [lastPayment, setLastPayment] = useState();
  const [orderId, setOrderId] = useState();
  const [vodafoneCashNumber, setVodafoneCashNumber] = useState();
  const [usePaymob, setUsePaymob] = useState(true);

  useEffect(() => {
    if (succeeded === true) props.success();

    return () => {
      console.log("Do some cleanup");
    };
  }, [succeeded]);

  useEffect(() => {
    var appLink =
      "https://welnes.page.link" +
      "?link=https://welnes.page.link/" +
      props.challenge.id +
      "_" +
      user.id +
      "&apn=com.welnes" +
      "&ibi=com.welnes" +
      "&isi=1513854681" +
      //'&efr=1'+
      "&afl=https://welnes.app" +
      "&ofl=https://welnes.app/download";

    console.log(appLink + "&d=1");

    setAppLink(appLink);

    return () => {
      console.log("Do some cleanup");
    };
  }, [succeeded]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (startDate) {
        const d = moment.duration(moment(startDate).diff());
        setDif({
          seconds: d.seconds(),
          minutes: d.minutes(),
          hours: d.hours(),
          days: d.days(),
        });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [startDate]);

  useEffect(() => {
    firestore("constants")
      .doc("app")
      .get()
      .then((snapshot) => {
        var data = snapshot.data();
        setVodafoneCashNumber(data.vodafoneCash.phoneNumber);
        setUsePaymob(data.enableAcceptPaymentService);
      });

    return () => {};
  }, []);

  useEffect(() => {
    firestore("users")
      .doc(user.id)
      .onSnapshot((d) => {
        //firestore("users").doc(user.id).get().then(d=> {

        var user = d.data();

        if (
          user.joinedChallengeIds &&
          user.joinedChallengeIds.includes(props.challenge.id)
        ) {
          //firestore("users").doc(user.id).update({challengeId: props.challenge.id})
          console.log(
            user.name + " already joined challenge " + props.challenge.name
          );
          setSucceeded(true);
          return;
        }

        if (
          user.joinedOneToOneIds &&
          user.joinedOneToOneIds.includes(props.challenge.id) &&
          user.joinedOneToOne[props.challenge.id].to.toDate() > new Date()
        ) {
          //firestore("users").doc(user.id).update({challengeId: props.challenge.id})
          console.log(
            user.name + " already joined challenge " + props.challenge.name
          );
          setSucceeded(true);
          return;
        }
      });

    return () => {
      console.log("Do some cleanup");
    };
  }, []);

  useEffect(() => {
    if (!orderId) return;

    // Subscribe to changes in the query
    const unsubscribe = firestore("payments")
      .where("orderId", "==", orderId)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const payment = change.doc.data();
            const trackProperties = {
              amount: props.amount,
              challengeName: props.challenge.name,
              challengeId: props.challenge.id,
              nutritionistId: props.challenge.nutritionistId,
              nutritionistName: props.challenge.nutritionistName,
              rounds: props.oneToOne ? props.months : props.rounds || 1,
              provider: "accept",
              programType: props.oneToOne ? "one_to_one" : "challenge",
              currency: props.currency,
            };
            if (props.promocode) {
              trackProperties.promocode = props.code;
              trackProperties.promoCodeId = props.promocode;
            }
            console.log("payment:", change.doc.id);

            if (payment.declined) {
              console.log("New payment added:", payment);
              console.log("set error", payment.declined);
              setError(payment.declined);
              rudderAnalytics.track("User_Purchase", {
                ...trackProperties,
                status: "failed",
              });
            } else {
              rudderAnalytics.track("User_Purchase", {
                ...trackProperties,
                status: "successful",
              });
            }
          }
        });
      });

    return () => {
      console.log("Do some cleanup");
      unsubscribe();
    };
  }, [orderId]);

  function setChallengeId() {
    firestore("users")
      .doc(user.id)
      .get()
      .then((d) => {
        var user = d.data();

        if (
          user.joinedChallengeIds &&
          user.joinedChallengeIds.includes(props.challenge.id)
        ) {
          firestore("users")
            .doc(user.id)
            .update({ challengeId: props.challenge.id });
          console.log(
            user.name + " already joined challenge " + props.challenge.name
          );
          setSucceeded(true);
          return;
        }

        if (
          user.joinedOneToOneIds &&
          user.joinedOneToOneIds.includes(props.challenge.id) &&
          user.joinedOneToOne[props.challenge.id].to.toDate() > new Date()
        ) {
          firestore("users")
            .doc(user.id)
            .update({ challengeId: props.challenge.id });
          console.log(
            user.name + " already joined challenge " + props.challenge.name
          );
          setSucceeded(true);
          return;
        }
      });
  }

  useEffect(() => {
    if (phoneNo) {
      setPhoneErrorMessage("");
      if (isPossiblePhoneNumber(phoneNo)) {
        const phoneNumber = parsePhoneNumber(phoneNo);
        if (phoneNumber.isValid()) {
          // Returns "(213) 373-4253 ext. 1234"
          setValidInternationalPhoneNumber(
            phoneNumber.formatInternational().replace(/\s+/g, "")
          );
        } else {
          setPhoneErrorMessage("Invalid phone number");
          setValidInternationalPhoneNumber("");
        }
      } else {
        setPhoneErrorMessage("Invalid phone number");
        setValidInternationalPhoneNumber("");
      }
    } else if (!phoneNo) {
      setPhoneErrorMessage("Add your phone number");
      setValidInternationalPhoneNumber("");
    }
  }, [phoneNo]);

  useEffect(() => {
    if (
      !user ||
      !props.challenge ||
      !(props.amount || props.amount === 0) ||
      !props.currency
    )
      window.location = "/";

    setStartDate(props.challenge.date);

    firestore("users")
      .where("id", "==", props.challenge.nutritionistId)
      .limit(1)
      .get()
      .then(function (n) {
        if (n.size) {
          var nutritionists = n.docs.map((d) => {
            const obj = d.data();
            obj.id = d.id;
            return obj;
          });

          setNutritioinist(nutritionists[0]);
        }
      });

    if (/*props.challenge.*/ props.isFree) {
      return (
        firestore("users")
          .doc(user.id)
          //.where("challengeId", "==", challengeId)
          //.where("userId", "==", userId)
          .get()
          .then(function (doc) {
            //if(props.challenge.name.toLowerCase().includes('etisalat') && !user.email.toLowerCase().includes('etisalat')) {logout(); return alert('checkout : user is not allowed to join the challenge')}

            if (
              doc.data().joinedChallengeIds &&
              doc.data().joinedChallengeIds.includes(props.challenge.id)
            ) {
              setSucceeded(true);
              return console.log("already In");
            }

            updateUserPlan();

            doc.ref
              .update(
                props.oneToOne !== true
                  ? {
                      challengeId: props.challenge.id,
                      joinedChallengeIds:
                        firebase.firestore.FieldValue.arrayUnion(
                          props.challenge.id
                        ),
                    }
                  : {
                      challengeId: props.challenge.id,
                      joinedOneToOneIds:
                        firebase.firestore.FieldValue.arrayUnion(
                          props.challenge.id
                        ),
                      [`joinedOneToOne.${props.challenge.id}.from`]:
                        firebase.firestore.Timestamp.fromDate(
                          moment().toDate()
                        ),
                      [`joinedOneToOne.${props.challenge.id}.to`]:
                        firebase.firestore.Timestamp.fromDate(
                          moment()
                            .add(+props.months * 4, "weeks")
                            .add(2, "days")
                            .toDate()
                        ),
                    }
              )
              .then(() => {
                doc.ref
                  .collection("selectedChallenges")
                  .doc(props.challenge.id)
                  .set({ joinDate: new Date().toISOString() })
                  .then(function (doc) {
                    console.log(
                      user.name + " joined challenge " + props.challenge.name
                    );
                    setSucceeded(true);

                    if (props.promocode) {
                      firestore("promocodes")
                        .doc(props.promocode)
                        .update({
                          count: firebase.firestore.FieldValue.increment(1),
                        });
                    }

                    return;
                  })
                  .catch(function (error) {
                    console.error(
                      "Error finding/updating user document: ",
                      error
                    );
                  });
              });

            return;
          })
          .catch(function (error) {
            console.error("Error finding user: ", error);
          })
      );
    }

    console.log({
      amount: props.amount,
      currency: props.currency,
      code: props.code,
    });

    const params = new URLSearchParams();
    params.append("web", true);
    params.append("src", src || "web");
    params.append("userId", user.id);
    params.append("challengeId", props.challenge.id);
    params.append("amount", props.amount);
    params.append("currency", props.currency);
    if (props.bundle && props.rounds === 1 && props.promocode)
      params.append("promoCodeId", props.promocode);
    if (props.oneToOne && props.months === 1 && props.promocode)
      params.append("promoCodeId", props.promocode);
    if (!props.bundle && !props.oneToOne && props.promocode)
      params.append("promoCodeId", props.promocode);
    if (props.code) params.append("code", props.code);
    if (props.bundle && props.rounds > 1) params.append("rounds", props.rounds); //bundle
    if (props.oneToOne === true) params.append("months", props.months); //one to one

    if (props.currency == "egp" && usePaymob) {
      params.append("userName", user.name);
      params.append("userEmail", user.email);
      params.append("userCountry", user.country);
      if (user.dielCode) params.append("userDielCode", user.dielCode);
      if (user.mobileNumber) params.append("userMobile", user.mobileNumber);

      axios({
        method: "post",
        url: "https://us-central1-welnes-app.cloudfunctions.net/completePaymentWithAccept",
        data: params,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then(function (response) {
          console.log(response);
          var iframeUrl = response.data.url;
          var orderId = response.data.orderId;
          setiframeUrl(response.data.url);
          setOrderId(orderId);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else
      axios({
        method: "post",
        url: "https://us-central1-welnes-app.cloudfunctions.net/stripeRequestPayment",
        data: params,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then(function (response) {
          console.log(response);
          setClientSecret(response.data.client_secret);
        })
        .catch(function (error) {
          console.log(error);
        });
  }, [props.amount, props.currency, usePaymob]);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    updateUserPlan();

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          //name: ev.target.name.value
          name: user.name,
          email: user.email,
        },
      },
    });
    const trackProperties = {
      amount: props.amount,
      challengeName: props.challenge.name,
      challengeId: props.challenge.id,
      nutritionistId: props.challenge.nutritionistId,
      nutritionistName: props.challenge.nutritionistName,
      rounds: props.oneToOne ? props.months : props.rounds || 1,
      provider: "stripe",
      programType: props.oneToOne ? "one_to_one" : "challenge",
      currency: props.currency,
    };
    if (props.promocode) {
      trackProperties.promocode = props.code;
      trackProperties.promoCodeId = props.promocode;
    }
    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);

      //if(window.mixpanel) window.mixpanel.track("user pay", {user: user? user.name:'', challenge: props.challenge.name, success: false});
      rudderAnalytics.track("User_Purchase", {
        ...trackProperties,
        status: "failed",
      });
      events.add({
        page: "payment",
        success: false,
        challenge: {
          id: props.challenge.id,
          name: props.challenge.name || props.challenge.nutritionistName,
        },
        ...(user && { user: { id: user.id, name: user.name } }),
        ...(src && { src: src }),
      });
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);

      if (window.mixpanel)
        window.mixpanel.track("Program_Payment_Successful", {
          challengeName: props.challenge.name,
          challengeId: props.challenge.id,
        });
      rudderAnalytics.track("User_Purchase", {
        ...trackProperties,
        status: "successful",
      });
      if (window.fbq)
        window.fbq("track", "Purchase", {
          value: props.amount,
          currency: props.currency,
        });

      events.add({
        page: "payment",
        success: true,
        challenge: {
          id: props.challenge.id,
          name: props.challenge.name || props.challenge.nutritionistName,
        },
        ...(user && { user: { id: user.id, name: user.name } }),
        ...(src && { src: src }),
      });
    }
  };

  async function handleFileUpload(e) {
    e.persist();
    e.stopPropagation();
    e.preventDefault();

    var receipt = await firestore("receipts")
      .where("userId", "==", user.id)
      .where("challengeId", "==", props.challenge.id)
      .where("status", "==", "pending")
      .get();

    if (receipt.size) return alert("receipt already uploaded.");

    setProcessingImage(true);

    console.log(e);
    console.log("e.target");
    console.log(e.target);

    const fileName = props.challenge.id + "_" + user.id + "-" + moment().unix();
    const imageFile = (e.target.files || e.dataTransfer.files)[0];

    const uploaded = await storage()
      .ref()
      .child(`/receipts/${fileName}`)
      .put(imageFile, { cacheControl: "public,max-age=31536000" });
    const child = await storage().ref().child(uploaded.metadata.fullPath);
    const image = await child.getDownloadURL();

    const adminDetails = JSON.parse(localStorage.getItem("adminDetails"));
    var receiptData = {};
    receiptData.challengeId = props.challenge.id;
    receiptData.challengeName = props.challenge.name || "";
    receiptData.nutritionistName = props.challenge.nutritionistName;
    receiptData.userId = user.id;
    receiptData.userName = user.name || "";
    receiptData.amount = props.amount;
    receiptData.currency = props.currency;
    receiptData.type = "vodafone";
    receiptData.status = "pending";
    receiptData.oneToOne = !!props.oneToOne;
    receiptData.image = image;
    receiptData.datetime = moment.utc().format();
    receiptData.created = firebase.firestore.FieldValue.serverTimestamp();
    await firestore("receipts").add(receiptData);

    setProcessingImage(false);
    //setSucceeded(true);

    //update user selected plans
    updateUserPlan();

    //let em in anyways.. till operators review from dashboard
    /*firestore("users").doc(user.id)
            //.where("challengeId", "==", challengeId)
            //.where("userId", "==", userId)
            .get().then(function(doc){

                if(doc.data().joinedChallengeIds && doc.data().joinedChallengeIds.includes(props.challenge.id)) {setSucceeded(true); return console.log('already In');}

                doc.ref.update(
                    props.oneToOne !== true?
                    {challengeId: props.challenge.id, joinedChallengeIds: firebase.firestore.FieldValue.arrayUnion(props.challenge.id)} :
                    {challengeId: props.challenge.id, joinedOneToOneIds: firebase.firestore.FieldValue.arrayUnion(props.challenge.id), 
                        [`joinedOneToOne.${props.challenge.id}.from`]: firebase.firestore.Timestamp.fromDate(moment().toDate()),
                        [`joinedOneToOne.${props.challenge.id}.to`]: firebase.firestore.Timestamp.fromDate(moment().add(+props.months*4, 'weeks').add(2, 'days').toDate())
                    }
                    ).then(()=>{

                    doc.ref.collection('selectedChallenges').doc(props.challenge.id).set({joinDate: new Date().toISOString()})
                        .then(function(doc) {

                            console.log(user.name+' joined challenge '+props.challenge.name)
                            setSucceeded(true);

                            if(props.promocode){

                              firestore('promocodes').doc(props.promocode).update({count: firebase.firestore.FieldValue.increment(1)});
                            }

                            return
                        })
                        .catch(function(error) {
                            
                            console.error("Error finding/updating user document: ", error);
                        });
                })
              })*/
    /*
      //send receipt to support
      var newMessage = {}
      newMessage.message = "vodafone cash receipt"
      newMessage.image = image
      newMessage.datetime = moment.utc().format();
      newMessage.created = firebase.firestore.FieldValue.serverTimestamp();
      firestore('users_support').doc(user.id).collection('messages').add(newMessage).then(()=>{

        //send reply message from support
        var newMessage = {}
        newMessage.message = "أهلاً بيك يا فندم 🌺\r\nحابين نقولك إننا استلمنا منك إيصال الدفع بنجاح و هنرجعلك تاني في خلال ساعات عشان نأكد الاشتراك في البرنامج 💪🏻"
        newMessage.isSupport = true;
        newMessage.datetime = moment.utc().format();
        newMessage.created = firebase.firestore.FieldValue.serverTimestamp();
        firestore('users_support').doc(user.id).collection('messages').add(newMessage).then(()=>{

        })
      });
      */
    //open support
    document.getElementById("contact-support").click();

    //if(window.mixpanel) window.mixpanel.track("user pay", {user: user? user.name:'', success: true});
    //if(window.fbq) window.fbq('track', 'payment', {value: props.amount, currency: props.currency});
    //events.add({page: 'payment', success: true, challenge: {id: props.challenge.id, name: props.challenge.name || props.challenge.nutritionistName}, ...(user) && {user: {id: user.id, name: user.name}}})
  }

  async function updateUserPlan() {
    if (!props.dietType || !props.exerciseType) return; //free challenges (no checkout page)
    rudderAnalytics.track("User_Complete_Program_Preference", {
      mealPlan: props.dietType,
      workoutPlan: props.exerciseType,
      challengeName: props.challenge.name,
      challengeId: props.challenge.id,
    });
    //update user selected plans
    await firestore("users")
      .doc(user.id)
      .update({
        [`healthInfo.dietType`]: props.dietType,
        [`healthInfo.exerciseType`]: props.exerciseType,
      });
  }

  function logout() {
    localStorage.clear();
    firebase.auth().signOut();
  }

  return (
    <>
      {(!succeeded || error) && (
        <>
          {!succeeded &&
            !props.isFree &&
            props.currency == "egp" &&
            usePaymob &&
            iframeUrl &&
            !error && (
              <iframe
                title=" "
                id="payment-form"
                className="accept-form"
                src={iframeUrl}
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation"
              />
            )}
          {!succeeded &&
            !props.isFree &&
            (props.currency !== "egp" || !usePaymob) && (
              <form
                id="payment-form"
                onSubmit={handleSubmit}
                style={{ display: "block", margin: "0 auto" }}
              >
                {!succeeded && !props.isFree && (
                  <CardElement
                    id="card-element"
                    options={cardStyle}
                    onChange={handleChange}
                  />
                )}
                {!succeeded && (
                  <button
                    disabled={processing || disabled || succeeded}
                    id="submit"
                  >
                    <span id="button-text">
                      {processing ? (
                        <div className="spinner" id="spinner"></div>
                      ) : (
                        "Pay"
                      )}
                    </span>
                  </button>
                )}
                {/* Show any error that happens when processing the payment */}
                {error && (
                  <div className="card-error" role="alert">
                    {error}
                  </div>
                )}
                {/* Show a success message upon completion */}
              </form>
            )}
        </>
      )}

      {!succeeded &&
        !props.isFree &&
        props.currency == "egp" &&
        usePaymob &&
        iframeUrl &&
        error && (
          <>
            <div style={{ color: "brown" }} className="card-error" role="alert">
              {error}
            </div>
            <Button
              style={{ marginTop: "10px" }}
              variant="warning"
              onClick={() => {
                setError(null);
                const iframU = iframeUrl;
                setiframeUrl();
                setiframeUrl(iframU);
              }}
            >
              Retry
            </Button>
          </>
        )}

      {!succeeded && !props.isFree && false /*props.currency == 'egp'*/ && (
        <form
          id="payment-form"
          onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
            fileInput.current.click();
          }}
          style={{ display: "block", margin: "3em auto" }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <input
              ref={fileInput}
              id={"receipt-image"}
              onChange={handleFileUpload}
              type="file"
              style={{ display: "none" }}
              multiple={false}
            />
            <img className="vodafone-image" height="44" src={vodafoneCash} />
            <span className="vodafone-number">
              {vodafoneCashNumber || "01014302208"}
            </span>
          </div>
          <button id="submit2">
            <span id="button-text">
              {processingImage ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Send receipt"
              )}
            </span>
          </button>
        </form>
      )}

      {succeeded && <br />}
      {succeeded && (
        <div className="successful">
          {/*
      {props.isFree && <div style={{color: '#4F9F4F', background:'none'}}><FontAwesomeIcon icon={faCheck} size="1x" className="mr-2" />You have successfully joined the challenge, download or open Welnes app to start the challenge</div>}
      {!props.isFree && <div><FontAwesomeIcon icon={faCheck} size="1x" className="mr-2" />Payment successful, download or open Welnes app to start the challenge</div>}
      */}

          <div className="success-check">
            <FontAwesomeIcon icon={faCheck} size="2x" />
          </div>

          <div className="success-1">Congratulations!</div>
          <div className="success-2">You have completed your purchase for</div>
          <div className="success-3">
            {props.challenge.name || "One to One follow up"}
            <b>{props.oneToOne ? "with" : "by"}</b>
            {nutritionist && nutritionist.name}
          </div>

          <div className="challenge">
            <div
              className="challenge-image"
              style={{
                backgroundImage:
                  "url(" +
                  (props.challenge.image ||
                    (nutritionist && nutritionist.image)) +
                  ")",
              }}
            ></div>
            <div className="info">
              <div className="title">
                {props.challenge.name || "One to One follow up"}
              </div>

              <span className="rating">
                <StarRatings
                  rating={props.challenge.roundsRating}
                  starDimension="15px"
                  starSpacing="1px"
                  starRatedColor="#ffb400"
                  numberOfStars={5}
                  name="rating"
                />
                <span>{(props.challenge.roundsRating || 0).toFixed(2)} </span>
                <span>({props.challenge.roundsRatings} ratings) </span>
              </span>

              <div className="date">
                {moment(props.challenge.date).isAfter()
                  ? "Starting "
                  : "Started "}
                {moment(props.challenge.date).format("Do")} of{" "}
                {moment(props.challenge.date).format("MMMM")}
              </div>
            </div>

            {nutritionist && !props.oneToOne && (
              <div
                className="nutritionist-image"
                style={{ backgroundImage: "url(" + nutritionist.image + ")" }}
              ></div>
            )}
          </div>

          {/*<label style={{marginTop: '1em', color: '#aaa'}}>enter your phone no.</label>*/}
          <div style={{ flexDirection: "column" }}>
            <PhoneInput
              defaultCountry="EG"
              style={{ width: "80%", margin: "0 auto", marginTop: "2em" }}
              placeholder="Enter your phone number"
              value={phoneNo}
              onChange={setPhoneNo}
              international
              onBlur={() => {
                if (validInternationalPhoneNumber) {
                  //if(!phoneNo.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g) alert('please enter a valid phone number');
                  firestore("followup").add({
                    phoneNo: validInternationalPhoneNumber,
                    datetime: moment().format(),
                    challenge: {
                      id: props.challenge.id,
                      name:
                        props.challenge.name ||
                        props.challenge.nutritionistName,
                    },
                    ...(user && { user: { id: user.id, name: user.name } }),
                    ...(src && { src: src }),
                  });
                  const phone = {
                    callingCode: parsePhoneNumber(validInternationalPhoneNumber)
                      .countryCallingCode,
                    countryCode: parsePhoneNumber(validInternationalPhoneNumber)
                      .country,
                    formattedNumber: validInternationalPhoneNumber,
                    number: validInternationalPhoneNumber.replace(
                      `+${
                        parsePhoneNumber(validInternationalPhoneNumber)
                          .countryCallingCode
                      }`,
                      ""
                    ),
                  };
                  firestore("users").doc(user.id).update({ phone });
                }
              }}
            />
            {phoneErrorMessage && (
              <span
                style={{ color: "red", fontStyle: "italic", fontSize: "12px" }}
              >
                {phoneErrorMessage}
              </span>
            )}
          </div>
          {/* <div style={{ display: "flex", width: "70%", margin: "2em auto 0" }}>
            <Form.Control
              style={{
                borderRadius: "5px",
                fontSize: ".85em",
                margin: "0",
                marginRight: "5px",
              }}
              placeholder="Enter your phone number"
              value={phoneNo}
              onChange={(e) => {
                setPhoneNo(e.target.value);
              }}
            />
            <Button
              variant="info"
              className="submit-button"
              disabled={!(phoneNo && phoneNo.length >= 8)}
              style={{
                background: "rgb(209, 129, 41)",
                margin: "0",
                padding: "0 1em",
                boxShadow: "0 2px 3px 0px #ccc",
                borderColor: "#999",
              }}
              onClick={() => {
                openWelnes.current.click();
              }}
            >
              Enter
            </Button>
          </div> */}

          <a href={appLink} target="_blank">
            <Button
              variant="info"
              className="submit-button"
              ref={openWelnes}
              onClick={() => {
                setChallengeId();
                //if(window.mixpanel) window.mixpanel.track("goto app");
                events.add({
                  page: "applink",
                  challenge: {
                    id: props.challenge.id,
                    name:
                      props.challenge.name || props.challenge.nutritionistName,
                  },
                  ...(user && { user: { id: user.id, name: user.name } }),
                  ...(src && { src: src }),
                });
              }}
            >
              Open Welnes App
            </Button>
          </a>
        </div>
      )}
      {succeeded && <br />}
      {succeeded && (
        <div className="timer">
          {!moment(startDate).isAfter() ? (
            <div>
              {props.oneToOne ? "Program has Started" : "Challenge has Started"}
            </div>
          ) : (
            <div>
              <div className="note-1">Challenge starts In</div>
              <div className="note-2">
                You will get access to the challenge with the start date
              </div>
              <Timer
                initialTime={moment(startDate).valueOf() - moment().valueOf()}
                direction="backward"
              >
                <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div className="element">
                    <svg width="100%" viewBox="0 0 40 40" class="donut">
                      <circle
                        class="donut-ring"
                        cx="20"
                        cy="20"
                        r="15.91549430918954"
                        fill="transparent"
                        stroke-width="1.5"
                      ></circle>
                      <circle
                        class="donut-segment"
                        cx="20"
                        cy="20"
                        r="15.91549430918954"
                        fill="transparent"
                        stroke-width="3"
                        stroke-dasharray={
                          dif &&
                          (dif.days / 30) * 100 +
                            " " +
                            (100 - (dif.days / 30) * 100)
                        }
                        stroke-dashoffset="25"
                      ></circle>
                      <text x="50%" y="60%" text-anchor="middle">
                        <Timer.Days />
                      </text>
                    </svg>

                    <span>days</span>
                  </div>
                  <div className="element">
                    <svg width="100%" viewBox="0 0 40 40" class="donut">
                      <circle
                        class="donut-ring"
                        cx="20"
                        cy="20"
                        r="15.91549430918954"
                        fill="transparent"
                        stroke-width="1.5"
                      ></circle>
                      <circle
                        class="donut-segment"
                        cx="20"
                        cy="20"
                        r="15.91549430918954"
                        fill="transparent"
                        stroke-width="3"
                        stroke-dasharray={
                          dif &&
                          (dif.hours / 24) * 100 +
                            " " +
                            (100 - (dif.hours / 24) * 100)
                        }
                        stroke-dashoffset="25"
                      ></circle>
                      <text x="50%" y="60%" text-anchor="middle">
                        <Timer.Hours />
                      </text>
                    </svg>
                    <span>hours</span>
                  </div>
                  <div className="element">
                    <svg width="100%" viewBox="0 0 40 40" class="donut">
                      <circle
                        class="donut-ring"
                        cx="20"
                        cy="20"
                        r="15.91549430918954"
                        fill="transparent"
                        stroke-width="1.5"
                      ></circle>
                      <circle
                        class="donut-segment"
                        cx="20"
                        cy="20"
                        r="15.91549430918954"
                        fill="transparent"
                        stroke-width="3"
                        stroke-dasharray={
                          dif &&
                          (dif.minutes / 60) * 100 +
                            " " +
                            (100 - (dif.minutes / 60) * 100)
                        }
                        stroke-dashoffset="25"
                      ></circle>
                      <text x="50%" y="60%" text-anchor="middle">
                        <Timer.Minutes />
                      </text>
                    </svg>
                    <span>minutes</span>
                  </div>
                  <div className="element">
                    <svg width="100%" viewBox="0 0 40 40" class="donut">
                      <circle
                        class="donut-ring"
                        cx="20"
                        cy="20"
                        r="15.91549430918954"
                        fill="transparent"
                        stroke-width="1.5"
                      ></circle>
                      <circle
                        class="donut-segment"
                        cx="20"
                        cy="20"
                        r="15.91549430918954"
                        fill="transparent"
                        stroke-width="3"
                        stroke-dasharray={
                          dif &&
                          (dif.seconds / 60) * 100 +
                            " " +
                            (100 - (dif.seconds / 60) * 100)
                        }
                        stroke-dashoffset="25"
                      ></circle>
                      <text x="50%" y="60%" text-anchor="middle">
                        <Timer.Seconds />
                      </text>
                    </svg>
                    <span>seconds</span>
                  </div>
                </div>
              </Timer>
            </div>
          )}
        </div>
      )}
    </>
  );
}
