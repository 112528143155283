
import React, {useEffect, useState} from 'react';
//import ReactPlayer from 'react-player'

import { Button, Spinner, Container, Row, Col } from "react-bootstrap";
import { firestore, storage } from "../services/firebase";
import Masonry from 'react-masonry-css'
import moment from 'moment';
import * as _ from "lodash";
import firebase from "firebase";
import { NavLink, useHistory } from "react-router-dom";
import StarRatings from 'react-star-ratings';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faComments} from '@fortawesome/free-solid-svg-icons'
import logoImg from '../assets/images/Logo.png';
import like from '../assets/images/dc-like-grey.png';
import likeActive from '../assets/images/dc-like.png';
import facebook from '../assets/images/fb.png';
import googlePlay from '../assets/images/google-play.png';
import appleStore from '../assets/images/apple-store.png';
import comingsoon from '../assets/images/comingsoon.png';
import './Programs.scss';


export default function Programs(props) {

    const [loader, setLoader] = useState(true);
    const [submitLoader, setIsSubmitLoader] = useState([]);
    const [imageFile, setImageFile] = useState();
    const [post, setPosted] = useState();

    const [programs, setPrograms] = useState([]);
    const [challenges, setChallenges] = useState([]);
    const [nutritionists, setNutritioinists] = useState([]);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('userDetails')));


    useEffect(() => {

        var unsubscribes = getCollections();

        return () => {

            console.log('Do some cleanup');
            //unsubscribes.map((unsubscribe) => unsubscribe())
        }

    }, []);

    async function getCollections(){

        setLoader(true);

        const nutritionistsRef = await firestore("users").where('role', 'array-contains', "nutritionist").get()
        var nutritionists = {} 

        nutritionistsRef.docs.map((d)=>{var obj = d.data(); obj.id = d.id; nutritionists[d.id] = obj;})

        //const challengesRef = await firestore("challenges").orderBy('datetime', 'desc').limit(10).get()
        //var challenges = _.orderBy(challengesRef.docs.length? challengesRef.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : [], 'datetime', 'desc')

        const subscribeFeeds = /*selectedChallenge?*/ firestore("one_to_one")

            //challenges.where('userId', '==', JSON.parse(localStorage.getItem('userDetails')).id)
            //.where('challengeId', '==', challenge.id)
            //.where('type', '==', "Nutritionist Video")
            .orderBy('datetime', 'desc')
            .limit(200)
            //.onSnapshot({includeMetadataChanges: true}, function(snapshot){
            .get().then(function(snapshot){

            var programs = _.orderBy(snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; obj.nutritionist=nutritionists[obj.nutritionistId]; return obj}) : [], 'datetime', 'desc').filter(d=> d.isPublished)

            programs = programs.sort((a,b)=>((b.nutritionist.ratings||0)*(b.nutritionist.rating||0))-((a.nutritionist.ratings||0)*(a.nutritionist.rating||0)))

            setPrograms(programs);

            setLoader(false);
        })
        //    : () => {}

        return [subscribeFeeds]
    }

    function fbImage(imageLink){

        if(!imageLink) return imageLink
        if(!imageLink.split) return imageLink
        var query = imageLink.split('asid=')[1]
        if(!query) return imageLink
        var fbId = query.split('&')[0]
        if(!fbId) return imageLink
            
        return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`
    }

    async function logout() {
        localStorage.clear();
        await firebase.auth().signOut();
    }

    //return (<div style={{textAlign: 'center'}}><img src={comingsoon} style={{padding: '10em'}}/></div>)

    /*return (
        <div className="page-container">
            <div className="data-container w-100 h-100 d-flex text-center justify-content-center flex-column">
                <div className="login-container shadow-lg">
                    <img
                        height="150"
                        src={logoImg}
                        alt="Welnes"
                    />
                    <div className="welcome-message-container">Welcome to Welnes!</div>

                </div>
            </div>
        </div>
    );*/


    return (<>

        <div id="Header">
            <Container fluid="md">
                <Row className="header justify-content-md-left">

                    <div className="main"><img height="40" src={logoImg}/><span>1 to 1 Followup</span></div>


                    {user && <div className="account">

                        <NavLink key={'/profile'} to={'/profile'} style={{cursor:'pointer'}}>
                            <img className="user-image" style={{cursor:'pointer'}}
                                height="90"
                                src={fbImage(user.image)}
                            />
                            <div className="user-name" style={{color: '#464646', fontWeight: 'bold'}}>{user.name}</div>
                        </NavLink>

                        {/*<Button className="sign-out" size="sm" variant="primary" onClick={() => logout()}>Sign out</Button>*/}

                    </div>}
                </Row>

            </Container>
        </div>


        <div id="Programs" className="">

            <div>
                {loader ? <div className="loader text-center"><Spinner animation="border" size={'sm'} role="status" /></div> : 

                <Container fluid="md">
                    <Row className="content">
                        <Col className="main" md={12}>

                         <h4>All 1 to 1 followup programs</h4>

                            {programs.length? programs.map((program, i) =>

                                    <Col key={program.id} lg={4} md={4} sm={12} style={{float: 'left'}} className="nutritionist-col" onClick={()=>{window.open('/'+program.nutritionist.name.replaceAll(' ', '.')+'/one.to.one')}}>
                                        <div className="nutritionist">
                                            
                                            <img className="image" height="70" width="70" src={fbImage(program.nutritionist.image)}/>
                                            <div className="details">
                                                <span className="name">{program.nutritionist.name} </span>
                                                <span className="title">{program.nutritionist.nutritionistTitle} </span>
                                                <span className="rating">
                                                    <StarRatings
                                                        rating={program.nutritionist.rating}
                                                        starDimension="12px"
                                                        starSpacing = "1px"
                                                        starRatedColor="#ffb400"
                                                        numberOfStars={5}
                                                        name='rating'
                                                    />
                                                    <span>{(program.nutritionist.rating||0).toFixed(2)} </span>
                                                    <span>({program.nutritionist.ratings} ratings) </span>
                                                </span>
                                                <span className="followers">{program.nutritionist.followersCount} followers</span>
                                            </div>
                                            <div className="arrow"></div>

                                        </div>
                                    </Col>
                                ) :
                            <div className="no-Home text-center">no programs</div>}
                        </Col>
                    </Row>
                </Container>}
            </div>
        </div>
    </>)
}
