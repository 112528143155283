
import React, {useEffect, useState} from 'react';
//import ReactPlayer from 'react-player'

import { Button, Spinner } from "react-bootstrap";
import { firestore, storage } from "../services/firebase";
import Masonry from 'react-masonry-css'
import moment from 'moment';
import * as _ from "lodash";
import firebase from "firebase";
import { NavLink, useHistory } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faComments} from '@fortawesome/free-solid-svg-icons'
import logoImg from '../assets/icons/icon.png';
import like from '../assets/images/dc-like-grey.png';
import likeActive from '../assets/images/dc-like.png';
import facebook from '../assets/images/fb.png';
import googlePlay from '../assets/images/google-play.png';
import appleStore from '../assets/images/apple-store.png';
import comingsoon from '../assets/images/comingsoon.png';
import './Feed.scss';


export default function Feed(props) {

    const [loader, setLoader] = useState(true);
    const [submitLoader, setIsSubmitLoader] = useState([]);
    const [imageFile, setImageFile] = useState();
    const [post, setPosted] = useState();

    const [feed, setFeed] = useState([]);
    const [challenges, setChallenges] = useState([]);
    const [nutritionists, setNutritioinists] = useState([]);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('userDetails')));


    useEffect(() => {

        function getMobileOperatingSystem() {
          var userAgent = navigator.userAgent || navigator.vendor || window.opera;

              // Windows Phone must come first because its UA also contains "Android"
            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone";
            }

            if (/android/i.test(userAgent)) {
                return "Android";
            }

            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }
         
            return "unknown";
        }  

        //var platform = getMobileOperatingSystem()
        //if(platform == "iOS") window.location = "https://apps.apple.com/eg/app/welnes/id1513854681"
        //if(platform == "Android") window.location = "https://play.google.com/store/apps/details?id=com.welnes"


        return () => {}

    }, []);



    useEffect(() => {

        var unsubscribes = getCollections();

        return () => {

            console.log('Do some cleanup');
            //unsubscribes.map((unsubscribe) => unsubscribe())
        }

    }, []);


    async function postImage(challenge, groups, targetGroups){

        setIsSubmitLoader(true);

        const fileName = 'video_' + Number(new Date());
        const uploaded = await storage().ref().child(`/feed_Home/${fileName}`).put(imageFile);
        const child = await storage().ref().child(uploaded.metadata.fullPath);
        const videoLink = await child.getDownloadURL();
        const fullPath = uploaded.metadata.fullPath;

        var userDetails = (JSON.parse(localStorage.getItem('userDetails')));

        var post = {

            challengeName: challenge.name,
            challengeId: challenge.id,
            userId: userDetails.id,
            userName: userDetails.name,
            type: 'Nutritionist Video',
            videoLink: videoLink,
            fileType: 'video',
            likesCount: 0,
            groupIds: ['none'],
            userImage: userDetails.image,
            datetime: new Date().toISOString(),
        }

        Array.prototype.push.apply(post.groupIds, groups.map((d)=>d.id))

        //var targetGroups = [];
        //groups.map((group) => {if(targetGroups.includes(group.id)) {targetGroups.push(group.id)}})
        //post.groups = groups.filter((group) => )

        console.log(post)

        firestore("feeds").add(post)
            .then(function(doc) {

                setIsSubmitLoader(false);
                //setVideoPosted('video posted to '+ post.challengeName + ' feed')
                setPosted('posted to challenge feed.')
                setTimeout(function(){setPosted(false)}, 10000)

                return
            })
            .catch(function(error) {

                console.error("Error finding/updating user document: ", error);
            });
    }


    async function getCollections(){

        setLoader(true);

        //const nutritionistsRef = await firestore("users").where('role', 'array-contains', "nutritionist").limit(10).get()
        //var nutritionists = _.orderBy(nutritionistsRef.docs.length? nutritionistsRef.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : [], 'datetime', 'desc')

        //const challengesRef = await firestore("challenges").orderBy('datetime', 'desc').limit(10).get()
        //var challenges = _.orderBy(challengesRef.docs.length? challengesRef.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : [], 'datetime', 'desc')

        const subscribeFeeds = /*selectedChallenge?*/ firestore("feeds")

            //challenges.where('userId', '==', JSON.parse(localStorage.getItem('userDetails')).id)
            //.where('challengeId', '==', challenge.id)
            //.where('type', '==', "Nutritionist Video")
            .orderBy('created', 'desc')
            .orderBy('welnesDayId')
            .limit(200)
            //.onSnapshot({includeMetadataChanges: true}, function(snapshot){
            .get().then(function(snapshot){

            var feed = _.orderBy(snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : [], 'datetime', 'desc').filter(d=>d.image&&d.userImage/*&&d.likesCount&&d.commentCount*/)

            feed.map((post) => {post.postType = 'post'})
            //challenges.map((challenge, i) => {challenge.postType = 'challenge'; feed.splice(Math.floor(Math.random()*(feed.length-1)), 0, challenge);})
            //nutritionists.map((nutritionist, i) => {nutritionist.postType = 'nutritionist'; feed.splice(Math.floor(Math.random()*(feed.length-1)), 0, nutritionist);})

            setFeed(_.clone(feed));

            setLoader(false);
        })
        //    : () => {}

        return [subscribeFeeds]
    }

    function fbImage(imageLink){

        if(!imageLink) return imageLink
        if(!imageLink.split) return imageLink
        var query = imageLink.split('asid=')[1]
        if(!query) return imageLink
        var fbId = query.split('&')[0]
        if(!fbId) return imageLink
            
        return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`
    }

    async function logout() {
        localStorage.clear();
        await firebase.auth().signOut();
    }

    //return (<div style={{textAlign: 'center'}}><img src={comingsoon} style={{padding: '10em'}}/></div>)

    /*return (
        <div className="page-container">
            <div className="data-container w-100 h-100 d-flex text-center justify-content-center flex-column">
                <div className="login-container shadow-lg">
                    <img
                        height="150"
                        src={logoImg}
                        alt="Welnes"
                    />
                    <div className="welcome-message-container">Welcome to Welnes!</div>

                </div>
            </div>
        </div>
    );*/


    return (<>
        <div id="Feed" className="">

            <div className="welcome">
                <div className="shade" style={{display:'none'}}></div>
                <div className="join" style={{padding:'0'}}>

                    <NavLink key={'/'} to={'/'} style={{cursor:'pointer'}}>
                        <span className="logo"><img height="60" src={logoImg}/><span style={{    color: '#555',fontSize: '12px', marginLeft: '16px', verticalAlign: 'sub'}}>by Diet Challenge, Inc.</span></span>
                        
                    </NavLink>


                    <div className="profile">

                        {user? <NavLink key={'/profile'} to={'/profile'} style={{cursor:'pointer'}}>

                            <div className="account" style={{justifyContent: "center"}}>
                                
                                <img className="user-image" style={{cursor:'pointer'}}
                                    height="90"
                                    src={fbImage(user.image)}
                                />

                                <div className="user-name">{user.name}</div>
                                {/*<Button className="sign-out" size="sm" variant="primary" onClick={() => logout()}>Sign out</Button>*/}

                            </div>
                        </NavLink> :
                        <NavLink key={'/login'} to={'/login'} style={{cursor:'pointer', display: 'inline-block'}}>

                            <div className="account">
                            
                                Login
                            </div>

                        </NavLink>}
                    </div>

                    <div className="download welnes-links">
                        <span onClick={() => {window.open('https://play.google.com/store/apps/details?id=com.welnes&hl=en')}}><img height="60" src={googlePlay}/></span>
                        <span onClick={() => {window.open('https://apps.apple.com/eg/app/welnes/id1513854681')}}><img height="60" src={appleStore}/></span>
                    </div>
                    
                    <div className="download gorilla-links">
                        <span onClick={() => {window.open('https://play.google.com/store/apps/details?id=com.dietchallenge')}}><img height="60" src={googlePlay}/></span>
                        <span onClick={() => {window.open('https://apps.apple.com/eg/app/gorilla-fit/id1498609215')}}><img height="60" src={appleStore}/></span>
                    </div>


                    {/*<img className="facebook" height="60" src={facebook}/>  to join dieters and nutritionists from all over the world*/}

                    {/*
                    <span className="note welnes-note">Download welnes app to follow trainers and nutritionists from all over the world</span>
                    <span className="note gorilla-note"></span>
                    */}

                </div>
            </div>

            <div className="background-health-container" style={{display:'none'}}>
                <div className="background-health-shade">

                </div>
            </div>

            <span className="note welnes-note" style={{ display: 'block', textAlign: 'center', fontSize: '30px', fontWeight: 'bold', padding: '1em 0 3em'}}>Join thousands of people <br/>eating healthy</span>
            <span className="note gorilla-note">{/*Download Gorilla Fit app to follow trainers and nutritionists from all over the world*/}</span>

            <div>
                {loader ? <div className="loader text-center"><Spinner animation="border" size={'sm'} role="status" /></div> : 

                feed.length? 
                <Masonry
                    breakpointCols={{default: 8, 1100: 6, 700: 4, 500: 2}}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column">
                    {feed.map((post, i) =>

                        <div key={post.id}>
                        {
                            {
                                'post': 

                                    <div className={post.postType}>

                                        <div className="main-image">
                                            <img className="meal-image" src={post.image? post.image.url : ''}/>
                                            <span className="type" style={{display:'none'}}>{post.type}</span>

                                            <div className="user">
                                                <span className="userImage"><img width="40" src={fbImage(post.userImage)}/></span>
                                                <span className="userName">{post.userName}</span>
                                            </div>
                                        </div>
                                        <div className="caption">
                                                
                                            {post.caption}

                                            <div>
                                                <span className="likes"><img src={(post.likes&&post.likes.length)?likeActive:like}/><span className="count">{post.likes?post.likes.length:0}</span></span>
                                                <span className="comments"><span className="count">{post.commentCount}</span> <FontAwesomeIcon icon={faComments} size="1x" className="mr-2" /></span>
                                            </div>
                                        </div>
                                    </div>,

                                'challenge': 

                                    <div className={post.postType}>

                                        <div className="main-image">
                                            <img src={post.image}/>
                                        </div>
                                        <div className="caption">
                                                
                                            {post.name} ({post.duration+' '+(post.durationType?post.durationType.toLowerCase():'')})

                                            <div className="author">
                                                <b>by</b> <span className="userName">{post.nutritionistName}</span>
                                            </div>
                                        </div>
                                        <div className="date">
                                            {moment(post.date).isAfter()?
                                                'Starts '+moment(post.date).fromNow().replace('a few seconds ago', 'now') : 
                                                'Started '+moment(post.date).fromNow().replace('a few seconds ago', 'now')}
                                        </div>
                                        <div>
                                            <button onClick={() => {window.open('/challenge/'+post.id)}}>Join</button>
                                        </div>
                                    </div>,

                                'nutritionist': 

                                    <div className={post.postType}>

                                        <div className="main-image">
                                            <img src={fbImage(post.image)}/>
                                        </div>
                                        <div className="user">
                                            <span className="userName">{post.name}</span>
                                            <div className="userTitle">{post.nutritionistTitle}</div>
                                        </div>
                                        <div>
                                            {/*<button onClick={() => {window.open('/'+post.name.replace(/ /g,'_'))}}>Follow</button>*/}
                                            <button onClick={() => {window.open('/coach/'+post.name.replace(/ /g,'_'))}}>Follow</button>
                                        </div>
                                    </div>,
                            }
                            [post.postType]
                        } 
                        </div>
                    )}
                </Masonry> :
                <div className="no-Home text-center">no posts</div>
                }
            </div>
        </div>
    </>)
}
