
import React, {useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
//import ReactPlayer from 'react-player'

import { Button, Spinner, Container, Modal, Form, Row, Col } from "react-bootstrap";
import { firestore, storage } from "../services/firebase";
import { events } from "../components/events";
import Masonry from 'react-masonry-css'
import moment from 'moment/min/moment-with-locales';
import * as _ from "lodash";
import firebase from "firebase";
import { NavLink, useHistory } from "react-router-dom";
import ReactPlayer from 'react-player'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faComments, faTimes, faCheckCircle, faExclamationCircle, faInfoCircle} from '@fortawesome/free-solid-svg-icons'
//import logoImg from '../assets/icons/icon.png';
import logoImg from '../assets/images/Logo.png';
import logoArImg from '../assets/box/LOGO.svg';
import logoArImgWhite from '../assets/box/LOGO-white.svg';
//import boxBg from '../assets/images/box_bg.jpg';
import boxBg from '../assets/box/Hero Section.png';
import like from '../assets/images/dc-like-grey.png';
import likeActive from '../assets/images/dc-like.png';
import facebook from '../assets/images/fb.png';
import googlePlay from '../assets/images/google-play.png';
import appleStore from '../assets/images/apple-store.png';
import comingsoon from '../assets/images/comingsoon.png';
import './Box.scss';

import arrowLeft from '../assets/box/arrow-left.svg';

import Img11 from '../assets/images/upload_plan.png';
import Img12 from '../assets/images/create_program.png';
import Img13 from '../assets/images/follow_up.png';
import Img21 from '../assets/images/group.png';
import Img22 from '../assets/images/measurements.png';
import Img23 from '../assets/images/leaderboard.png';

import Img_2 from '../assets/box/BANNER-1.gif';
//import Img_3 from '../assets/box/placeholder-welnes-box-contents.png';
import Img_3 from '../assets/box/525 X 525_Welnes Box.png';
import Img_meals from '../assets/icons/meals.png';
import Img_exercises from '../assets/icons/exercises.png';
import Img_water_cups from '../assets/icons/water_cups.png';

import Img_11 from '../assets/images/box_browse.svg';
import Img_12 from '../assets/images/box_order.svg';
import Img_13 from '../assets/images/box_book.svg';

import Img_user_1 from '../assets/box/placeholder-user1.png';
import Img_user_2 from '../assets/box/placeholder-user2.png';
import Img_user_3 from '../assets/box/placeholder-user3.png';

import Img_star from '../assets/box/reviews-star.svg';

import Img_social_1 from '../assets/box/social-facebook.svg';
import Img_social_2 from '../assets/box/social-instagram.svg';
import Img_social_3 from '../assets/box/social-youtube.svg';

import ketoImg from '../assets/box/Keto Box_V1_525x525.png';
import standardImg from '../assets/box/Standard Box_V1_525 X 525.png';

import boStop from '../assets/images/box-stop.png';

const deliveryDate = function(datetime = moment()){

    return moment(datetime)
        .add(6 - moment(datetime).day(), 'days')
        .add(+(moment(datetime).day() >= 4), 'weeks')
}

const saturdayOfMonth = function(datetime = moment()) {

    var delivery = deliveryDate()
    var day = datetime.startOf('month').day("Saturday");
    if (day.date() > 7) day.add(7,'d');
    var month = day.month();
    var n = 1
    while(month === day.month()){

        if(day.date() == delivery.date()) break;
        day.add(7,'d');
        n++
    }

    return n;
}


export default function Box(props) {

    const [choices, setChoices] = useState({

        Standard: [

            { boxes: 4, meals: 28, price: 2400, org: 2800, label: 'شهر' },
            { boxes: 2, meals: 14, price: 1300, org: 1400, label: 'إسبوعين' },
            { boxes: 1, meals: 7, price: 700, label: 'إسبوع' },
        ],

        Keto: [

            { boxes: 4, meals: 28, price: 2200, org: 2600, label: 'شهر' },
            { boxes: 2, meals: 14, price: 1200, org: 1300, label: 'إسبوعين' },
            { boxes: 1, meals: 7, price: 650, label: 'إسبوع' },
        ],
    });

    const [loader, setLoader] = useState(true);
    const [post, setPosted] = useState();

    const [feed, setFeed] = useState([]);
    const [challenges, setChallenges] = useState([]);
    const [nutritionists, setNutritioinists] = useState([]);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('userDetails')));
    const [src, setSrc] = useState(window.location.href.includes('fbclid')? 'facebook' : null);
    const [selectedBoxType, setSelectedBoxType] = useState(localStorage.getItem('selectedBoxType') || 'Standard');
    const [selectedBoxes, setSelectedBoxes] = useState(+localStorage.getItem('selectedBoxes') || 4);
    const [contact, setContact] = useState(JSON.parse(localStorage.getItem('contactDetails')) || {});
    const [code, setCode] = useState('');
    const [applied, setApplied] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [invalidCode, setInvalidCode] = useState(false);
    const [promocode, setPromocode] = useState(false);
    const [promocodeId, setPromocodeId] = useState();

    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    const [saved, setSaved] = useState(false);
    const [districts, setDistricts] = useState([
        'اكتوبر',
        'المهندسين',
        'الدقي',
        'العجوزة',
        'مدينة نصر',
        'مصر الجديدة',
        'التجمع',
        'المعادي',
        'الشروق',
        'الرحاب',
        'الشيخ زايد',
    ])

    const [formValue, setFormValue] = useState({});

    const [recipes, setRecipes] = useState();

    let { wizard } = useParams();

    useEffect(() => {

        if(!selectedBoxType) return window.location = '/box/type';
        if(!selectedBoxes) return window.location = '/box/quantity';

        return () => {

            console.log('Do some cleanup');
        }

    }, []);

    useEffect(() => {

        document.body.style.background = "white";

        //if (window.location.href.indexOf("register") > -1) gotoCheckout()

        getRecipes()

        events.add({page: 'box', ...(user) && {user: {id: user.id, name: user.name}}, ...src && {src: src}})


        if(user && user.email === 'mariam25@gmail.com'){

            setChoices({

                Standard: [

                    { boxes: 4, meals: 28, price: 17, org: 2800, label: 'شهر' },
                    { boxes: 2, meals: 14, price: 15, org: 1400, label: 'إسبوعين' },
                    { boxes: 1, meals: 7, price: 13, label: 'إسبوع' },
                ],

                Keto: [

                    { boxes: 4, meals: 28, price: 16, org: 2600, label: 'شهر' },
                    { boxes: 2, meals: 14, price: 14, org: 1300, label: 'إسبوعين' },
                    { boxes: 1, meals: 7, price: 12, label: 'إسبوع' },
                ],
            })
        }


        return () => {

            console.log('Do some cleanup');
            //unsubscribes.map((unsubscribe) => unsubscribe())
        }

    }, []);

    useEffect(() => {

        localStorage.setItem('choices', JSON.stringify(choices))

        return () => {

        }

    }, [choices]);
    
    useEffect(() => {

        localStorage.setItem('selectedBoxType', selectedBoxType)

        return () => {

        }

    }, [selectedBoxType]);

    useEffect(() => {

        localStorage.setItem('selectedBoxes', selectedBoxes)

        return () => {

        }

    }, [selectedBoxes]);

    useEffect(() => {

        if(promocodeId) localStorage.setItem('promocodeId', promocodeId)
        else localStorage.removeItem('promocodeId');

        return () => {

        }

    }, [promocodeId]);

    useEffect(() => {

        setSaved(false);

        return () => {

        }

    }, [contact]);


    async function getRecipes() {

        const mealplansSnapshot = await firestore('meal_plans').where('name', 'in', ['Standard Box', 'Keto Box']).get();
        const recipesSnapshot = await firestore("recipes").where('nutritionistId', '==', 'welnes').get()

        const recipesObj = {}
        recipesSnapshot.docs.map(d => recipesObj[d.id] = d.data())

        var recipes = []
        mealplansSnapshot.docs.map(d => {
            var type = d.data().name.replace(' Box', '');

            var weekRecipes = []
            var nWeek = saturdayOfMonth()
            var days = []
            for(var i=1;i<=7;i++) days.push((7*(nWeek-1))+i)
                console.log(days)
            
            //get week recipes
            //for(var day in d.data().recipes){
            //
            //    if(days.includes(+day)) weekRecipes.push(...d.data().recipes[day])
            //}

            //get all
            for(var day in d.data().recipes){

                weekRecipes.push(...d.data().recipes[day])
            }

            weekRecipes.map(d => {

                var recipe = {

                    type: type,
                    name: recipesObj[d].name,
                    image: recipesObj[d].image
                }

                recipes.push(recipe)
            })
        })

        console.log(recipes)

        setRecipes(recipes)
    }

    function fbImage(imageLink){

        if(!imageLink) return imageLink
        if(!imageLink.split) return imageLink
        var query = imageLink.split('asid=')[1]
        if(!query) return imageLink
        var fbId = query.split('&')[0]
        if(!fbId) return imageLink
            
        return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`
    }

    async function logout() {
        localStorage.clear();
        await firebase.auth().signOut();
        window.location.reload();
    }

    function gotoWizard(){

        return window.location = '/box/type';
        //return window.location = '/checkout/box';
    }

    function gotoCheckout(){

        return window.location = '/checkout/box';
    }

    async function submitContact(e) {
        e.stopPropagation();
        e.preventDefault();

        if(!contact.phone.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g)) return alert('يرجى إدخال رقم هاتف صحيح');

        setIsSubmitLoader(true)

        contact.datetime = moment.utc().format();

        //await firestore('users').doc(user.id).update({contact: contact});
        await firestore('leads').add(contact);

        localStorage.setItem('contactDetails', JSON.stringify(contact));

        setSaved(true)
        setIsSubmitLoader(false)

        return window.location = '/box/quantity';
    }

    async function submitForm(e) {
        e.stopPropagation();
        e.preventDefault();

        setInvalidCode(false)
        var codeDocs = await firestore('promocodes').where('code', '==', code.toLowerCase().trim()).limit(1).get()
        if(codeDocs.size) {
            setPromocodeId(codeDocs.docs[0].id);
            //setDiscount((codeDocs.docs[0].data().discount)/100); 
            setDiscount((100-codeDocs.docs[0].data().discount)/100);    //use this cuz of reverse error in db
            setApplied(true)

            const choicesClone = _.clone(choices);

            var standard = choicesClone.Standard.filter(d=>d.boxes===1)[0]
            var keto = choicesClone.Keto.filter(d=>d.boxes===1)[0]

            if(!standard.org) standard.org = standard.price;
            standard.price = Math.floor(standard.org * (codeDocs.docs[0].data().discount/100))

            if(!keto.org) keto.org = keto.price;
            keto.price = Math.floor(keto.org * (codeDocs.docs[0].data().discount/100))
            setChoices(choicesClone);
        }
        else{setInvalidCode(true)}
    }

    
    return <>

        <img src={boStop} style={{width: '100%'}}/>
    </>

    if(wizard == 'type') return (
        
        <div className="checkout-box">

            <div className="resize" id="header" style={{background: '#fff', padding: '1.2em 14% 1.2em 14%', direction: 'rtl', textAlign: 'right'}}>

                <NavLink key={'/'} to={'/'} style={{cursor:'pointer', marginLeft: '3em'}}>
                    <span className="logo"><img height="90" style={{maxWidth: '30%'}} src={logoArImg}/></span>
                </NavLink>

                {/*user && <div style={{cursor:'pointer', float: 'left', marginTop: '25px'}}><Button variant="dark" style={{background: '#0E5070', padding: '10px 20px'}} onClick={logout}>تسجيل الخروج</Button></div>*/}

                {/*<NavLink key={'/challenges'} to={'/challenges'} style={{cursor:'pointer', color: '#1E516F', margin: '0 .5em', verticalAlign: 'bottom', fontSize: '14px', fontWeight: 'bold', opacity: .9}}>Explore challenges</NavLink>*/}
                {/*<NavLink key={'/activity'} to={'/activity'} style={{cursor:'pointer', color: '#1E516F', margin: '0 .5em', verticalAlign: 'bottom', fontSize: '14px', fontWeight: 'bold', opacity: .9}}>Activity</NavLink>*/}

            </div>


            <div className="resize" style={{padding: '0 14% 0 14%', textAlign: 'left', background: '#fff'}}>
                <NavLink key={'/box'} to={'/box'}>
                    <img src={arrowLeft} style={{height: '20px'}}/>
                </NavLink>
            </div>

            <div className="resize" style={{color: '#0E5070', direction: 'rtl', textAlign: 'right', position: 'relative', background: '#fff', paddingTop: '50px'}}>

                <div style={{textAlign: 'center'}}>

                    <h1 style={{fontWeight: 'bold', lineHeight: '1.5'}}>١- إختر نظامك الغذائي</h1>
                    

                    <div style={{display: 'flex', direction: 'ltr', justifyContent: 'center', marginTop: '2%', color: '#0E5070'}}>

                        <div style={{margin: '2%'}} className={"meal-option" + (selectedBoxType === "Keto" ? " selected":"")} onClick={() => {setSelectedBoxType("Keto")}}>
                            <img src={ketoImg} width="400px" style={{maxWidth: '100%'}}/>
                            <div className="option-content" style={{marginTop: '-62px', fontSize: '18px', fontWeight: 'bold'}}>
                                <div>كيتو (د. وعد)</div>
                                {recipes && <div style={{fontSize: '14px', fontWeight: 'normal', marginTop: '4px', direction: 'rtl'}}>{/*toArabicDigits(recipes.filter(d => d.type === 'Keto').length)*/7} وجبات  </div>}
                            </div>
                        </div>
                        <div style={{margin: '2%'}} className={"meal-option" + (selectedBoxType === "Standard" ? " selected":"")} onClick={() => {setSelectedBoxType("Standard")}}>
                            <img src={standardImg} width="400px" style={{maxWidth: '100%'}}/>
                            <div className="option-content" style={{marginTop: '-62px', fontSize: '18px', fontWeight: 'bold'}}>
                                <div>عادي</div>
                                {recipes && <div style={{fontSize: '14px', fontWeight: 'normal', marginTop: '4px', direction: 'rtl'}}>{/*toArabicDigits(recipes.filter(d => d.type === 'Standard').length)*/7} وجبات  </div>}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="resize recipes-offered-header" style={{background: '#fff', color: '#0E5070', padding: '2% 0 2%', textAlign: 'center', fontWeight: 'bold', direction: 'rtl', marginTop: '2%'}}>

                {selectedBoxType === 'Standard' && <div style={{background: '#F9F0E4', borderRadius: '10px', overflowX: 'auto', whiteSpace: 'nowrap', padding: '1.9%', boxSizing: 'content-box', direction: 'ltr', marginBottom: '30px', width: '800px', margin: '0 auto', maxWidth: '90%'}} className="recipes-offered">
                    <div className="recipes-offered-title" style={{transform: 'rotate(-90deg) translateX(150px)', display: 'inline-block', fontSize: '24px', width: '200px'}}>نظام عادي</div>

                    {recipes && recipes.filter(d => d.type === 'Standard').map(recipe => <div className="recipes-offered-item" style={{display: 'inline-block', padding: '20px', margin: '0 30px', background: '#fff', color: '#0E5070', borderRadius: '10px', border: '3px solid #0E5070', width: '300px'}}>
                        <img src={recipe.image} style={{width: '100%', borderRadius: '10px', height: '280px', objectFit: 'cover'}}/>
                        <div style={{paddingTop: '20px'}}>{recipe.name}</div>
                    </div>)}
                </div>}

                {selectedBoxType === 'Keto' && <div style={{background: '#D4EBF4', borderRadius: '10px', overflowX: 'auto', whiteSpace: 'nowrap', padding: '1.9%', boxSizing: 'content-box', direction: 'ltr', marginBottom: '30px', width: '800px', margin: '0 auto', maxWidth: '90%'}} className="recipes-offered">
                    <div className="recipes-offered-title" style={{transform: 'rotate(-90deg) translateX(150px)', display: 'inline-block', fontSize: '24px', width: '200px'}}>نظام كيتو (د. وعد)</div>

                    {recipes && recipes.filter(d => d.type === 'Keto').map(recipe => <div className="recipes-offered-item" style={{display: 'inline-block', padding: '20px', margin: '0 30px', background: '#fff', color: '#0E5070', borderRadius: '10px', border: '3px solid #0E5070', width: '300px'}}>
                        <img src={recipe.image} style={{width: '100%', borderRadius: '10px', height: '280px', objectFit: 'cover'}}/>
                        <div style={{paddingTop: '20px'}}>{recipe.name}</div>
                    </div>)}
                </div>}
            </div>

            <div className="resize" style={{background: '#fff', padding: '10% 10%'}}>
                
                <div style={{borderBottom: '2px solid #E2EAED', padding: '0 10%', boxSizing: 'border'}}></div>

            </div>


            <div className="resize" style={{background: '#fff', color: '#0E5070', direction: 'rtl', textAlign: 'center', position: 'relative', background: '#fff', padding: '2% 0 2%', maxWidth: '90%', margin: '0 auto'}}>

                <div style={{textAlign: 'center'}}>

                    <h1 style={{fontWeight: 'bold', lineHeight: '1.5', marginBottom: '70px'}}>٢ - إدخل معلومات التوصيل</h1>

                    <Form className="checkout-box-contact" onSubmit={(e) => {submitContact(e)}} style={{width: '800px', margin: '0 auto'}}>

                        <Row>
                            <Col lg={6} xs={12} style={{marginBottom: '.5em'}}>
                                <Form.Label>الإسم بالكامل</Form.Label>
                                <Form.Control required value={contact.name} onChange={e => {
                                    const formValueClone = _.clone(contact);
                                    formValueClone.name = e.target.value;
                                    setContact(formValueClone);
                                }} />
                            </Col>
                            <Col lg={6} xs={12} style={{marginBottom: '.5em'}}>
                                <Form.Label>رقم الهاتف</Form.Label>
                                <Form.Control required value={contact.phone} onChange={e => {
                                    const formValueClone = _.clone(contact);
                                    formValueClone.phone = e.target.value;
                                    setContact(formValueClone);
                                }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} xs={12} style={{marginBottom: '.5em'}}>
                                <Form.Label>إختر منطقتك</Form.Label>
                                <Form.Control required value={contact.district} as="select" style={{borderColor: '#eee'}} onChange={e => {
                                    const formValueClone = _.clone(contact);
                                    formValueClone.district = e.target.value;
                                    setContact(formValueClone);
                                }}>
                                    <option disabled selected key={''}></option>
                                    {districts.map(d => {
                                        return <option value={d} key={d}>{d}</option>
                                    })}
                                </Form.Control>
                            </Col>
                            <Col lg={6} xs={12} style={{marginBottom: '.5em'}}>
                                <Form.Label>العنوان</Form.Label>
                                <Form.Control required value={contact.address} onChange={e => {
                                    const formValueClone = _.clone(contact);
                                    formValueClone.address = e.target.value;
                                    setContact(formValueClone);
                                }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Form.Label></Form.Label>
                                <li>يتم توصيل الطلبات يوم السبت من كل إسبوع</li>
                                <li>موعد تسليم الطلبات القادم السبت {deliveryDate().locale('ar').format('DD MMMM')}</li>
                            </Col>
                        </Row>

                        <Button variant="dark" type="submit" style={{background: '#0E5070', margin: '5em auto 7em', padding: '10px 50px'}}>
                            {isSubmitLoader ? <Spinner animation="border" size={'sm'} role="status" /> : saved? 'تم الحفظ' : 'التالي'}
                        </Button>

                    </Form>

                </div>
                
            </div>

            <div className="resize" style={{background: '#0E5070', padding: '1.2em 14% 1.2em 14%', direction: 'rtl', textAlign: 'right', height: '100px'}}>

                <div className="resize-block" style={{float: 'left', marginTop: '0px'}}>
                    <span className="logo"><img height="60" src={logoArImgWhite}/></span>
                </div>

                <div className="resize-block" style={{float: 'right', marginTop: '20px'}}>
                    <span style={{margin: '.5em', cursor: 'pointer'}} onClick={()=>{window.open('https://www.facebook.com/welnesapp')}}><img src={Img_social_1}/></span>
                    <span style={{margin: '.5em', cursor: 'pointer'}} onClick={()=>{window.open('https://www.instagram.com/welnes.app')}}><img src={Img_social_2}/></span>
                    {/*<span style={{margin: '.5em'}} onClick={()=>{}}><img src={Img_social_3}/></span>*/}
                </div>
            </div>

        </div>
    );

    if(wizard == 'quantity') return (

        <div className="checkout-box">

            <div className="resize" id="header" style={{background: '#fff', padding: '1.2em 14% 1.2em 14%', direction: 'rtl', textAlign: 'right'}}>

                <NavLink key={'/'} to={'/'} style={{cursor:'pointer', marginLeft: '3em'}}>
                    <span className="logo"><img height="90" style={{maxWidth: '30%'}} src={logoArImg}/></span>
                </NavLink>

                {/*user && <div style={{cursor:'pointer', float: 'left', marginTop: '25px'}}><Button variant="dark" style={{background: '#0E5070', padding: '10px 20px'}} onClick={logout}>تسجيل الخروج</Button></div>*/}

                {/*<NavLink key={'/challenges'} to={'/challenges'} style={{cursor:'pointer', color: '#1E516F', margin: '0 .5em', verticalAlign: 'bottom', fontSize: '14px', fontWeight: 'bold', opacity: .9}}>Explore challenges</NavLink>*/}
                {/*<NavLink key={'/activity'} to={'/activity'} style={{cursor:'pointer', color: '#1E516F', margin: '0 .5em', verticalAlign: 'bottom', fontSize: '14px', fontWeight: 'bold', opacity: .9}}>Activity</NavLink>*/}

            </div>

            <div className="resize" style={{padding: '0 14% 0 14%', textAlign: 'left', background: '#fff'}}>
                <NavLink key={'/box/type'} to={'/box/type'}>
                    <img src={arrowLeft} style={{height: '20px'}}/>
                </NavLink>
            </div>

            <div className="resize" style={{background: '#fff', color: '#0E5070', direction: 'rtl', textAlign: 'right', position: 'relative', background: '#fff', paddingTop: '100px'}}>

                <div style={{textAlign: 'center'}}>

                    <h1 style={{fontWeight: 'bold', lineHeight: '1.5', marginBottom: '70px'}}>٣ - إختر نظام الدفع</h1>

                    <div>
                        
                        <div style={{width: '400px', maxWidth: '95%', margin: '0 auto', marginTop: '20px'}}>
                            {choices[selectedBoxType].map(choice => 
                                <div className={"price-option" + (selectedBoxes === choice.boxes ? " selected":"")} onClick={() => {setSelectedBoxes(choice.boxes);}}>
                                    <span className="price-option-rounds">{choice.label}<div>{choice.boxes} بوكس</div><div>{choice.meals} وجبة</div></span>
                                    {choice.org && <span className="price-option-discount">{choice.boxes===1?' تم خصم '  : 'وفر '}{choice.org - choice.price} جنيه</span>}
                                    <span className="price-option-price" style={choice.org && {paddingTop: 0}}>
                                        {choice.org && <span style={{display: 'block', lineHeight: '20px'}}>{choice.org} جنيه
                                            <span style={{position: 'absolute', width: '100%', height: '2px', background: '#FAA620', left: '0', top: '10px'}}></span>
                                        </span>}
                                        <span style={{display: 'block'}}>{choice.price} جنيه</span>
                                        <div>{Math.ceil(choice.price / choice.meals)} جنيه لكل وجبة</div>
                                    </span>
                                </div>
                            )}
                        </div>


                        <div style={{width: '400px', maxWidth: '100%', margin: '0 auto'}}>
                            <span className={"promocode"} style={{width: selectedBoxes === 1 ? '96%' : '92%'}}>

                                {!promocode && <div className={"promocode-div"}  style={{direction: 'rtl'}}>
                                    <span>
                                        {applied? 'تم تطبيق كود الخصم' : 'لديك كود خصم ؟'}
                                        <div>
                                            <div className={"promocode-note"}>يطبق فقط لإختيار إسبوع</div>
                                        </div>
                                    </span>
                                    <span className="add-promocode" onClick={()=>{setPromocode(true); setSelectedBoxes(1);}}>إضافة كود خصم</span>
                                </div>}
                                {promocode && <div style={{display: 'block'}}>
                                    <Form className="form" onSubmit={(e) => {submitForm(e)}} style={{margin: '0 auto'}}>
                                        <FontAwesomeIcon icon={faTimes} size="1x" onClick={() => {setPromocode(false)}} className="close-promo" style={{left: '10px', right: 'initial'}}/>
                                        <label style={{textAlign: 'right'}}>كود الخصم</label>
                                        <div style={{display:'flex'}}>
                                            <Form.Control required defaultValue={code} onChange={e => {
                                                setCode(e.target.value);
                                            }}>
                                            </Form.Control>
                                            {applied?
                                            <Button variant="primary" type="submit" className="submit-button applied" style={{marginLeft: 0, marginRight: '1em', whiteSpace: 'nowrap'}}>
                                                تم التطبيق
                                            </Button> :
                                            <Button variant="primary" type="submit" className="submit-button" style={{marginLeft: 0, marginRight: '1em'}}>
                                                تطبيق
                                            </Button>
                                            }
                                        </div>
                                        {applied && <div className="response applied" style={{textAlign: 'right'}}><FontAwesomeIcon icon={faCheckCircle} size="1x"/>تم تطبيق كود الخصم</div>}
                                        {invalidCode && <div className="response invalid" style={{textAlign: 'right'}}><FontAwesomeIcon icon={faExclamationCircle} size="1x"/>كود خصم غير صحيح</div>}
                                    </Form>
                                </div>}
                            </span>
                        </div>



                    </div>

                </div>

            </div>

            <div style={{textAlign: 'center', padding: '5em 0 20em'}}>
                <Button variant="dark" style={{background: '#0E5070', padding: '10px 20px', margin: '2em auto', fontSize: '18px'}} onClick={()=>{gotoCheckout()}}>{user? 'إدفع' : 'تسجيل الدخول'}</Button>
            </div>

            <div className="resize" style={{background: '#0E5070', padding: '1.2em 14% 1.2em 14%', direction: 'rtl', textAlign: 'right', height: '100px'}}>

                <div className="resize-block" style={{float: 'left', marginTop: '0px'}}>
                    <span className="logo"><img height="60" src={logoArImgWhite}/></span>
                </div>

                <div className="resize-block" style={{float: 'right', marginTop: '20px'}}>
                    <span style={{margin: '.5em', cursor: 'pointer'}} onClick={()=>{window.open('https://www.facebook.com/welnesapp')}}><img src={Img_social_1}/></span>
                    <span style={{margin: '.5em', cursor: 'pointer'}} onClick={()=>{window.open('https://www.instagram.com/welnes.app')}}><img src={Img_social_2}/></span>
                    {/*<span style={{margin: '.5em'}} onClick={()=>{}}><img src={Img_social_3}/></span>*/}
                </div>
            </div>
        </div>
    );

    return (<>
        <div fluid="md" id="Box" className="" style={{}}>

            <div className="resize" id="header" style={{background: '#fff', padding: '1.2em 14% 1.2em 14%', direction: 'rtl', textAlign: 'right'}}>

                <NavLink key={'/'} to={'/'} style={{cursor:'pointer', marginLeft: '3em'}}>
                    <span className="logo"><img height="90" src={logoArImg}/></span>
                </NavLink>

                {/*user ? 
                    <div style={{cursor:'pointer', float: 'left', marginTop: '25px'}}><Button variant="dark" style={{background: '#0E5070', padding: '10px 20px'}} onClick={logout}>تسجيل الخروج</Button></div> :
                    <NavLink key={`/checkout/box`} to={`/checkout/box`} style={{cursor:'pointer', float: 'left', marginTop: '25px'}}><Button variant="dark" style={{background: '#0E5070', padding: '10px 20px'}}>تسجيل الدخول </Button></NavLink>
                */}

                {/*<NavLink key={'/challenges'} to={'/challenges'} style={{cursor:'pointer', color: '#1E516F', margin: '0 .5em', verticalAlign: 'bottom', fontSize: '14px', fontWeight: 'bold', opacity: .9}}>Explore challenges</NavLink>*/}
                {/*<NavLink key={'/activity'} to={'/activity'} style={{cursor:'pointer', color: '#1E516F', margin: '0 .5em', verticalAlign: 'bottom', fontSize: '14px', fontWeight: 'bold', opacity: .9}}>Activity</NavLink>*/}

            </div>

            <div className="resize" style={{color: '#0E5070', direction: 'rtl', textAlign: 'right', position: 'relative', backgroundImage: `url("${boxBg}")`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'right'}}>

                <div style={{padding: '200px 14% 100px 14%', textShadow: '0px 0px 5px #fff'}} className="box-bg">

                    <h1 style={{fontWeight: 'bold', lineHeight: '1.5'}}>كل الوجبات المتبلة<br/>لنظامك الغذائي في مطبخك</h1>
                    <br/>
                    {/*<p style={{fontSize: '17px'}}>وفر الوقت والمال واحصل على مكونات طازجة معدة ومقاسة بعناية لكل وجبات<br/>
                    نظامك الغذائي من خبراء تغذية ويلنس إلى مطبخك مباشرةً!
                    </p>*/}
                    <br/>
                    <Button style={{background: '#FAA620', padding: '1em 2em', marginRight: '0', border: 'none', fontSize: '18px'}} onClick={()=>{gotoWizard()}}>إبدأ الآن</Button>

                </div>

            </div>

            <div className="resize" style={{background: '#fff', color: '#0E5070', padding: '2% 14% 7em', textAlign: 'center', fontWeight: 'bold', direction: 'rtl'}}>

                <h1 style={{fontWeight: 'bold', margin: '3em auto'}}>الخطوات</h1>

                <div className="resize-block" style={{display: 'inline-block', marginTop: '7%', display: 'flex', justifyContent: 'space-between', direction: 'rtl', fontWeight: 'normal'}}>
                    <div style={{flexBasis: 0, flexGrow: .333}}>
                        <img height="100px" src={Img_11}/>
                        <h4 style={{marginBottom: '.5em', marginTop: '3em', fontWeight: 'bold'}}>تصفح الوجبات</h4>
                        <p>
                            تصفح الوجبات الأسبوعية المناسبة لنظامك الغذائي
                        </p>
                    </div>
                    <div style={{flexBasis: 0, flexGrow: .333}}>
                        <img height="100px" src={Img_12} style={{ marginLeft: '-46px'}}/>
                        <h4 style={{marginBottom: '.5em', marginTop: '3em', fontWeight: 'bold'}}>اطلب الوجبات</h4>
                        <p>
                            اطلب ويلنس بوكس و استمتع بمجموعة متنوعة من الوجبات المتبلة جاهزة للتحضير معدة خصيصا لنظامك الغذائي
                        </p>
                    </div>
                    <div style={{flexBasis: 0, flexGrow: .333}}>
                        <img height="100px" src={Img_13}/>
                        <h4 style={{marginBottom: '.5em', marginTop: '3em', fontWeight: 'bold'}}>اطبخ سريعا و استمتع</h4>
                        <p>
                            اطبخ في أقل من 20 دقيقة و استمتع
                        </p>
                    </div>
                </div>
            </div>

            <div className="box-cook" style={{}}>

                <div className="" style={{}}>
                    <img style={{width: '100%', minHeight: '300px', objectFit: 'contain', objectPosition: 'right', backgroundColor: '#fff'}} src={Img_2}/>
                </div>
            </div>

            <div className="resize recipes-offered-header" style={{background: '#fff', color: '#0E5070', padding: '2% 14% 7em', textAlign: 'center', fontWeight: 'bold', direction: 'rtl', borderBottom: '1px solid #ddd'}}>

                <h1 style={{fontWeight: 'bold', margin: '3em auto'}}>وجبات هذا الإسبوع</h1>

                <div style={{background: '#F9F0E4', borderRadius: '10px', overflowX: 'auto', whiteSpace: 'nowrap', padding: '20px', direction: 'ltr', marginBottom: '30px'}} className="recipes-offered">
                    <div className="recipes-offered-title" style={{transform: 'rotate(-90deg) translateX(150px)', display: 'inline-block', fontSize: '24px', width: '200px'}}>نظام عادي</div>

                    {recipes && recipes.filter(d => d.type === 'Standard').map(recipe => <div className="recipes-offered-item" style={{display: 'inline-block', padding: '20px', margin: '0 30px', background: '#fff', color: '#0E5070', borderRadius: '10px', border: '3px solid #0E5070', width: '300px'}}>
                        <img src={recipe.image} style={{width: '100%', borderRadius: '10px', height: '280px', objectFit: 'cover'}}/>
                        <div style={{paddingTop: '20px'}}>{recipe.name}</div>
                    </div>)}
                </div>

                <div style={{background: '#D4EBF4', borderRadius: '10px', overflowX: 'auto', whiteSpace: 'nowrap', padding: '20px', direction: 'ltr', marginBottom: '30px'}} className="recipes-offered">
                    <div className="recipes-offered-title" style={{transform: 'rotate(-90deg) translateX(150px)', display: 'inline-block', fontSize: '24px', width: '200px'}}>نظام كيتو (د. وعد)</div>

                    {recipes && recipes.filter(d => d.type === 'Keto').map(recipe => <div className="recipes-offered-item" style={{display: 'inline-block', padding: '20px', margin: '0 30px', background: '#fff', color: '#0E5070', borderRadius: '10px', border: '3px solid #0E5070', width: '300px'}}>
                        <img src={recipe.image} style={{width: '100%', borderRadius: '10px', height: '280px', objectFit: 'cover'}}/>
                        <div style={{paddingTop: '20px'}}>{recipe.name}</div>
                    </div>)}
                </div>

                <Button variant="dark" style={{background: '#0E5070', padding: '10px 20px', margin: '2em auto', fontSize: '18px'}} onClick={()=>{gotoWizard()}}>احصل على ويلنس بوكس</Button>
            </div>

            <div className="resize box_components" style={{padding: '2% 14% 7em', backgroundColor: '#C6D8E0', color: '#0E5070', position: 'relative', backgroundImage: `url("${Img_3}")`, backgroundRepeat: 'no-repeat', backgroundPosition: '14%'}}>

                <div style={{direction: 'rtl', textAlign: 'center'}}>

                    <h1 style={{fontWeight: 'bold', lineHeight: '1.5', margin: '3em auto', textAlign: 'center'}}>محتويات ويلنس بوكس</h1>
                    <br/>
                    <img style={{display: 'none'}} src={Img_3}/>
                    <br/>
                    <p style={{fontSize: '17px', textAlign: 'right'}}>
                    <ul>
                        <li><div>وجبات متبلة و مجهزة على الطبخ من قبل خبراء التغذية الصحية مصحوبة بمطبوعات لإرشادات الطبخ.</div></li>
                        <li><div>أفضل المكونات الموسمية عالية الجودة مختارة خصيصاً لك.</div></li>
                        <li><div>مكونات مقاسة بدقة لكل وجبة وحسب احتياجك.</div></li>
                        <li><div>عبوات أقل لإعادة التدوير وقابلة لإعادة الإستخدام وصديقة للبيئة.</div></li>
                    </ul>
                    </p>
                    <br/>
                
                    <Button variant="dark" style={{background: '#0E5070', padding: '10px 20px', margin: '2em auto', fontSize: '18px'}} onClick={()=>{gotoWizard()}}>احصل على ويلنس بوكس</Button>

                </div>

            </div>

            <div className="resize testimonial" style={{background: '#fff', color: '#0E5070', padding: '2% 14%', borderTop: '1px solid #aaa', textAlign: 'center', fontWeight: 'normal'}}>

                <h1 style={{fontWeight: 'bold', margin: '2.5em auto 1.5em'}}>تقييمات العملاء</h1>

                <div className="resize-block" style={{display: 'inline-block', marginTop: '7%', display: 'flex', justifyContent: 'space-between', fontSize: '15px'}}>
                    <div className="resize-block-div" style={{margin: '1.5em', width: '30%', textAlign: 'left', padding: '1.5em', overflow: 'hidden', textAlign: 'center'}}>
                        <div style={{display: 'flex'}}>
                            <img height="50" src={Img_user_1} style={{display: 'inline-block', float: 'left', borderRadius: '30px', marginRight: '.5em'}}/>
                            <div style={{fontSize: '24px', lineHeight: '50px'}}>

                                <span style={{display: 'block', whiteSpace: 'nowrap'}}>Mohamed Fouda</span>
                            </div>
                        </div>
                        <div style={{border: '2px solid #0E5070', height: '400px', background: '#EEF1F4', margin: '1em 0 2em'}}>
                            <iframe src="https://drive.google.com/file/d/1Hzsxfmha0NB08LiS6WfIwPFTcDUOcZBy/preview" width="100%" height="100%" allow="autoplay" style={{border: '0'}}></iframe>
                        </div>
                        <div>
                            <img style={{margin: '0 .2em'}} src={Img_star}/>
                            <img style={{margin: '0 .2em'}} src={Img_star}/>
                            <img style={{margin: '0 .2em'}} src={Img_star}/>
                            <img style={{margin: '0 .2em'}} src={Img_star}/>
                            <img style={{margin: '0 .2em'}} src={Img_star}/>
                        </div>
                        <div style={{fontWeight: 'bold'}}>5.00</div>
                    </div>
                    <div className="resize-block-div" style={{margin: '1.5em', width: '30%', textAlign: 'left', padding: '1.5em', overflow: 'hidden', textAlign: 'center'}}>
                        <div style={{display: 'flex'}}>
                            <img height="50" src={Img_user_2} style={{display: 'inline-block', float: 'left', borderRadius: '30px', marginRight: '.5em'}}/>
                            <div style={{fontSize: '24px', lineHeight: '50px'}}>

                                <span style={{display: 'block', whiteSpace: 'nowrap'}}>Noura Ahmed</span>
                            </div>
                        </div>
                        <div style={{border: '2px solid #0E5070', height: '400px', background: '#EEF1F4', margin: '1em 0 2em'}}>
                            <iframe src="https://drive.google.com/file/d/1dXHgl87jFDHKcjde-rd-CdsAAUMgavie/preview" width="100%" height="100%" allow="autoplay" style={{border: '0'}}></iframe>
                        </div>
                        <div>
                            <img style={{margin: '0 .2em'}} src={Img_star}/>
                            <img style={{margin: '0 .2em'}} src={Img_star}/>
                            <img style={{margin: '0 .2em'}} src={Img_star}/>
                            <img style={{margin: '0 .2em'}} src={Img_star}/>
                            <img style={{margin: '0 .2em'}} src={Img_star}/>
                        </div>
                        <div style={{fontWeight: 'bold'}}>5.00</div>
                    </div>
                    <div className="resize-block-div" style={{margin: '1.5em', width: '30%', textAlign: 'left', padding: '1.5em', overflow: 'hidden', textAlign: 'center'}}>
                        <div style={{display: 'flex'}}>
                            <img height="50" src={Img_user_3} style={{display: 'inline-block', float: 'left', borderRadius: '30px', marginRight: '.5em'}}/>
                            <div style={{fontSize: '24px', lineHeight: '50px'}}>

                                <span style={{display: 'block', whiteSpace: 'nowrap'}}>Nadine Farrag</span>
                            </div>
                        </div>
                        <div style={{border: '2px solid #0E5070', height: '400px', background: '#EEF1F4', margin: '1em 0 2em'}}>
                            <iframe src="https://drive.google.com/file/d/19zrB_hojzbNh4IAt2Z4w__1j_5v45TwK/preview?start=1" width="100%" height="100%" allow="autoplay" style={{border: '0'}}></iframe>
                        </div>
                        <div>
                            <img style={{margin: '0 .2em'}} src={Img_star}/>
                            <img style={{margin: '0 .2em'}} src={Img_star}/>
                            <img style={{margin: '0 .2em'}} src={Img_star}/>
                            <img style={{margin: '0 .2em'}} src={Img_star}/>
                            <img style={{margin: '0 .2em'}} src={Img_star}/>
                        </div>
                        <div style={{fontWeight: 'bold'}}>5.00</div>
                    </div>
                </div>

            </div>

            <div className="resize" style={{background: '#0E5070', padding: '1.2em 14% 1.2em 14%', direction: 'rtl', textAlign: 'right', height: '250px'}}>

                <div className="resize-block" style={{float: 'left', marginTop: '50px'}}>
                    <span className="logo"><img height="90" src={logoArImgWhite}/></span>
                </div>

                <div className="resize-block" style={{float: 'right', marginTop: '80px'}}>
                    <span style={{margin: '.5em', cursor: 'pointer'}} onClick={()=>{window.open('https://www.facebook.com/welnesapp')}}><img src={Img_social_1}/></span>
                    <span style={{margin: '.5em', cursor: 'pointer'}} onClick={()=>{window.open('https://www.instagram.com/welnes.app')}}><img src={Img_social_2}/></span>
                    {/*<span style={{margin: '.5em'}} onClick={()=>{}}><img src={Img_social_3}/></span>*/}
                </div>
            </div>



        </div>
    </>)
}
