import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import firebase from "firebase";
import {
  Button,
  Spinner,
  Container,
  Row,
  Col,
  Tabs,
  Tab,
  Form,
} from "react-bootstrap";
import { firestore, storage } from "../services/firebase";
import Masonry from "react-masonry-css";
import moment from "moment";
import * as _ from "lodash";
import StarRatings from "react-star-ratings";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { NavLink, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { events } from "../components/events";
import CryptoJS from "crypto-js";

import logoImg from "../assets/images/Logo.png";
import logoDark from "../assets/images/Logo_.png";
import axios from "axios";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import "./Checkout.scss";

import dietType_standard from "../assets/icons/diet-types/standard.svg";
import dietType_plantbased from "../assets/icons/diet-types/plantbased.svg";
import dietType_pescatarian from "../assets/icons/diet-types/pescatarian.svg";
import dietType_lowcarb from "../assets/icons/diet-types/lowcarb.svg";
import dietType_keto from "../assets/icons/diet-types/keto.svg";

import exerciseType_home from "../assets/icons/exercise-types/home.png";
import exerciseType_equipment from "../assets/icons/exercise-types/equipment.png";
import exerciseType_gym from "../assets/icons/exercise-types/gym.png";
import { rudderAnalytics } from "../services/trackers";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// Setup Stripe.js and the Elements provider
const stripePromise = loadStripe("pk_live_olYCygAG79BGQpAn9lrdRWBN00DZZ2Eb37");

export default function Checkout(props) {
  let { item, id } = useParams();

  const [bundles, setBundles] =
    useState(/*{
        1: 0,
        2: 15,
        3: 27
    }*/);
  const [oneToOneDiscount, setOneToOneDiscount] = useState({
    1: 0,
    2: 15,
    3: 27,
  });
  const [bundleAmount, setBundleAmount] = useState({});
  const [oneToOneAmount, setOneToOneAmount] = useState({});
  const [selectedRounds, setSelectedRounds] = useState(3);
  const [selectedMonths, setSelectedMonths] = useState(3);
  const [orgAmount, setOrgAmount] = useState();
  const [amount, setAmount] = useState();
  const [currency, setCurrency] = useState();
  const [loader, setLoader] = useState(false);
  const [code, setCode] = useState("");
  const [applied, setApplied] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [invalidCode, setInvalidCode] = useState(false);
  const [promocode, setPromocode] = useState(false);
  const [promocodeId, setPromocodeId] = useState();
  const [submitLoader, setIsSubmitLoader] = useState([]);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("userDetails"))
  );
  const [src, setSrc] = useState(localStorage.getItem("src"));
  const [challenge, setChallenge] = useState();
  const [location, setLocation] = useState();
  const [succeeded, setSucceeded] = useState(false);

  const [selectedDietType, setSelectedDietType] = useState();
  const [selectedExerciseType, setSelectedExerciseType] = useState();
  const [errorMessage, setErrorMessage] = useState("invalid code!");
  const [promocodeEngineData, setPromocodeEngineData] = useState("");

  const dietTypes = [
    {
      key: "Standard",
      title: "Standard (I enjoy eating everything)",
      icon: dietType_standard,
    },
    {
      key: "Plant Based",
      title: "Plant Based (I avoid animal products)",
      icon: dietType_plantbased,
    },
    {
      key: "Pescatarian",
      title: "Pescatarian (I avoid meat but i eat fish)",
      icon: dietType_pescatarian,
    },
    {
      key: "Low Carb",
      title: "Low Carb (I minimize carb intake)",
      icon: dietType_lowcarb,
    },
    {
      key: "Keto",
      title: "Keto (I avoid any carb intake)",
      icon: dietType_keto,
    },
  ];

  const exerciseTypes = [
    { key: "home", title: "Home workout", icon: exerciseType_home },
    { key: "gym", title: "Gym workout", icon: exerciseType_gym },
  ];

  const getLocation = async () => {
    //if(user && user.country) return setLocation(user.country)

    try {
      const resp = await axios.get("https://ipapi.co/json/");
      console.log("geo", resp.data);
      setLocation(resp.data.country_code);
    } catch (err) {
      console.error(err);
      setLocation("N/A");
    }
  };

  useEffect(() => {
    async function fetch() {
      var promocodeRef = await firestore("constants").doc("app").get();
      setPromocodeEngineData(promocodeRef.data().promocodeEngine);
    }
    fetch();
    return () => {};
  }, []);

  useEffect(() => {
    var location = getLocation();

    return () => {
      console.log("Do some cleanup");
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      var _challenge;

      if (item === "followup") {
        const doc = await firestore("one_to_one").doc(id).get();
        _challenge = { ...doc.data(), id: doc.id, oneToOne: true };
      } else if (item === "challenge") {
        const doc = await firestore("challenges").doc(id).get();
        _challenge = { ...doc.data(), id: doc.id };
      }

      _challenge.weeks =
        _challenge.durationType.toLowerCase().trim() === "weeks"
          ? _challenge.duration
          : +(_challenge.duration / 7).toFixed(0);

      if (_challenge.isBundleable) {
        setBundles(_challenge.bundles);
        setSelectedRounds(
          Math.max(...Object.keys(_challenge.bundles).map((d) => +d))
        );
        console.log(
          Math.max(...Object.keys(_challenge.bundles).map((d) => +d))
        );
      }

      setChallenge(_challenge);

      if (_challenge.packages && _challenge.packages[3]) setSelectedMonths(3);
      else if (_challenge.packages && _challenge.packages[2])
        setSelectedMonths(2);
      else if (_challenge.packages && _challenge.packages[1])
        setSelectedMonths(1);

      var dietType, exerciseType;

      dietTypes.map((d) => {
        //set default type

        if (
          _challenge.dietTypes &&
          _challenge.dietTypes[d.key] === true &&
          !dietType
        )
          dietType = d.key;
      });

      exerciseTypes.map((d) => {
        //set default type

        if (
          _challenge.exerciseTypes &&
          _challenge.exerciseTypes[d.key] === true &&
          !exerciseType
        )
          exerciseType = d.key;
      });

      //user selected type
      const doc = await firestore("users").doc(user.id).get();

      if (!doc.data().userPromocode) {
        const generateShortCode = (input_data) => {
          // Generate hash value using SHA256
          const hash_value = CryptoJS.SHA256(input_data);

          // Convert the hash value to a base64 encoded string
          const base64_encoded = CryptoJS.enc.Base64.stringify(hash_value);

          // Take the first 5 characters from the base64 encoded string
          let short_code = base64_encoded.substr(0, 5);

          // Replace any non-alphanumeric characters with alphanumeric ones
          short_code = short_code.replace(/[^a-zA-Z0-9]/g, "");

          return short_code;
        };
        var shortCode = generateShortCode(user.id);
        firestore("users").doc(user.id).update({ userPromocode: shortCode });
      }

      if (_challenge.dietTypes)
        for (var type in _challenge.dietTypes) {
          if (
            _challenge.dietTypes[type] === true &&
            doc.data().healthInfo &&
            doc.data().healthInfo.dietType === type
          ) {
            dietType = doc.data().healthInfo.dietType;
            break;
          }
        }

      if (_challenge.exerciseTypes)
        for (var type in _challenge.exerciseTypes) {
          if (
            _challenge.exerciseTypes[type] === true &&
            doc.data().healthInfo &&
            doc.data().healthInfo.exerciseType === type
          ) {
            exerciseType = doc.data().healthInfo.exerciseType;
            break;
          }
        }

      setSelectedDietType(dietType);
      setSelectedExerciseType(exerciseType);

      if (window.mixpanel)
        window.mixpanel.track("Program_Join_Now_Clicked", {
          challengeName: _challenge.name || _challenge.nutritionistName,
          challengeId: _challenge.id,
        });
      rudderAnalytics.track("User_Click_Join_Now", {
        challengeName: _challenge.name,
        challengeId: _challenge.id,
      });
      events.add({
        page: "checkout",
        challenge: {
          id: _challenge.id,
          name: _challenge.name || _challenge.nutritionistName,
        },
        ...(user && { user: { id: user.id, name: user.name } }),
        ...(src && { src: src }),
      });
    }

    fetchData();

    return () => {};
  }, []);

  useEffect(() => {
    if (!challenge) return;

    if (!location) return;

    var selectedChallenge = challenge;

    //if(!selectedChallenge) return window.location = '/'

    //default
    var amount = selectedChallenge.price_usd;
    var currency = "usd";

    if (location && location === "EG") {
      amount = selectedChallenge.price;
      currency = "egp";
    }

    //set bundle amounts after currency alternation, and before amount alternation
    /*setBundleAmount({

            1: Math.round(((100-bundles[1])/100)*1*amount * (discount? (1-discount) : 1)),
            2: Math.round(((100-bundles[2])/100)*2*amount),
            3: Math.round(((100-bundles[3])/100)*3*amount),
        })*/

    var amts = {};
    for (var key in bundles)
      amts[key] = Math.round(
        ((100 - bundles[key]) / 100) *
          key *
          amount *
          (+key === 1 && discount ? 1 - discount : 1)
      );
    setBundleAmount(amts);

    setOneToOneAmount({
      1: Math.round(
        ((100 - oneToOneDiscount[1]) / 100) *
          1 *
          amount *
          (discount ? 1 - discount : 1)
      ),
      2: Math.round(((100 - oneToOneDiscount[2]) / 100) * 2 * amount),
      3: Math.round(((100 - oneToOneDiscount[3]) / 100) * 3 * amount),
    });

    if (
      ((!challenge.isBundleable && !challenge.oneToOne) ||
        selectedRounds === 1 ||
        selectedMonths === 1) &&
      discount
    ) {
      setOrgAmount(amount);
      amount *= 1 - discount;
    }

    if (selectedRounds > 1 && selectedChallenge.isBundleable) {
      amount *= selectedRounds;
      amount *= (100 - bundles[selectedRounds]) / 100;
    }

    if (selectedMonths > 1 && selectedChallenge.oneToOne) {
      amount *= selectedMonths;
      amount *= (100 - oneToOneDiscount[selectedMonths]) / 100;
    }

    amount = Math.round(amount);

    setAmount(amount);
    setCurrency(currency);

    return () => {};
  }, [challenge, location, discount, selectedRounds, selectedMonths]);

  //let { challengeId } = useParams();

  useEffect(() => {
    const nutritionistsRef = user
      ? firestore("users")
          .doc(user.id)
          .onSnapshot((documentSnapshot) => {
            var _user = documentSnapshot.data();
            _user.id = documentSnapshot.id;
            setUser(_user);
            localStorage.setItem("userDetails", JSON.stringify(_user));
          })
      : () => {};

    return () => {
      console.log("Do some cleanup");
      nutritionistsRef();
    };
  }, []);

  function fbImage(imageLink) {
    if (!imageLink) return imageLink;
    var query = imageLink.split("asid=")[1];
    if (!query) return imageLink;
    var fbId = query.split("&")[0];
    if (!fbId) return imageLink;

    return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`;
  }

  async function logout() {
    localStorage.clear();
    await firebase.auth().signOut();
  }

  async function submitForm(e) {
    e.stopPropagation();
    e.preventDefault();

    setInvalidCode(false);
    var state = "noState";
    var clonedPromocode = code;
    if (code === user.userPromocode) {
      setInvalidCode(true);
      setErrorMessage("This promo code is for your new friends");
      return;
    }
    if (
      (promocodeEngineData.excludeNutritionistsActivated &&
        promocodeEngineData.excludedNutritionistsIds.includes(
          challenge.nutritionistId
        )) ||
      (promocodeEngineData.excludeProgramsActivated &&
        promocodeEngineData.excludedProgramsIds.includes(challenge.id))
    ) {
      setInvalidCode(true);
      setErrorMessage("Promocode not valid for this program");
      return;
    }
    if (code.includes("-")) {
      clonedPromocode = code.split("-")[0];
      if (clonedPromocode !== user.userPromocode) {
        setInvalidCode(true);
        setErrorMessage("You can't use this promocode");
        return;
      }
    }
    await firestore("promocodes")
      .where("code", "==", clonedPromocode)
      .get()
      .then(
        async (querySnapshot) => {
          if (querySnapshot.docs && querySnapshot.docs.length) {
            const data = querySnapshot.docs[0].data();
            data.key = querySnapshot.docs[0].id;
            let reusableCodeAndNotUsed = false;
            let friendCode = data.friends.find(
              (friendCode) => friendCode.reusablePromocode === code
            );
            if (
              code.includes("-") &&
              ((data.reusablePromocodeUsed &&
                !data.reusablePromocodeUsed.includes(code)) ||
                !data.reusablePromocodeUsed)
            ) {
              if (
                promocodeEngineData.promocodeExpiryActivate &&
                friendCode &&
                moment().isBefore(
                  moment(friendCode.timeUsed.toDate()).add(
                    promocodeEngineData.promocodeExpiryDuration,
                    "days"
                  )
                )
              ) {
                reusableCodeAndNotUsed = true;
              } else if (!promocodeEngineData.promocodeExpiryActivate) {
                reusableCodeAndNotUsed = true;
              }
            }
            if (
              (data.friends.length < promocodeEngineData.numberOfInvitees &&
                (!user.joinedChallengeIds || !user.joinedChallengeIds.length) &&
                (!user.joinedOneToOneIds || !user.joinedOneToOneIds.length)) ||
              reusableCodeAndNotUsed
            ) {
              state = "valid";
              setPromocodeId(data.key);
              //setDiscount((codeDocs.docs[0].data().discount)/100);
              var disc =
                code.includes("-") && friendCode.discount
                  ? friendCode.discount
                  : data.discount;
              setDiscount((100 - disc) / 100); //use this cuz of reverse error in db
              setApplied(true);
            } else {
              setInvalidCode(true);
              state = "error";
              if (
                !code.includes("-") &&
                ((user.joinedChallengeIds && user.joinedChallengeIds.length) ||
                  (user.joinedOneToOneIds && user.joinedOneToOneIds.length))
              ) {
                setErrorMessage(
                  "This promo code is exclusive for new users only"
                );
              } else if (
                code.includes("-") &&
                data.reusablePromocodeUsed &&
                data.reusablePromocodeUsed.includes(code)
              ) {
                setErrorMessage("This promocode has been used before");
              } else if (
                data.friends.length === promocodeEngineData.numberOfInvitees
              ) {
                setErrorMessage("This promocode has reached its limit");
              } else if (
                promocodeEngineData.promocodeExpiryActivate &&
                friendCode &&
                moment().isAfter(
                  moment(friendCode.timeUsed.toDate()).add(
                    promocodeEngineData.promocodeExpiryDuration,
                    "days"
                  )
                )
              ) {
                setErrorMessage("This promocode has expired");
              } else if (code.includes("-") && !friendCode) {
                setErrorMessage("Invalid promocode!");
              }
            }
          }
        },
        (error) => {
          console.error("error : ", error);
        }
      );
    if (state === "valid" || state === "error") {
      return;
    }
    var codeDocs = await firestore("promocodes")
      .where("code", "==", code.toLowerCase().trim())
      .where("nutritionist", "==", challenge.nutritionistId)
      .get();
    if (codeDocs.size) {
      setPromocodeId(codeDocs.docs[0].id);
      //setDiscount((codeDocs.docs[0].data().discount)/100);
      setDiscount((100 - codeDocs.docs[0].data().discount) / 100); //use this cuz of reverse error in db
      setApplied(true);
    } else {
      setErrorMessage("Invalid code!");
      setInvalidCode(true);
    }

    if (window.mixpanel)
      window.mixpanel.track("Have_Promocode_Entered", { promocode: code });
  }

  function success() {
    setSucceeded(true);
  }

  return (
    <>
      <div id="Header" className="">
        <Container fluid="md">
          <Row className="header justify-content-md-left">
            {/* <NavLink key={"/"} to={"/"} style={{ cursor: "pointer" }}> */}
            <div className="main">
              <img height="40" src={logoImg} />
            </div>
            {/* </NavLink> */}
            <div className="account">
              <NavLink
                key={"/profile"}
                to={"/profile"}
                style={{ cursor: "pointer" }}
              >
                <img
                  className="user-image"
                  style={{ cursor: "pointer" }}
                  height="90"
                  src={fbImage(user.image)}
                />
                <div
                  className="user-name"
                  style={{ color: "#464646", fontWeight: "bold" }}
                >
                  {user.name}
                </div>
              </NavLink>

              {/*<Button className="sign-out" size="sm" variant="primary" onClick={() => logout()}>Sign out</Button>*/}
            </div>
          </Row>
        </Container>
      </div>

      <div className="checkout">
        {!succeeded &&
          challenge &&
          (!user.joinedChallengeIds ||
            !user.joinedChallengeIds.includes(challenge.id)) &&
          (!user.joinedOneToOneIds ||
            !user.joinedOneToOneIds.includes(challenge.id) ||
            !(new Date(user.joinedOneToOne[challenge.id].to) > new Date())) && (
            <div className="challenge-title">
              {loader ? (
                <div className="loader text-center">
                  <Spinner animation="border" size={"sm"} role="status" />
                </div>
              ) : (
                challenge && (
                  <div>
                    <div className="challenge-name">
                      {challenge.name || (
                        <>
                          One to One follow up
                          <span
                            style={{
                              fontWeight: "normal",
                              fontSize: ".8em",
                              margin: "0 .3em",
                            }}
                          >
                            {" "}
                            with{" "}
                          </span>
                          {challenge.nutritionistName}
                        </>
                      )}
                    </div>{" "}
                    <span>
                      {/*<h5>Choose your program plan</h5>*/}

                      {dietTypes.filter(
                        (type) =>
                          challenge.dietTypes &&
                          challenge.dietTypes[type.key] === true
                      ).length > 0 && (
                        <h5 style={{ marginTop: "1em" }}>
                          Choose your meal plan
                        </h5>
                      )}

                      <div
                        style={{
                          width: "400px",
                          maxWidth: "100%",
                          margin: "0 auto",
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        {dietTypes.map(
                          (type) =>
                            challenge.dietTypes &&
                            challenge.dietTypes[type.key] === true && (
                              <div
                                className={
                                  "type-option" +
                                  (selectedDietType === type.key
                                    ? " selected"
                                    : "")
                                }
                                onClick={() => {
                                  setSelectedDietType(type.key);
                                }}
                                style={{}}
                              >
                                <div>
                                  <span
                                    style={{ margin: ".7em", display: "block" }}
                                  >
                                    <img height="30" src={type.icon} />
                                  </span>
                                  <span
                                    style={{
                                      margin: ".7em",
                                      display: "block",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {type.key}
                                  </span>
                                </div>
                              </div>
                            )
                        )}
                      </div>

                      {exerciseTypes.filter(
                        (type) =>
                          challenge.exerciseTypes &&
                          challenge.exerciseTypes[type.key] === true
                      ).length > 0 && (
                        <h5 style={{ marginTop: "2em" }}>
                          Choose your workout plan
                        </h5>
                      )}

                      <div
                        style={{
                          width: "400px",
                          maxWidth: "100%",
                          margin: "0 auto",
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        {exerciseTypes.map(
                          (type) =>
                            challenge.exerciseTypes &&
                            challenge.exerciseTypes[type.key] === true && (
                              <div
                                className={
                                  "type-option" +
                                  (selectedExerciseType === type.key
                                    ? " selected"
                                    : "")
                                }
                                onClick={() => {
                                  setSelectedExerciseType(type.key);
                                }}
                                style={{}}
                              >
                                <div>
                                  <span
                                    style={{ margin: ".7em", display: "block" }}
                                  >
                                    <img height="30" src={type.icon} />
                                  </span>
                                  <span
                                    style={{
                                      margin: ".7em",
                                      display: "block",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {type.key}
                                  </span>
                                </div>
                              </div>
                            )
                        )}
                      </div>

                      <h5 style={{ marginTop: "2em" }}>
                        {challenge.isFree
                          ? ""
                          : challenge.oneToOne || challenge.isBundleable
                          ? "Choose your payment plan"
                          : "Program fees"}
                      </h5>

                      {challenge.isFree &&
                      (!user.joinedChallengeIds ||
                        !user.joinedChallengeIds.includes(challenge.id)) &&
                      (!user.joinedOneToOneIds ||
                        !user.joinedOneToOneIds.includes(challenge.id) ||
                        !(
                          new Date(user.joinedOneToOne[challenge.id].to) >
                          new Date()
                        )) ? (
                        <span className="price"></span>
                      ) : (
                        (amount || amount === 0) &&
                        currency && (
                          <div>
                            {challenge.oneToOne ? (
                              <div
                                style={{
                                  width: "400px",
                                  maxWidth: "100%",
                                  margin: "0 auto",
                                  marginTop: "20px",
                                }}
                              >
                                {challenge.packages[3] && (
                                  <div
                                    className={
                                      "price-option" +
                                      (selectedMonths === 3 ? " selected" : "")
                                    }
                                    onClick={() => {
                                      setSelectedMonths(3);
                                    }}
                                  >
                                    <span className="price-option-rounds">
                                      3 months <div>({3 * 4} weeks)</div>
                                    </span>
                                    <span className="price-option-discount">
                                      {oneToOneDiscount[3]}% discount
                                    </span>
                                    <span className="price-option-price">
                                      {oneToOneAmount[3]}{" "}
                                      {currency.toUpperCase()}{" "}
                                      <div>
                                        {Math.ceil(oneToOneAmount[3] / (3 * 4))}{" "}
                                        {currency.toUpperCase()}/week
                                      </div>
                                    </span>
                                  </div>
                                )}
                                {challenge.packages[2] && (
                                  <div
                                    className={
                                      "price-option" +
                                      (selectedMonths === 2 ? " selected" : "")
                                    }
                                    onClick={() => {
                                      setSelectedMonths(2);
                                    }}
                                  >
                                    <span className="price-option-rounds">
                                      2 months <div>({2 * 4} weeks)</div>
                                    </span>
                                    <span className="price-option-discount">
                                      {oneToOneDiscount[2]}% discount
                                    </span>
                                    <span className="price-option-price">
                                      {oneToOneAmount[2]}{" "}
                                      {currency.toUpperCase()}{" "}
                                      <div>
                                        {Math.ceil(oneToOneAmount[2] / (2 * 4))}{" "}
                                        {currency.toUpperCase()}/week
                                      </div>
                                    </span>
                                  </div>
                                )}
                                {challenge.packages[1] && (
                                  <div
                                    className={
                                      "price-option" +
                                      (selectedMonths === 1 ? " selected" : "")
                                    }
                                    onClick={() => {
                                      setSelectedMonths(1);
                                    }}
                                  >
                                    <span className="price-option-rounds">
                                      1 month <div>({1 * 4} weeks)</div>
                                    </span>
                                    <span className="price-option-price">
                                      {orgAmount && (
                                        <span className="original">
                                          {orgAmount} {currency.toUpperCase()}
                                        </span>
                                      )}{" "}
                                      {oneToOneAmount[1]}{" "}
                                      {currency.toUpperCase()}{" "}
                                      <div>
                                        {Math.ceil(oneToOneAmount[1] / (1 * 4))}{" "}
                                        {currency.toUpperCase()}/week
                                      </div>
                                    </span>
                                  </div>
                                )}
                              </div>
                            ) : challenge.isBundleable ? (
                              <div
                                style={{
                                  width: "400px",
                                  maxWidth: "100%",
                                  margin: "0 auto",
                                  marginTop: "20px",
                                }}
                              >
                                {Object.keys(bundles)
                                  .reverse()
                                  .map((key) => (
                                    <div
                                      className={
                                        "price-option" +
                                        (selectedRounds === +key
                                          ? " selected"
                                          : "")
                                      }
                                      onClick={() => {
                                        setSelectedRounds(+key);
                                      }}
                                    >
                                      <span className="price-option-rounds">
                                        {(key + " months")
                                          .replace("1 months", "1 month")
                                          .replace("12 months", "1 year")}{" "}
                                        <div>
                                          ({key * challenge.weeks} weeks)
                                        </div>
                                      </span>
                                      {+key !== 1 && (
                                        <span className="price-option-discount">
                                          {bundles[key]}% discount
                                        </span>
                                      )}
                                      <span className="price-option-price">
                                        {+key === 1 && orgAmount && (
                                          <span className="original">
                                            {orgAmount} {currency.toUpperCase()}
                                          </span>
                                        )}
                                        {bundleAmount[key]}{" "}
                                        {currency.toUpperCase()}{" "}
                                        <div>
                                          {Math.ceil(
                                            bundleAmount[key] /
                                              (key * challenge.weeks)
                                          )}{" "}
                                          {currency.toUpperCase()}/week
                                        </div>
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            ) : (
                              <span className="price">
                                {currency === "usd" && "$"}
                                {orgAmount && (
                                  <span className="original">
                                    {orgAmount} {currency.toUpperCase()}
                                  </span>
                                )}{" "}
                                {amount} {currency.toUpperCase()}
                              </span>
                            )}
                          </div>
                        )
                      )}
                    </span>
                    {!challenge.isFree &&
                      (!user.joinedChallengeIds ||
                        !user.joinedChallengeIds.includes(challenge.id)) &&
                      (!user.joinedOneToOneIds ||
                        !user.joinedOneToOneIds.includes(challenge.id) ||
                        !(
                          new Date(user.joinedOneToOne[challenge.id].to) >
                          new Date()
                        )) && (
                        <span
                          className={
                            "promocode" +
                            (challenge.oneToOne || challenge.isBundleable
                              ? ""
                              : " promocode-single")
                          }
                        >
                          {!promocode && (
                            <div className={"promocode-div"}>
                              <span>
                                {applied
                                  ? "Promo code applied."
                                  : "Do you have a promo code?"}
                                <div>
                                  {challenge.oneToOne && (
                                    <div className={"promocode-note"}>
                                      *Apply only on 1 month payment
                                    </div>
                                  )}
                                  {challenge.isBundleable && (
                                    <div className={"promocode-note"}>
                                      *Apply only on 1 round payment
                                    </div>
                                  )}
                                </div>
                              </span>
                              <span
                                className="add-promocode"
                                onClick={() => {
                                  setPromocode(true);
                                  if (challenge.isBundleable)
                                    setSelectedRounds(1);
                                  if (challenge.oneToOne) setSelectedMonths(1);
                                  if (window.mixpanel)
                                    window.mixpanel.track(
                                      "Have_Promocode_Clicked"
                                    );
                                }}
                              >
                                Add promo code
                              </span>
                            </div>
                          )}
                          {promocode && (
                            <div style={{ display: "block" }}>
                              <Form
                                className="form"
                                onSubmit={(e) => {
                                  submitForm(e);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  size="1x"
                                  onClick={() => {
                                    setPromocode(false);
                                  }}
                                  className="close-promo"
                                />
                                <label>Promo code</label>
                                <div style={{ display: "flex" }}>
                                  <Form.Control
                                    required
                                    defaultValue={code}
                                    onChange={(e) => {
                                      setCode(e.target.value);
                                    }}
                                  ></Form.Control>
                                  {applied ? (
                                    <Button
                                      variant="primary"
                                      type="submit"
                                      className="submit-button applied"
                                    >
                                      Applied!
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="primary"
                                      type="submit"
                                      className="submit-button"
                                    >
                                      Apply
                                    </Button>
                                  )}
                                </div>
                                {applied && (
                                  <div className="response applied">
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      size="1x"
                                    />
                                    Promo code Applied!
                                  </div>
                                )}
                                {invalidCode && (
                                  <div className="response invalid">
                                    <FontAwesomeIcon
                                      icon={faExclamationCircle}
                                      size="1x"
                                    />
                                    {errorMessage}
                                  </div>
                                )}
                              </Form>
                            </div>
                          )}
                        </span>
                      )}
                  </div>
                )
              )}
            </div>
          )}
        {challenge && (amount || amount === 0) && currency && (
          <Elements stripe={stripePromise}>
            <CheckoutForm
              amount={amount}
              currency={currency}
              challenge={challenge}
              isFree={challenge.isFree || amount === 0}
              promocode={promocodeId}
              bundle={challenge.isBundleable}
              rounds={selectedRounds}
              oneToOne={challenge.oneToOne}
              months={selectedMonths}
              success={success}
              dietType={selectedDietType}
              exerciseType={selectedExerciseType}
              code={code}
            />
          </Elements>
        )}

        {!succeeded && challenge && (
          <div className="huwai-note">
            <FontAwesomeIcon
              icon={faExclamationCircle}
              size="1x"
              className="info-icon"
            />
            Some Huawei devices are not supported, please make sure <br /> to
            download the app on your mobile first before paying
          </div>
        )}
      </div>
    </>
  );
}
