import * as firebase from "firebase/app";
import "firebase/firestore";
import 'firebase/analytics';
import "firebase/auth";

function getKey(key){

  return process.env['REACT_APP_' + key]
}

var firebaseConfig = {
  apiKey: getKey('apiKey'),
  authDomain: getKey('authDomain'),
  databaseURL: getKey('databaseURL'),
  projectId: getKey('projectId'),
  storageBucket: getKey('storageBucket'),
  messagingSenderId: getKey('messagingSenderId'),
  appId: getKey('appId'),
  measurementId: getKey('measurementId')
};

firebase.initializeApp(firebaseConfig);
firebase.analytics()

export const auth = () => {
    return firebase.auth();
};

export const firestore = (collection) => {
    return firebase.firestore().collection(collection);
};

export const storage = () => {
    return firebase.storage();
};