
import React, {useEffect, useState} from 'react';
import ReactPlayer from 'react-player'
import { useParams } from "react-router-dom";
import firebase from "firebase";
import moment from 'moment/min/moment-with-locales';
import { Button, Spinner, Container, Row, Col, Tabs, Tab, Form } from "react-bootstrap";
import { firestore, storage } from "../services/firebase";
import Masonry from 'react-masonry-css';
import * as _ from "lodash";
import StarRatings from 'react-star-ratings';
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { NavLink, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes, faCheckCircle, faExclamationCircle, faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import { events } from "../components/events";


import logoImg from '../assets/images/Logo.png';
import logoDark from '../assets/images/Logo_.png';
import axios from 'axios';

import logoArImg from '../assets/box/LOGO.svg';
import logoArImgWhite from '../assets/box/LOGO-white.svg';
import ketoImg from '../assets/box/Keto Box_V1_525x525.png';
import standardImg from '../assets/box/Standard Box_V1_525 X 525.png';



import arrowLeft from '../assets/box/arrow-left.svg';

import Img_social_1 from '../assets/box/social-facebook.svg';
import Img_social_2 from '../assets/box/social-instagram.svg';
import Img_social_3 from '../assets/box/social-youtube.svg';


import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutBoxForm";
import './Checkout.scss';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// Setup Stripe.js and the Elements provider
const stripePromise = loadStripe('pk_live_olYCygAG79BGQpAn9lrdRWBN00DZZ2Eb37');



export default function Checkout(props) {

    let { item, id } = useParams();

    const [bundleDiscount, setBundleDiscount] = useState({
        1: 0,
        2: 20,
        3: 27
    });
    const [oneToOneDiscount, setOneToOneDiscount] = useState({
        1: 0,
        2: 20,
        3: 27
    });
    const [bundleAmount, setBundleAmount] = useState({});
    const [oneToOneAmount, setOneToOneAmount] = useState({});
    const [selectedRounds, setSelectedRounds] = useState(3);
    const [selectedMonths, setSelectedMonths] = useState(3);
    const [orgAmount, setOrgAmount] = useState();
    const [amount, setAmount] = useState();
    const [currency, setCurrency] = useState('egp');
    const [loader, setLoader] = useState(false);
    const [code, setCode] = useState('');
    const [applied, setApplied] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [invalidCode, setInvalidCode] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('userDetails')));
    const [src, setSrc] = useState(localStorage.getItem('src'));
    const [challenge, setChallenge] = useState();
    const [location, setLocation] = useState();
    const [succeeded, setSucceeded] = useState(false);

    const [selectedDietType, setSelectedDietType] = useState();
    const [selectedExerciseType, setSelectedExerciseType] = useState();
    const [selectedBoxType, setSelectedBoxType] = useState(localStorage.getItem('selectedBoxType'));
    const [selectedBoxes, setSelectedBoxes] = useState(+localStorage.getItem('selectedBoxes'));
    const [contact, setContact] = useState(JSON.parse(localStorage.getItem('contactDetails')));
    const [choices, setChoices] = useState(JSON.parse(localStorage.getItem('choices')));
    const [promocodeId, setPromocodeId] = useState(localStorage.getItem('promocodeId'));


    useEffect(() => {

        if(!contact) return window.location = '/box/type';
        if(!selectedBoxType) return window.location = '/box/type';
        if(!selectedBoxes) return window.location = '/box/quantity';


        firestore('users').doc(user.id).update({contact: contact});

        return () => {

            console.log('Do some cleanup');
        }

    }, []);

    useEffect(() => {

        setAmount(choices[selectedBoxType].filter(choice => choice.boxes == selectedBoxes)[0].price)

        return () => {

        }

    }, [selectedBoxes, selectedBoxType]);

    useEffect(() => {

        getContact();

        document.body.style.background = "white";

        events.add({page: 'checkout', box: true, ...(user) && {user: {id: user.id, name: user.name}}, ...src && {src: src}})

        return () => {

            console.log('Do some cleanup');
        }

    }, []);



    async function getContact() {

        const userSnapshot = await firestore('users').doc(user.id).get();
        console.log(userSnapshot.data())
        setContact(userSnapshot.data().contact || {})
    }


    function fbImage(imageLink){

        if(!imageLink) return imageLink
        var query = imageLink.split('asid=')[1]
        if(!query) return imageLink
        var fbId = query.split('&')[0]
        if(!fbId) return imageLink
            
        return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`
    }

    async function logout() {
        localStorage.clear();
        await firebase.auth().signOut();
        window.location.replace('/box');
    }

    async function submitForm(e) {
        e.stopPropagation();
        e.preventDefault();

        setInvalidCode(false)
        var codeDocs = await firestore('promocodes').where('code', '==', code.toLowerCase().trim()).get()
        if(codeDocs.size) {
            setPromocodeId(codeDocs.docs[0].id);
            //setDiscount((codeDocs.docs[0].data().discount)/100); 
            setDiscount((100-codeDocs.docs[0].data().discount)/100);    //use this cuz of reverse error in db
            setApplied(true)
        }
        else{setInvalidCode(true)}
    }

    function success() {
        
        setSucceeded(true)
    }

    var toArabicDigits= function(digits){

        return digits.toString().replace(/\d/g, d => String.fromCharCode('0x066'+d))
    }

  return (<>

        <div className="checkout-box">

            <div className="resize" style={{padding: '2% 14% 0 14%', textAlign: 'left', background: '#fff'}}>
                <NavLink key={'/box/quantity'} to={'/box/quantity'}>
                    <img src={arrowLeft} style={{height: '20px'}}/>
                </NavLink>
            </div>

            {/*<div className="resize" id="header" style={{background: '#fff', padding: '1.2em 14% 1.2em 14%', direction: 'rtl', textAlign: 'right'}}>

                <NavLink key={'/'} to={'/'} style={{cursor:'pointer', marginLeft: '3em'}}>
                    <span className="logo"><img height="90" style={{maxWidth: '30%'}} src={logoArImg}/></span>
                </NavLink>

            </div>*/}

            <div className="resize" style={{background: '#fff', color: '#0E5070', textAlign: 'right', position: 'relative', background: '#fff', paddingTop: '100px'}}>

                <div style={{textAlign: 'center'}}>

                    {!succeeded && <h1 style={{fontWeight: 'bold', lineHeight: '1.5', marginBottom: '70px'}}>٤ - إدفع</h1>}

                    {!succeeded && <div className="payment-info">
                        <div style={{border: '1px solid #0E5070', borderRadius: '10px', direction: 'rtl', padding: '15px'}}>
                            <div style={{display: 'flex', justifyContent: 'space-between', margin: '15px 0'}}>
                                <div>{selectedBoxes} ويلنس بوكس ({{Standard: 'عادي', Keto: 'كيتو'}[selectedBoxType]}) <span style={{marginRight: '7px'}}>{choices[selectedBoxType].filter(choice => choice.boxes === selectedBoxes)[0].meals} وجبة</span></div>
                                <span>{amount} جنيه</span>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'space-between', margin: '15px 0'}}>
                                رسوم التوصيل
                                <span>{selectedBoxes * 30} جنيه</span>
                            </div>

                            <div style={{width: '100%', borderBottom: '1px solid #0E5070', margin: '40px 0 25px 0'}}></div>

                            <div style={{display: 'flex', justifyContent: 'space-between', margin: '15px 0', fontWeight: 'bold'}}>
                                الإجمالي
                                <span>{amount+(user.email === 'mariam25@gmail.com'? 0 : selectedBoxes * 30)} جنيه</span>
                            </div>
                        </div>
                    </div>}

                    {/*not has it already &&*/ <Elements stripe={stripePromise}>
                        <CheckoutForm amount={amount + (user.email === 'mariam25@gmail.com'? 0 : 30)} currency={currency} promocode={promocodeId} boxes={selectedBoxes} success={success} boxType={selectedBoxType}/>
                    </Elements>}

                    <div style={{height: '5em'}}><br/></div>
                </div>
         
            </div>

            {/*<div className="resize" style={{background: '#0E5070', padding: '1.2em 14% 1.2em 14%', direction: 'rtl', textAlign: 'right', height: '100px'}}>

                <div className="resize-block" style={{float: 'left', marginTop: '0px'}}>
                    <span className="logo"><img height="60" src={logoArImgWhite}/></span>
                </div>

                <div className="resize-block" style={{float: 'right', marginTop: '20px'}}>
                    <span style={{margin: '.5em', cursor: 'pointer'}} onClick={()=>{window.open('https://www.facebook.com/welnesapp')}}><img src={Img_social_1}/></span>
                    <span style={{margin: '.5em', cursor: 'pointer'}} onClick={()=>{window.open('https://www.instagram.com/welnes.app')}}><img src={Img_social_2}/></span>
                </div>
            </div>*/}

        </div>   
  </>);
}