import React, { useState, useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";

import { Login } from "./pages/login/Login";
import { AppPage } from "./pages/AppPage";
import Subscribe from "./pages/Subscribe";
import Home from "./pages/Home";
import Feed from "./pages/Feed";
import Alpha from "./pages/Alpha";
import Start from "./pages/Start";
import Box from "./pages/Box";
import Download from "./pages/Download";
import Policy from "./pages/Policy";
import Policy_gorilla from "./pages/Policy_gorilla";
import Programs from "./pages/Programs";
import Challenge from "./pages/Challenge";
import Checkout from "./pages/Checkout";
import CheckoutBox from "./pages/CheckoutBox";
import Profile from "./pages/Profile";
import Coach from "./pages/Coach";
//import NotFound from "./pages/NotFound";
import PageLoading from "./components/PageLoading";
import { firestore, auth } from "./services/firebase";
import firebase from "firebase";
import * as _ from "lodash";
import moment from "moment";
import axios from "axios";
import "./App.scss";

import Support from "./components/Support";
import { createBrowserHistory } from "history";
import { rudderAnalytics } from "./services/trackers";
import { TrackPage } from "./services/trackers";

const history = createBrowserHistory();

var stopAuthListening = false;

export function App() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [kycFilled, setKycFilled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    var unsub = auth().onAuthStateChanged((user) => {
      if (!user) {
        setKycFilled(false);
        setIsSignedIn(false);
        setIsLoading(false);
        return;
      }

      if (stopAuthListening) return;
      stopAuthListening = true;

      if (user && user.uid) {
        firestore("users")
          .where("uid", "==", user.uid)
          .limit(1)
          .get()
          .then((snapshot) => {
            if (snapshot && snapshot.docs && snapshot.docs.length) {
              const userDoc = snapshot.docs[0];
              var userDetails = userDoc.data();
              userDetails.id = userDoc.id;
              userDetails.uid = user.uid;

              if (userDetails.joinedOneToOne)
                for (var id in userDetails.joinedOneToOne) {
                  userDetails.joinedOneToOne[id].from =
                    userDetails.joinedOneToOne[id].from.toDate();
                  userDetails.joinedOneToOne[id].to =
                    userDetails.joinedOneToOne[id].to.toDate();
                }

              localStorage.setItem("userDetails", JSON.stringify(userDetails));

              if (window.mixpanel) window.mixpanel.identify(userDetails.id);

              setKycFilled(
                true /*userDetails.birthday && userDetails.gender && userDetails.height && userDetails.weight && userDetails.targetWeight*/
              );
              rudderstackIdentifyUser(userDetails);
              setIsSignedIn(true);
              setIsLoading(false);

              //if(user.displayName === 'Asaleya Amr') setKycFilled(userDetails.kycFilled)
            } else {
              //new user will be created at "/login"

              var userObject = {
                id: user.uid.substring(0, 20),
                name: user.displayName,
                image: user.photoURL
                  ? user.photoURL + "?type=large&width=500&height=500"
                  : "",
                email: user.email,
                uid: user.uid,
                type:
                  user.providerData && user.providerData.length
                    ? user.providerData[0].providerId
                    : "",
                pre: true,
              };
              /*
                        setNewUser(userObject);
                        setUID(user.uid)
                        */
              localStorage.setItem("userDetails", JSON.stringify(userObject));
              rudderstackIdentifyUser(userObject);
              setKycFilled(true /*false*/);
              setIsSignedIn(true);
              setIsLoading(false);
            }

            /*
                    var email = prompt("Please enter your new email");
                    var password = prompt("Please enter your password");
                    var cred = firebase.auth.EmailAuthProvider.credential(user.email, password);
                    user.reauthenticateWithCredential(cred).then(function(){

                        user.updateEmail(email).then(function(){
                        
                            alert('email changed')

                            //change "email" in firestore user document
                            firestore("users").doc(userDetails.id).update({email: email})
                        })

                    }).catch(function(){

                        alert('wrong password!')
                    })
                    */
          });
      }

      //setIsSignedIn(!!user);
      //setIsLoading(false);
      //stopAuthListening = false
    });
    return () => {
      unsub();
    };
  }, []);

  function rudderstackIdentifyUser(userDetails) {
    const traitData = {};
    if (userDetails.name) {
      traitData.name = userDetails.name;
    }
    if (userDetails.birthday) {
      traitData.birthday = userDetails.birthday;
      traitData.age = moment().diff(userDetails.birthday, "years");
    }
    if (userDetails.gender) {
      traitData.gender = userDetails.gender;
    }
    if (userDetails.email) {
      traitData.email = userDetails.email;
    }
    if (userDetails.country) {
      traitData.country = userDetails.country;
    }
    if (userDetails.targetWeight && !isNaN(Number(userDetails.targetWeight))) {
        traitData.targetWeight = Number(userDetails.targetWeight);
    }

    if (userDetails.height && !isNaN(Number(userDetails.height))) {
        traitData.height = Number(userDetails.height);
    }

    if (userDetails.weight && !isNaN(Number(userDetails.weight))) {
        traitData.currentWeight = Number(userDetails.weight);
    }
    if (userDetails.goal) {
      traitData.goal = userDetails.goal;
    }
    if (userDetails.lastOpened) {
      traitData.appLastOpened = userDetails.lastOpened;
    }
    if (userDetails.healthInfo && userDetails.healthInfo.exerciseType) {
      traitData.exerciseLocation =
        userDetails.healthInfo && userDetails.healthInfo.exerciseType;
    }

    if (userDetails.healthInfo && userDetails.healthInfo.dietType) {
      traitData.dietType =
        userDetails.healthInfo && userDetails.healthInfo.dietType;
    }
    if (userDetails.created) {
        traitData.joinedDate = userDetails.created.toDate();
    }
    traitData.role =
      (userDetails.role &&
        ((userDetails.role.includes("admin") && "admin") ||
          (userDetails.role.includes("nutritionist") && "nutritionist") ||
          (userDetails.role.includes("collaborator") && "collaborator") ||
          (userDetails.role.includes("assistance") && "assistant") ||
          "user")) ||
      "user";
    rudderAnalytics.identify(userDetails.uid, traitData);
  }

  return (
    <div className="" id="wrapper">
      {isLoading ? (
        <PageLoading />
      ) : (
        <Router>
          <TrackPage />
          <Switch>
            <Route path="/subscribe">
              <Subscribe />
            </Route>
            <Route path="/alpha">
              <Alpha />
            </Route>
            <Route path="/start">
              <Start />
            </Route>
            <Route path="/feed">
              <Home ar />
            </Route>
            <Route path="/box/:wizard">
              <Box />
            </Route>
            <Route path="/box">
              <Box />
            </Route>
            <Route path="/register">
              {kycFilled ? <Start /> : isSignedIn ? <Profile /> : <Login />}
            </Route>
            <Route path="/app">
              <AppPage />
            </Route>
            {/*<Route path="/404"><NotFound/></Route>*/}
            <Route path="/login">{isSignedIn ? <Feed /> : <Login />}</Route>
            <Route path="/policy">
              <Policy />
            </Route>
            <Route path="/download">
              <Download />
            </Route>
            <Route path="/gorillafit/policy">
              <Policy_gorilla />
            </Route>
            <Route path="/profile">
              {isSignedIn ? <Profile /> : <Redirect to="/login" />}
            </Route>
            {/*<Route path="/checkout/box">{isSignedIn ?<CheckoutBox/> : <Login/>}</Route>*/}
            <Route path="/checkout/:item/:id/:etisalat">
              {isSignedIn ? <Checkout /> : <Login />}
            </Route>
            {/*<Route path="/checkout/:item/:id">{isSignedIn ?<Checkout/> : <Login/>}</Route>*/}
            <Route path="/checkout/:item/:id">
              {isSignedIn ? kycFilled ? <Checkout /> : <Profile /> : <Login />}
            </Route>
            <Route path="/challenge/:challengeId">
              <Challenge />
            </Route>
            <Route path="/energize">
              <Redirect to="/Heba.Shendy/ENERGIZE" />
            </Route>
            <Route path="/The.Slim.Game">
              <Redirect to="/Kareem.Gamal" />
            </Route>
            <Route path="/Valeo_Challenge">
              <Redirect to="/Nour.AlJakoush/Immunity.boost.&.weight.loss.challenge" />
            </Route>
            <Route path="/Talabat_Challenge">
              <Redirect to="/Marwa.El-Tawil/Talabat.Weight.Loss.Challenge" />
            </Route>
            <Route path="/i.fit">
              <Redirect to="/Nourhan.Mohssen" />
            </Route>
            <Route path="/airliquide">
              <Redirect to="/challenge/6nlKfjJiE2t7iaw6znv8" />
            </Route>
            <Route path="/etisalat">
              <Redirect to="/challenge/FoyrBF7757cKy2HGD8vl" />
            </Route>
            <Route path="/EtisalatChallengeR2">
              <Redirect to="/challenge/aVzVCgoum3OZuyABjIXV" />
            </Route>
            <Route path="/IGNITE">
              <Redirect to="/challenge/xO2K0g7b4JNswjWLm5IL" />
            </Route>
            <Route path="/LiteBite">
              <Redirect to="/challenge/UEzV53x0DwK6nokvxuCo" />
            </Route>
            <Route path="/P&G">
              <Redirect to="/Heba.Shendy/P&G.Weight.Loss.Challenge" />
            </Route>
            <Route path="/teleperformance">
              <Redirect to="/Heba.Shendy/Teleperformance.Weight.Loss.Challenge" />
            </Route>
            <Route path="/TeamEzz">
              <Redirect to="/Team_Ezz" />
            </Route>
            <Route path="/challenges">
              <Challenge />
            </Route>
            <Route path="/programs">
              <Programs />
            </Route>
            <Route path="/:nutritionistName/follow">
              {kycFilled ? <Challenge /> : isSignedIn ? <Profile /> : <Login />}
            </Route>
            <Route path="/:nutritionistName/:challengeName">
              <Challenge />
            </Route>
            <Route path="/:nutritionistName">
              <Challenge />
            </Route>
            <Route>
              <Feed />
            </Route>
          </Switch>
        </Router>
      )}

      {isSignedIn &&
        history.location.pathname !== "/start" &&
        history.location.pathname !== "/register" &&
        !history.location.pathname.includes("/box") && <Support />}
      {isSignedIn && history.location.pathname === "/checkout/box" && (
        <Support />
      )}
    </div>
  );
}

export default App;
