import React, { useEffect, useState } from "react";
import * as firebase from "firebase";
import { events } from "../../components/events";
import FirebaseAuth from "react-firebaseui/FirebaseAuth";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { firestore, storage } from "../../services/firebase";
import {
  Button,
  Spinner,
  Container,
  Row,
  Col,
  Tabs,
  Tab,
  Form,
} from "react-bootstrap";
import {
  NavLink,
  useHistory,
  Redirect,
  useLocation,
  useParams,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import logoArImg from "../../assets/box/LOGO.svg";
import logoArImgWhite from "../../assets/box/LOGO-white.svg";
import moment from "moment";
import axios from "axios";
import "./Login.scss";

/* images */
import logoImg from "../../assets/images/Logo.png";
import facebook from "../../assets/images/fb.png";

import Img_social_1 from "../../assets/box/social-facebook.svg";
import Img_social_2 from "../../assets/box/social-instagram.svg";
import Img_social_3 from "../../assets/box/social-youtube.svg";
import { ClearAll, rudderAnalytics } from "../../services/trackers";

const history = createBrowserHistory();

export function Login(props) {
  let { item, id, etisalat } = useParams();

  const [box, setBox] = useState(false);

  useEffect(() => {
    if (history.location.pathname == "/checkout/box") {
      setBox(true);
      document.body.style.background = "white";
    }

    events.add({ page: "login" });

    return () => {
      console.log("Do some cleanup");
    };
  }, []);

  async function logout() {
    localStorage.clear();
    rudderAnalytics.track("User_Sign_Out");
    await firebase.auth().signOut();
    window.location.reload();
    ClearAll();
  }

  async function createUser(user, cb) {
    var userObject = {
      id: user.uid.substring(0, 20),
      created: firebase.firestore.FieldValue.serverTimestamp(),
      datetime: moment.utc().format(),
      name: user.displayName,
      image: user.photoURL
        ? user.photoURL + "?type=large&width=500&height=500"
        : "",
      email: user.email,
      uid: user.uid,
      type:
        user.providerData && user.providerData.length
          ? user.providerData[0].providerId
          : "",
      web: true,
    };

    try {
      const resp = await axios.get("https://ipapi.co/json/");
      userObject.country = resp.data.country_code;
    } catch (err) {
      userObject.country = "N/A";
      console.error("get location error", err);
    }

    firestore("users")
      .doc(userObject.id)
      .set(userObject)
      .then((userRef) => {
        //firestore('users').add(userObject).then(userRef => {

        //userObject.id = userRef.id;

        localStorage.setItem("userDetails", JSON.stringify(userObject));

        if (window.mixpanel) window.mixpanel.identify(userObject.id);
        rudderAnalytics.identify(userObject.uid);
        //if(window.mixpanel) window.mixpanel.track("signup", {user: userObject.name});

        cb(userObject);

        //window.location.reload();     //don't reload to avoid calling seChallengeId use effect in checkoutForm
      });
  }

  return (
    <>
      {box && (
        <div
          className="resize"
          id="header"
          style={{
            background: "#fff",
            padding: "1.2em 14% 1.2em 14%",
            direction: "rtl",
            textAlign: "right",
          }}
        >
          <NavLink
            key={"/"}
            to={"/"}
            style={{ cursor: "pointer", marginLeft: "3em" }}
          >
            <span className="logo">
              <img height="90" style={{ maxWidth: "30%" }} src={logoArImg} />
            </span>
          </NavLink>

          {/*user && <div style={{cursor:'pointer', float: 'left', marginTop: '25px'}}><Button variant="dark" style={{background: '#0E5070', padding: '10px 20px'}} onClick={logout}>تسجيل الخروج</Button></div>*/}

          {/*<NavLink key={'/challenges'} to={'/challenges'} style={{cursor:'pointer', color: '#1E516F', margin: '0 .5em', verticalAlign: 'bottom', fontSize: '14px', fontWeight: 'bold', opacity: .9}}>Explore challenges</NavLink>*/}
          {/*<NavLink key={'/activity'} to={'/activity'} style={{cursor:'pointer', color: '#1E516F', margin: '0 .5em', verticalAlign: 'bottom', fontSize: '14px', fontWeight: 'bold', opacity: .9}}>Activity</NavLink>*/}
        </div>
      )}

      <div id="Login" style={box ? { height: "auto" } : {}}>
        <div className="welcome">
          {!box && <div className="shade"></div>}
          <div
            className="join"
            style={{ paddingTop: "10vh", position: "absolute" }}
          >
            <span className="logo">
              <img height="40" src={logoImg} />
            </span>

            <span
              className="note welnes-note"
              style={{
                display: "block",
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "bold",
                padding: "2em 0 3em",
              }}
            >
              Register at Welnes to <br />
              <span style={{ fontSize: ".85em" }}>
                join the program and login to the app
              </span>
            </span>

            {box && (
              <div
                className="resize"
                style={{
                  background: "#fff",
                  color: "#0E5070",
                  direction: "rtl",
                  textAlign: "right",
                  position: "relative",
                  background: "#fff",
                  paddingBottom: "20px",
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <h1
                    style={{
                      fontWeight: "bold",
                      lineHeight: "1.5",
                      marginBottom: "70px",
                    }}
                  >
                    ٤ - تسجيل الدخول
                  </h1>
                </div>
              </div>
            )}

            {/*<img className="facebook" height="60" src={facebook}/> to join the challenge*/}

            <FirebaseAuth
              firebaseAuth={firebase.auth()}
              uiConfig={{
                signInFlow: "popup", //redirect is not working on ios 16.x
                signInOptions: etisalat
                  ? [firebase.auth.EmailAuthProvider.PROVIDER_ID]
                  : [
                      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                      "apple.com",
                      firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    ],
                callbacks: {
                  //signInSuccess: () => false,
                  signInSuccessWithAuthResult: (authResult) => {
                    if (
                      etisalat &&
                      !authResult.user.email.toLowerCase().includes("etisalat")
                    ) {
                      logout();
                      alert("user is not allowed to join the challenge");
                      return false;
                    }

                    if (authResult.user && authResult.user.uid) {
                      const user = authResult.user;

                      firestore("users")
                        .where("uid", "==", user.uid)
                        .limit(1)
                        .get()
                        .then((snapshot) => {
                          if (
                            snapshot &&
                            snapshot.docs &&
                            snapshot.docs.length
                          ) {
                            const userDoc = snapshot.docs[0];
                            var userDetails = userDoc.data();

                            //if(props.callback) props.callback(userDetails);

                            /*if(redirect === 'checkout'){

                                                    if(userDetails.gender && userDetails.birthday && userDetails.height && userDetails.weight){

                                                        window.location = '/checkout';
                                                    }
                                                    else{

                                                        window.location = '/profile';
                                                    }
                                                }
                                                else if(redirect){  //back to nutritionist page

                                                    if(userDetails.gender && userDetails.birthday && userDetails.height && userDetails.weight){

                                                        window.location = '/'+redirect;
                                                    }
                                                    else{
                                                        
                                                        window.location = '/profile/'+redirect;
                                                    }
                                                }
                                                else{

                                                    window.location = '/profile';
                                                }*/

                            if (window.mixpanel)
                              window.mixpanel.track("Login", {
                                user: userDetails.name,
                              });
                            let provider =
                              user.providerData && user.providerData.length
                                ? user.providerData[0].providerId
                                : "";
                            rudderAnalytics.track("User_Sign_In", {
                              provider: provider,
                            });
                            events.add({
                              page: "signin",
                              ...(userDetails && {
                                user: {
                                  id: userDetails.id,
                                  name: userDetails.name,
                                  type: userDetails.type || "",
                                },
                              }),
                            });
                          } else {
                            //new user

                            createUser(user, (userDetails) => {
                              //window.location = '/profile';

                              if (window.mixpanel)
                                window.mixpanel.track("SignUp", {
                                  user: userDetails.name,
                                });
                              let provider =
                                user.providerData && user.providerData.length
                                  ? user.providerData[0].providerId
                                  : "";
                              rudderAnalytics.track("User_Sign_Up", {
                                provider: provider,
                              });
                              events.add({
                                page: "signup",
                                ...(userDetails && {
                                  user: {
                                    id: userDetails.id,
                                    name: userDetails.name,
                                    type: userDetails.type || "",
                                  },
                                }),
                              });
                            });
                          }
                        });
                    } else {
                      //window.location = '/profile';
                    }

                    // Do not automatically redirect.
                    //return false;
                  },
                  //signInSuccessUrl: '/checkout',
                },
              }}
            />

            {/*<div>to join the challenge</div>*/}
            {/*<div>to proceed with payment</div>*/}
          </div>
        </div>

        {/*<div className="background-health-container">
                <div className="background-health-shade">

                </div>
            </div>*/}
      </div>

      {/*box && <div className="resize" style={{background: '#0E5070', padding: '1.2em 14% 1.2em 14%', direction: 'rtl', textAlign: 'right', height: '250px', position: 'fixed', bottom: 0, left: 0, right: 0}}>

                <div className="resize-block" style={{float: 'left', marginTop: '50px'}}>
                    <span className="logo"><img height="90" src={logoArImgWhite}/></span>
                </div>

                <div className="resize-block" style={{float: 'right', marginTop: '80px'}}>
                    <span style={{margin: '.5em', cursor: 'pointer'}} onClick={()=>{window.open('https://www.facebook.com/welnesapp')}}><img src={Img_social_1}/></span>
                    <span style={{margin: '.5em', cursor: 'pointer'}} onClick={()=>{window.open('https://www.instagram.com/welnes.app')}}><img src={Img_social_2}/></span>
                </div>
            </div>*/}
    </>
  );
}
