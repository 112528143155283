import React, { useEffect, useState } from "react";
import { RudderAnalytics } from "@rudderstack/analytics-js/bundled";
import posthog from "posthog-js";
import { useParams, useLocation } from "react-router-dom";
import queryString from "query-string";

let rudderAnalytics = new RudderAnalytics();

const InitRudderstack = (callback) => {
  // if (process.env.NODE_ENV === 'production') {
  rudderAnalytics.load(
    process.env.REACT_APP_RUDDERSTACK_WRITE_KEY,
    process.env.REACT_APP_RUDDERSTACK_DATAPLANE_URL
  );

  rudderAnalytics.ready(() => {
    console.log("rs ready");
  });

  rudderAnalytics.ready(callback);
  // }
};

const InitPosthog = () => {
  //   if (
  //     process.env.NODE_ENV === "production"
  //   ) {
  posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_POSTHOG_HOST,
    // capture_pageview: false,
    cross_subdomain_cookie: false,
    loaded: function (posthog) {
      rudderAnalytics.setAnonymousId(posthog.get_distinct_id());
    },
  });
  //   }
};

const InitAll = () => {
  InitRudderstack(() => {
    InitPosthog();
  });
};

const TrackPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    let page = pathname && pathname.split("/")[1];
    if (!page) {
      rudderAnalytics.page("home");
    } else {
      rudderAnalytics.page(pathname);
    }
  }, [pathname]);

  return null;
};

const ClearAll = (force) => {
  if (!force && !rudderAnalytics.getUserId()) return;
  console.log(
    "befiore",
    rudderAnalytics.getUserId(),
    posthog.get_distinct_id()
  );
  rudderAnalytics.reset(true);
  posthog.reset(true);
  rudderAnalytics.setAnonymousId(posthog.get_distinct_id());
  console.log("after", rudderAnalytics.getUserId(), posthog.get_distinct_id());
};

export { rudderAnalytics, InitAll, TrackPage, ClearAll };
