
import React, {useEffect, useState} from 'react';
//import ReactPlayer from 'react-player'

import { Button, Spinner, Container, Modal, Form, Row, Col } from "react-bootstrap";
import { firestore, storage } from "../services/firebase";
import Masonry from 'react-masonry-css'
import moment from 'moment';
import * as _ from "lodash";
import firebase from "firebase";
import { NavLink, useHistory } from "react-router-dom";
import logoImg from '../assets/home/Header/welnes-Logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck, faSquare, faChevronRight, faTimes, faCheckCircle, faExclamationCircle, faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import StarRatings from 'react-star-ratings';


import frame from '../assets/subscribe/frame.png';

import screen_1 from '../assets/subscribe/screens/free-progress.gif';
import screen_2 from '../assets/subscribe/screens/free-support.gif';
import screen_3 from '../assets/subscribe/screens/free-community.gif';
import screen_4 from '../assets/subscribe/screens/free-capsules.gif';
import screen_5 from '../assets/subscribe/screens/program-mealplan.gif';
import screen_6 from '../assets/subscribe/screens/program-workoutplan.gif';
import screen_box from '../assets/home/Our Products/Welnes-Box.png';

import './Subscribe.scss';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";



import { events } from "../components/events";


import axios from 'axios';


import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import './Checkout.scss';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// Setup Stripe.js and the Elements provider
const stripePromise = loadStripe('pk_live_olYCygAG79BGQpAn9lrdRWBN00DZZ2Eb37');




export default function Subscribe(props) {



    const [bundleDiscount, setBundleDiscount] = useState({
        1: 0,
        2: 20,
        3: 27
    });
    const [oneToOneDiscount, setOneToOneDiscount] = useState({
        1: 0,
        2: 20,
        3: 27
    });
    const [bundleAmount, setBundleAmount] = useState({});
    const [oneToOneAmount, setOneToOneAmount] = useState({});
    const [selectedRounds, setSelectedRounds] = useState(3);
    const [selectedMonths, setSelectedMonths] = useState(1);
    const [orgAmount, setOrgAmount] = useState();
    const [amount, setAmount] = useState();
    const [currency, setCurrency] = useState();
    const [code, setCode] = useState('');
    const [applied, setApplied] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [invalidCode, setInvalidCode] = useState(false);
    const [promocode, setPromocode] = useState(false);
    const [promocodeId, setPromocodeId] = useState();
    const [challenge, setChallenge] = useState();
    const [location, setLocation] = useState();
    const [succeeded, setSucceeded] = useState(false);



    const [age, setAge] = useState();
    const [height, setHeight] = useState();
    const [weight, setWeight] = useState();



    const [loader, setLoader] = useState(true);
    const [post, setPosted] = useState();

    const [feed, setFeed] = useState([]);
    const [challenges, setChallenges] = useState([]);
    const [nutritionists, setNutritioinists] = useState([]);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('userDetails')));


    const [wizard, setWizard] = useState(0);
    const [nSteps, setnSteps] = useState(2);
    const [formValue, setFormValue] = useState({});

    const [imageFile, setImageFile] = useState([]);
    const [imageError, setImageError] = useState('');
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    const [isImageChanged, setIsImageChanged] = useState(false);
    const [nutritionistStatus, setNutritionistStatus] = useState();

    const [currentFeature, setCurrentFeature] = useState({});
    const [key, setKey] = useState();
    const [screen, setScreen] = useState();
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [programs, setPrograms] = useState([]);
    const [choose, setChoose] = useState();
    const [hideScreen, setHideScreen] = useState();
    const [total, setTotal] = useState();

    const [targetWeight, setTargetWeight] = useState();
    const [weightLoss, setWeightLoss] = useState();
    const [duration, setDuration] = useState();

    const [features, setFeatures] = useState([

        {key: 'track', screen: screen_1, required: true, selected: true, free: true, title: 'track your progress', description: 'check your uploaded meals, workouts, asked questions and activity, check your weight updates'},
        {key: 'share', screen: screen_2, required: true, selected: true, free: true, title: 'share your acheivments', description: 'upload your meals, workouts and get motivated by other users who share same interests'},
        {key: 'ask', screen: screen_3, required: true, selected: true, free: true, title: 'ask the community', description: 'gain knowlegde by asking the community, get answers from experts, check asked questions by other community members'},
        {key: 'capsules', screen: screen_4, required: true, selected: true, free: true, title: 'watch capsules', description: 'navigate through short informative videos by nutrition experts and trainers on welnes'},
        {key: 'mealplan', screen: screen_5, required: false, selected: false, free: false, title: 'get a meal plan', description: 'subscribe to a personalized mealplan that is prepared by nutritionists on welnes'},
        {key: 'workout', screen: screen_6, required: false, selected: false, free: false, title: 'get a workout plan', description: 'subscribe to a personalized workout plan that is prepared by coaches on welnes'},
        {key: 'box', screen: screen_box, required: false, selected: false, free: false, price: 2000, title: 'get program meals', description: 'order welnes box and get your program recipes right at your kitchen'},
    ]);

    useEffect(() => {

        document.body.style.background = "white";

        var index = 0

        setKey(features[index].key)
        setTitle(features[index].title)
        setScreen(features[index].screen)
        setDescription(features[index].description)
        setCurrentFeature(features[index])
        index++

        setInterval(function(){

            //setHideScreen(true);

            //setTimeout(function(){

                setKey(features[index].key)
                setTitle(features[index].title)
                setScreen(features[index].screen)
                setDescription(features[index].description)
                setCurrentFeature(features[index])

                index++
                if(index === features.length) index = 0

                //setHideScreen(false);

            //}, 1000)

        }, 10000)

        getCollections()

        return () => {

            console.log('Do some cleanup');
            //unsubscribes.map((unsubscribe) => unsubscribe())
        }

    }, []);

    useEffect(() => {

        var targetWeight = 45.5 + (0.91 * (height - 152.4))
        var weightLoss = weight - targetWeight
        var duration = Math.floor(Math.max(1, Math.min(3, weightLoss / 2.5)))

        setTargetWeight(targetWeight.toFixed(2))
        setWeightLoss(weightLoss.toFixed(2))
        setDuration(duration)

        if(duration) setSelectedMonths(duration)

        return () => {

            console.log('Do some cleanup');
            //unsubscribes.map((unsubscribe) => unsubscribe())
        }

    }, [age, weight, height]);

    useEffect(() => {

        var amount = features.map(d => d.selected && d.price ? d.price : 0).reduce((partialSum, a) => partialSum + a, 0)

        setTotal(amount)


        setOneToOneAmount({

            1: Math.round(((100-oneToOneDiscount[1])/100)*1*amount * (discount? (1-discount) : 1)),
            2: Math.round(((100-oneToOneDiscount[2])/100)*2*amount),
            3: Math.round(((100-oneToOneDiscount[3])/100)*3*amount),
        })

        setCurrency('egp')

        if(amount) setChallenge({price: amount, weeks: 4})

        return () => {

            console.log('Do some cleanup');
            //unsubscribes.map((unsubscribe) => unsubscribe())
        }

    }, [features]);



    async function getCollections(){

        setLoader(true);

        const nutritionistsRef = await firestore("users").where('role', 'array-contains', "nutritionist").get()
        var nutritionists = {} 

        nutritionistsRef.docs.map((d)=>{var obj = d.data(); obj.id = d.id; nutritionists[d.id] = obj;})

        //const challengesRef = await firestore("challenges").orderBy('datetime', 'desc').limit(10).get()
        //var challenges = _.orderBy(challengesRef.docs.length? challengesRef.docs.map((d)=>{var obj = d.data(); obj.id = d.id; return obj}) : [], 'datetime', 'desc')

        const subscribeFeeds = /*selectedChallenge?*/ firestore("one_to_one")

            //challenges.where('userId', '==', JSON.parse(localStorage.getItem('userDetails')).id)
            //.where('challengeId', '==', challenge.id)
            //.where('type', '==', "Nutritionist Video")
            .orderBy('datetime', 'desc')
            .limit(200)
            //.onSnapshot({includeMetadataChanges: true}, function(snapshot){
            .get().then(function(snapshot){

            var programs = _.orderBy(snapshot.docs.length? snapshot.docs.map((d)=>{var obj = d.data(); obj.id = d.id; obj.nutritionist=nutritionists[obj.nutritionistId]; return obj}) : [], 'datetime', 'desc').filter(d=> d.isPublished)

            programs = programs.sort((a,b)=>((b.nutritionist.ratings||0)*(b.nutritionist.rating||0))-((a.nutritionist.ratings||0)*(a.nutritionist.rating||0)))

            setPrograms(programs);

            setLoader(false);
        })
        //    : () => {}

        return [subscribeFeeds]
    }

    function fbImage(imageLink){

        if(!imageLink) return imageLink
        if(!imageLink.split) return imageLink
        var query = imageLink.split('asid=')[1]
        if(!query) return imageLink
        var fbId = query.split('&')[0]
        if(!fbId) return imageLink
            
        return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`
    }

    async function logout() {
        localStorage.clear();
        await firebase.auth().signOut();
    }

    async function submitForm(e) {
        e.stopPropagation();
        e.preventDefault();

        if(!imageFile.length) return alert('please upload certificates')

        setIsSubmitLoader(true)

        const dataToSubmit = _.cloneDeep(formValue);

        dataToSubmit.nutritionist = 'pending'
        dataToSubmit.nutritionistDatetime = moment.utc().format()

        Promise.all(imageFile.map(async(f, i) => {

            const fileName = dataToSubmit.name.replaceAll(' ','_')+'/'+f.name;
            const uploaded = await storage().ref().child(`/certificates/${fileName}`).put(f, {cacheControl: 'public,max-age=31536000'});
            const child = await storage().ref().child(uploaded.metadata.fullPath);
            return await child.getDownloadURL();
        }))
        .then(async(values) => {

            dataToSubmit.certificates = values

            console.log(dataToSubmit)

            await firestore('users').doc(user.id).update(dataToSubmit);

            setNutritionistStatus('pending')
            setIsSubmitLoader(false)
        });
    }

    async function toggle(title) {

        var lastState;
        var clone = _.clone(features)
        for(var prop in clone) {if(clone[prop].title == title) {clone[prop].selected = !clone[prop].selected; lastState = clone[prop].selected}}
        setFeatures(clone)

        if(title.includes('meal plan')) setChoose(!lastState? '' : 'nutrition')
        if(title.includes('workout plan')) setChoose(!lastState? '' : 'workout')
    }

    async function setNutrition(program) {

        var clone = _.clone(features)
        for(var prop in clone) {if(clone[prop].title.includes('meal plan')) {clone[prop].price = program.price; clone[prop].program = program;}}
        setFeatures(clone)
    }

    async function setWorkout(program) {

        var clone = _.clone(features)
        for(var prop in clone) {if(clone[prop].title.includes('workout plan')) {clone[prop].price = program.price; clone[prop].program = program}}
        setFeatures(clone)
    }


    return (<>
        <div fluid="md" id="Subscribe" className="" style={{background: '#fff'}}>


            <div className="resize" style={{color: '#464646', padding: '60px 0px', margin: '0 auto', width: '600px'}}>
            
                <div>

                    <span className="logo" style={{marginRight: '10px'}}><img height="60" style={{margin: '-10px'}} src={logoImg}/></span> is an app that helps you reach your perfect shape by letting you

                </div>

                <div style={{display: 'flex', marginTop: '50px'}}>

                    <div style={{color: '#464646', width: '300px', paddingTop: '7px', paddingRight: '30px'}}>

                        {features.map(d => <div 
                            onClick={()=>{!d.required && toggle(d.title);}} 
                            style={{
                                fontWeight: (d.title === title)? 'bold' : 'normal', 
                                borderRight: (d.title == title)? '3px solid #222' : 'none', 
                                height: Math.floor((605/features.length) - 10)+'px', 
                                cursor: d.required? 'default':'pointer', 
                                opacity: d.required? .7:1, 
                                background: '#f7f7f7', 
                                marginBottom: '10px', 
                                borderRadius: '10px', 
                                padding: '10px'
                            }}><div style={{width: '24px', height: '24px', padding: '0px 3px', border: '1px solid #ccc', display: 'inline-block', borderRadius: '5px', marginRight: '10px', verticalAlign: 'top'}}>

                                <FontAwesomeIcon icon={d.selected?faCheck:null} size="1x" />
                            </div>
                            {d.title}
                            {/*features.filter(d => d.key == 'mealPlan')[0].selected? */}

                            <div style={{float: 'right', fontSize: '13px', color: d.free? '#38CE91' : '#2E7191'}}>{d.selected ? (d.free? 'free' : (d.price? (d.price + ' EGP') : '')) : ''}</div>
                            <div style={{fontSize: '13px', margin: '5px 35px', fontWeight: 'normal', color: '#999'}}>{d.selected && d.program ? <div><img src={fbImage(d.program.nutritionistImage)} style={{display: 'none', width: '25px', height: '25px', borderRadius: '20px', objectFit: 'cover', boxShadow: '0 0 1px 2px #ccc', marginRight: '10px'}}/>{d.program.name}</div> : ''}</div>
                        </div>)}

                    </div>

                    <div style={{color: '#464646', width: '300px', height: '605px', position: 'relative'}}>

                        <div style={{color: '#464646', width: '100%', height: '100%', padding: '14px'}}>

                            <img src={screen} height="100%" width={currentFeature.key === 'box'? '300px' : 'auto'} style={{borderRadius: '10px', opacity: hideScreen? 0 : 1}}/>
                            {/*features.map(d => <img src={screen} height="100%" style={{borderRadius: '10px', position: 'absolute', top: 0, left: 0, opacity: (d.screen == screen)? 1 : 0}}/>)*/}
                        </div>

                        {currentFeature.key !== 'box' && <div style={{color: '#464646', width: '100%', height: '100%', top: 0, left: 0, position: 'absolute', zIndex: 1000, backgroundImage: `url("${frame}")`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'right'}}>
                           

                        </div>}

                    </div>
                </div>

                <p style={{fontSize: '16px', fontWeight: 'bold', marginTop: '20px', marginBottom: '30px', padding: '20px 10px', textAlign: 'center'}}>{description}</p>

            </div>

            <div md={12} style={{position: 'fixed', position: 'relative', paddingBottom: '50px', bottom: 0, left: 0, width: '100%'}}>

{!total ? <NavLink
                    className="hover-blur mw-50 "
                    key={`/download`} to={`/download`}>
                    <button style={{border: '1px solid #eee', display: 'block', width: '600px', height: '50px', margin: '10px auto'}}>Download App</button>
                </NavLink> : 

                <div>

                    <div style={{marginTop: '0', display: 'flex', color: '#464646', padding: '10px 0px', margin: '0 auto', width: '600px'}}>
                        
                        <Form.Control style={{borderRadius: '5px', fontSize: '.85em', margin: '0 5px'}} placeholder="Enter your age" value={age} onChange={e => {setAge(e.target.value);}} />
                        <Form.Control style={{borderRadius: '5px', fontSize: '.85em', margin: '0 5px'}} placeholder="Enter your height" value={height} onChange={e => {setHeight(e.target.value);}} />
                        <Form.Control style={{borderRadius: '5px', fontSize: '.85em', margin: '0 5px'}} placeholder="Enter your weight" value={weight} onChange={e => {setWeight(e.target.value);}} />
                    </div>

                    {targetWeight && weightLoss && duration ? <div style={{marginTop: '0', display: 'flex', color: '#464646', padding: '10px 0px', margin: '0 auto', width: '600px'}}>
                        
                        <Form.Control style={{borderRadius: '5px', fontSize: '.85em', margin: '0 5px'}} placeholder="target weight" value={"target weight: "+targetWeight} disabled />
                        <Form.Control style={{borderRadius: '5px', fontSize: '.85em', margin: '0 5px'}} placeholder="weight to lose" value={"weight to be lost: "+weightLoss} disabled />
                        <Form.Control style={{borderRadius: '5px', fontSize: '.85em', margin: '0 5px'}} placeholder="duration to target" value={"duration to target: "+duration+ ' months'} disabled />
                    </div> : null}



        <div className="checkout" style={{padding: 0}}>

            {!succeeded && challenge && <div className="challenge-title">
            {loader ? <div className="loader text-center"><Spinner animation="border" size={'sm'} role="status" /></div> : 
            <div>
                <span>


                    <div>
                            <div style={{width: '400px', maxWidth: '100%', margin: '0 auto', marginTop: '20px'}}>
                                    <div className={"price-option" + (selectedMonths === 3 ? " selected":"")} onClick={() => {setSelectedMonths(3)}}>
                                        <span className="price-option-rounds">3 months <div>({3*4} weeks)</div></span>
                                        <span className="price-option-discount">{oneToOneDiscount[3]}% discount</span>
                                        <span className="price-option-price">{oneToOneAmount[3]} {currency.toUpperCase()} <div>{Math.ceil(oneToOneAmount[3]/(3*4))} {currency.toUpperCase()}/week</div></span>
                                    </div>
                                    <div className={"price-option" + (selectedMonths === 2 ? " selected":"")} onClick={() => {setSelectedMonths(2)}}>
                                        <span className="price-option-rounds">2 months <div>({2*4} weeks)</div></span>
                                        <span className="price-option-discount">{oneToOneDiscount[2]}% discount</span>
                                        <span className="price-option-price">{oneToOneAmount[2]} {currency.toUpperCase()} <div>{Math.ceil(oneToOneAmount[2]/(2*4))} {currency.toUpperCase()}/week</div></span>
                                    </div>
                                    <div className={"price-option" + (selectedMonths === 1 ? " selected":"")} onClick={() => {setSelectedMonths(1)}}>
                                        <span className="price-option-rounds">1 month <div>({1*4} weeks)</div></span>
                                        <span className="price-option-price">{orgAmount && <span className="original">{orgAmount} {currency.toUpperCase()}</span>} {oneToOneAmount[1]} {currency.toUpperCase()} <div>{Math.ceil(oneToOneAmount[1]/(1*4))} {currency.toUpperCase()}/week</div></span>
                                    </div>
                                </div>
                    </div>

                </span>
                <span className={"promocode"+((challenge.oneToOne||challenge.isBundleable)?"":" promocode-single")}>

                    {!promocode && <div className={"promocode-div"}>
                        <span>
                            {applied? 'Promo code applied.' : 'Do you have a promo code?'}
                            <div>
                                {challenge.oneToOne && <div className={"promocode-note"}>*Apply only on 1 month payment</div>}
                                {challenge.isBundleable && <div className={"promocode-note"}>*Apply only on 1 round payment</div>}
                            </div>
                        </span>
                        <span className="add-promocode" onClick={()=>{setPromocode(true); if(challenge.isBundleable) setSelectedRounds(1); if(challenge.oneToOne) setSelectedMonths(1); if(window.mixpanel) window.mixpanel.track("Have_Promocode_Clicked");}}>Add promo code</span>
                    </div>}
                    {promocode && <div style={{display: 'block'}}>
                        <Form className="form" onSubmit={(e) => {submitForm(e)}}>
                            <FontAwesomeIcon icon={faTimes} size="1x" onClick={() => {setPromocode(false)}} className="close-promo"/>
                            <label>Promo code</label>
                            <div style={{display:'flex'}}>
                                <Form.Control required defaultValue={code} onChange={e => {
                                    setCode(e.target.value);
                                }}>
                                </Form.Control>
                                {applied?
                                <Button variant="primary" type="submit" className="submit-button applied">
                                    Applied!
                                </Button> :
                                <Button variant="primary" type="submit" className="submit-button">
                                    Apply
                                </Button>
                                }
                            </div>
                            {applied && <div className="response applied"><FontAwesomeIcon icon={faCheckCircle} size="1x"/>Promo code Applied!</div>}
                            {invalidCode && <div className="response invalid"><FontAwesomeIcon icon={faExclamationCircle} size="1x"/>invalid code!</div>}
                        </Form>
                    </div>}
                </span>
            </div>
            }
            </div>}
            
        </div>


                <NavLink
                    className="hover-blur mw-50 "
                    key={`/checkout/program`} to={`/checkout/program`}>
                    <button style={{border: '1px solid #eee', display: 'block', width: '600px', height: '50px', margin: '10px auto', color: '#eee', background: '#1B3D54'}}>
                        <span style={{fontWeight: 'normal', marginRight: '20px'}}>{selectedMonths? oneToOneAmount[selectedMonths] : total} EGP</span>
                        Checkout
                        <FontAwesomeIcon icon={faChevronRight} size="1x" style={{float: 'right', fontSize: '25px', marginRight: '10px'}} />
                    </button>
                </NavLink>

                </div>}
            </div>

        </div>

        <Modal show={choose} onHide={() => {choose === 'nutrition'? toggle('get a meal plan') : choose === 'workout'? toggle('get a workout plan') : toggle(''); setChoose(); }}>

            <Modal.Header closeButton>
                <Modal.Title>Select {choose === 'nutrition'? 'nutritionist' : choose === 'workout'? 'coach' : ''}</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{textAlign: 'center', padding: '0 0'}}>

                <div id="Programs" className="" style={{borderRadius: '10px'}}>

                    <div>
                        {loader ? <div className="loader text-center"><Spinner animation="border" size={'sm'} role="status" /></div> : 

                        <Container fluid="md">
                            <Row className="content">
                                <Col className="main" md={12}>

                                    {/*<h4>All nutrition programs</h4>*/}

                                    {programs.filter(d => (choose === 'nutrition' && d.features.mealPlan) || (choose === 'workout' && d.features.workoutPlan)).length? programs.filter(d => (choose === 'nutrition' && d.features.mealPlan) || (choose === 'workout' && d.features.workoutPlan)).map((program, i) =>

                                            <Col key={program.id} lg={12} md={12} sm={12} style={{float: 'left'}} className="nutritionist-col" onClick={()=>{setChoose(); if(choose === 'nutrition') setNutrition(program); if(choose === 'workout') setWorkout(program);}}>
                                                <div className="nutritionist">
                                                    
                                                    <img className="image" height="70" width="70" src={fbImage(program.nutritionist.image)}/>
                                                    <div style={{float: 'right', fontSize: '13px', color: '#38CE91', marginRight: '30px'}}>{program.price} EGP</div>
                                                    <div className="details">
                                                        <span className="name">{program.nutritionist.name} </span>
                                                        <span className="title">{program.nutritionist.nutritionistTitle} </span>
                                                        <span className="rating">
                                                            <StarRatings
                                                                rating={program.nutritionist.rating}
                                                                starDimension="12px"
                                                                starSpacing = "1px"
                                                                starRatedColor="#ffb400"
                                                                numberOfStars={5}
                                                                name='rating'
                                                            />
                                                            <span>{(program.nutritionist.rating||0).toFixed(2)} </span>
                                                            <span>({program.nutritionist.ratings} ratings) </span>
                                                        </span>
                                                        <span className="followers">{program.nutritionist.followersCount} followers</span>
                                                    </div>
                                                    <div className="arrow"></div>

                                                </div>
                                            </Col>
                                        ) :
                                    <div className="no-Home text-center">no programs</div>}
                                </Col>
                            </Row>
                        </Container>}
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    </>)
}
