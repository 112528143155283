
import React, {useEffect, useState} from 'react';
//import ReactPlayer from 'react-player'

import { Button, Spinner, Container, Modal, Form, Row, Col } from "react-bootstrap";
import { firestore, storage } from "../services/firebase";
import Masonry from 'react-masonry-css'
import moment from 'moment';
import * as _ from "lodash";
import firebase from "firebase";
import { NavLink, useHistory } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faComments} from '@fortawesome/free-solid-svg-icons'
//import logoImg from '../assets/icons/icon.png';
import logoImg from '../assets/images/Logo.png';
import appImg from '../assets/images/app.png';
import like from '../assets/images/dc-like-grey.png';
import likeActive from '../assets/images/dc-like.png';
import facebook from '../assets/images/fb.png';
import googlePlay from '../assets/images/google-play.png';
import appleStore from '../assets/images/apple-store.png';
import comingsoon from '../assets/images/comingsoon.png';
import './Start.scss';


import Img11 from '../assets/images/upload_plan.png';
import Img12 from '../assets/images/create_program.png';
import Img13 from '../assets/images/follow_up.png';
import Img21 from '../assets/images/group.png';
import Img22 from '../assets/images/measurements.png';
import Img23 from '../assets/images/leaderboard.png';

import Img_clients from '../assets/icons/clients.png';
import Img_countries from '../assets/icons/countries.png';
import Img_meals from '../assets/icons/meals.png';
import Img_exercises from '../assets/icons/exercises.png';
import Img_water_cups from '../assets/icons/water_cups.png';

import Img_capsules from '../assets/icons/capsules.png';
import Img_answer from '../assets/icons/answer.png';
import Img_quiz from '../assets/icons/quiz.png';


export default function Start(props) {

    const [loader, setLoader] = useState(true);
    const [post, setPosted] = useState();

    const [feed, setFeed] = useState([]);
    const [challenges, setChallenges] = useState([]);
    const [nutritionists, setNutritioinists] = useState([]);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('userDetails')));


    const [wizard, setWizard] = useState(0);
    const [nSteps, setnSteps] = useState(2);
    const [formValue, setFormValue] = useState({});

    const [imageFile, setImageFile] = useState([]);
    const [imageError, setImageError] = useState('');
    const [isSubmitLoader, setIsSubmitLoader] = useState(false);
    const [isImageChanged, setIsImageChanged] = useState(false);
    const [nutritionistStatus, setNutritionistStatus] = useState();

    useEffect(() => {

        if (window.location.href.indexOf("register") > -1) openWizard()

        if(user) getUserData()

        return () => {

            console.log('Do some cleanup');
            //unsubscribes.map((unsubscribe) => unsubscribe())
        }

    }, []);


    async function getUserData() {

        const querySnapshot = await firestore('users').doc(user.id).get();

        setFormValue({
            name: querySnapshot.data().name || '',
            mobileNumber: querySnapshot.data().mobileNumber || '',
            nutritionistTitle: querySnapshot.data().nutritionistTitle || '',
            nutritionistBio: querySnapshot.data().nutritionistBio || '',
            //assistantName: querySnapshot.data().assistantName || '',
        })

        setNutritionistStatus(querySnapshot.data().nutritionist || '')
    }


    function fbImage(imageLink){

        if(!imageLink) return imageLink
        if(!imageLink.split) return imageLink
        var query = imageLink.split('asid=')[1]
        if(!query) return imageLink
        var fbId = query.split('&')[0]
        if(!fbId) return imageLink
            
        return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`
    }

    async function logout() {
        localStorage.clear();
        await firebase.auth().signOut();
    }




    function onImageUpload(e) {
        fileSelectHandler(e)
    }

    async function submitForm(e) {
        e.stopPropagation();
        e.preventDefault();

        if(!imageFile.length) return alert('please upload certificates')

        setIsSubmitLoader(true)

        const dataToSubmit = _.cloneDeep(formValue);

        dataToSubmit.nutritionist = 'pending'
        dataToSubmit.nutritionistDatetime = moment.utc().format()

        Promise.all(imageFile.map(async(f, i) => {

            const fileName = dataToSubmit.name.replaceAll(' ','_')+'/'+f.name;
            const uploaded = await storage().ref().child(`/certificates/${fileName}`).put(f, {cacheControl: 'public,max-age=31536000'});
            const child = await storage().ref().child(uploaded.metadata.fullPath);
            return await child.getDownloadURL();
        }))
        .then(async(values) => {

            dataToSubmit.certificates = values

            console.log(dataToSubmit)

            await firestore('users').doc(user.id).update(dataToSubmit);

            setNutritionistStatus('pending')
            setIsSubmitLoader(false)
        });
    }

    function fileDragHover(e) {
        var fileDrag = document.getElementById('file-drag');
        e.stopPropagation();
        e.preventDefault();
        fileDrag.className = (e.type === 'dragover' ? 'hover' : 'modal-body file-upload');
    }

    async function fileSelectHandler(e) {
        // Fetch FileList object
        var files = e.target.files || e.dataTransfer.files;
        // Cancel event and hover styling
        fileDragHover(e);


        var m = document.getElementById('messages');
        //m.innerHTML = '';

        var _files = _.clone(imageFile)

        // Process all File objects
        for (var i = 0, f; f = files[i]; i++) {
            var options = {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 400
            }
            try {

                _files.push(f)

                setImageError('');
                m.innerHTML += '<strong>' + encodeURI(f.name) + '</strong><br/>';

                //document.getElementById('start').classList.add("hidden");
                document.getElementById('response').classList.remove("hidden");

            } catch (error) {
                console.log('error fileSelectHandler', error);
                setImageError('please upload a valid image file');
            }
        }

        setImageFile(_files);
        setIsImageChanged(true);
    }

    function openWizard(){

        if(!user) return window.location = '/register';
        setWizard(1)
    }

    //return (<div style={{textAlign: 'center'}}><img src={comingsoon} style={{padding: '10em'}}/></div>)

    /*return (
        <div className="page-container">
            <div className="data-container w-100 h-100 d-flex text-center justify-content-center flex-column">
                <div className="login-container shadow-lg">
                    <img
                        height="150"
                        src={logoImg}
                        alt="Welnes"
                    />
                    <div className="welcome-message-container">Welcome to Welnes!</div>

                </div>
            </div>
        </div>
    );*/


    return (<>
        <div fluid="md" id="Start" className="" style={{}}>

            <div className="resize" id="header" style={{background: '#fff', padding: '1.3em 14%'}}>

                <NavLink key={'/'} to={'/'} style={{cursor:'pointer', marginRight: '3em'}}>
                    <span className="logo"><img height="30" src={logoImg}/></span>
                </NavLink>

                <NavLink key={'/challenges'} to={'/challenges'} style={{cursor:'pointer', color: '#1E516F', margin: '0 .5em', verticalAlign: 'bottom', fontSize: '14px', fontWeight: 'bold', opacity: .9}}>Explore challenges</NavLink>
                {/*<NavLink key={'/activity'} to={'/activity'} style={{cursor:'pointer', color: '#1E516F', margin: '0 .5em', verticalAlign: 'bottom', fontSize: '14px', fontWeight: 'bold', opacity: .9}}>Activity</NavLink>*/}

            </div>

            <div className="resize" id="content-transform" style={{background: '#1E516F', color: '#fff', padding: '2% 14%', borderTop: '1px solid #aaa', borderBottom: '1px solid #aaa', display: 'flex', justifyContent: 'space-between'}}>

                <div style={{display: 'inline-block', marginTop: '5em', maxWidth: '50%'}} className="transform-lives">
                    <h1>Transform peoples' lives</h1>
                    <p>Create a group follow up or one to one follow up programs for clients around the world</p>

                    <Button style={{background: '#D18129', padding: '.7em 1em', margin: '2em 0'}} onClick={()=>{openWizard()}}>Start a program</Button>
                </div>

                <img src={appImg} style={{display: 'inline-block', width: '240px', maxWidth: '50%', height: 'fit-content'}}/>
            </div>

            <div className="resize" id="content-how" style={{background: '#fff', color: '#464646', padding: '2% 20%', textAlign: 'center', fontWeight: 'bold'}}>

                <h2>How does it work?</h2>
                <div className="line" style={{display: 'inline-block', background: '#bbb', height: '1px', width: '100px'}}></div>

                {/*<div className="resize-block" style={{display: 'inline-block', marginTop: '7%', display: 'flex', justifyContent: 'space-between'}}>
                    <div className="resize-block-div" style={{textAlign: 'center', paddingRight: '3.5em', width: '50%'}}>
                        <h4 style={{marginBottom: '1em'}}>1. Upload your plan</h4>
                        <p>
                            Create your own recipes or use recipes from Welnes database, then create your meal plans, and select exercises to create your workout plans.
                        </p>
                        <img width="100%" src={Img11} style={{display: 'inline-block', borderRadius: '5px'}}/>
                    </div>
                    <div className="resize-block-div" style={{textAlign: 'center', paddingLeft: '3.5em', width: '50%'}}>
                        <br className="resize-block-swap-show" style={{display: 'none'}}/>
                        <br className="resize-block-swap-show" style={{display: 'none'}}/>
                        <br className="resize-block-swap-show" style={{display: 'none'}}/>
                        <img className="resize-block-swap-hide" width="100%" src={Img12} style={{display: 'inline-block', borderRadius: '5px'}}/>
                        <h4 style={{marginTop: '1em'}}>2. Create a program</h4>
                        <p>
                            Start a group challenge with a specific start and end date, or follow up 1 to 1 on your clients for 2 months, or 3 months.
                        </p>
                        <img className="resize-block-swap-show" width="100%" src={Img12} style={{display: 'none', borderRadius: '5px'}}/>
                    </div>
                </div>*/}
                <div className="resize-block" style={{display: 'block', marginTop: '12%', display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <div className="resize-block-div" style={{textAlign: 'left', paddingRight: '2em', width: '50%'}}>
                        <h4 style={{marginBottom: '1em'}}>1. Upload your plan</h4>
                        <p style={{fontWeight: 'normal', fontSize: '1.1em'}}>
                            Create your own recipes or use recipes from Welnes database, then create your meal plans, and select exercises to create your workout plans.
                        </p>
                    </div>
                    <div className="resize-block-div" style={{textAlign: 'center', paddingLeft: '2em', width: '50%'}}>
                        <img width="100%" src={Img11} style={{display: 'inline-block', borderRadius: '5px'}}/>
                    </div>
                </div>
                <div className="resize-block reverse" style={{display: 'block', marginTop: '12%', display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <div className="resize-block-div" style={{textAlign: 'left', paddingLeft: '2em', width: '50%'}}>
                        <h4 style={{marginBottom: '1em'}}>2. Create a program</h4>
                        <p style={{fontWeight: 'normal', fontSize: '1.1em'}}>
                            Start a group challenge with a specific start and end date, or follow up 1 to 1 on your clients for 2 months, or 3 months.
                        </p>
                    </div>
                    <div className="resize-block-div" style={{textAlign: 'center', paddingRight: '2em', width: '50%'}}>
                        <img width="100%" src={Img12} style={{display: 'inline-block', borderRadius: '5px'}}/>
                    </div>
                </div>
                <div className="resize-block" style={{display: 'block', marginTop: '12%', display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    <div className="resize-block-div" style={{textAlign: 'left', paddingRight: '2em', width: '50%'}}>
                        <h4 style={{marginBottom: '1em'}}>3. Follow up on your clients</h4>
                        <p style={{fontWeight: 'normal', fontSize: '1.1em'}}>
                            Keep them motivated! Post videos, answer questions, update your plans and start live videos to engage with your clients.
                        </p>
                    </div>
                    <div className="resize-block-div" style={{textAlign: 'center', paddingLeft: '2em', width: '50%'}}>
                        <img width="100%" src={Img13} style={{display: 'inline-block', borderRadius: '5px'}}/>
                    </div>
                </div>
            </div>

            <div className="resize" id="content-impact" style={{background: '#1E516F', color: '#fff', padding: '2% 14%', borderTop: '1px solid #aaa', borderBottom: '1px solid #aaa', textAlign: 'center'}}>

                <h2>A global impact</h2>
                <div className="line" style={{display: 'inline-block', background: '#bbb', height: '1px', width: '100px'}}></div>

                <div className="resize-block" style={{display: 'inline-block', marginTop: '7%', display: 'flex', justifyContent: 'space-evenly'}}>
                    <div>
                        <img height="100px" src={Img_clients}/>
                        <h4 style={{marginBottom: '.5em', marginTop: '1em'}}>60K</h4>
                        <p>
                            Clients
                        </p>
                    </div>
                    <div>
                        <img height="100px" src={Img_countries}/>
                        <h4 style={{marginBottom: '.5em', marginTop: '1em'}}>84</h4>
                        <p>
                            Countries
                        </p>
                    </div>
                    <div>
                        <img height="100px" src={Img_meals}/>
                        <h4 style={{marginBottom: '.5em', marginTop: '1em'}}>500K</h4>
                        <p>
                            Uploaded meals
                        </p>
                    </div>
                    <div>
                        <img height="100px" src={Img_exercises}/>
                        <h4 style={{marginBottom: '.5em', marginTop: '1em'}}>100K</h4>
                        <p>
                            Played exercises
                        </p>
                    </div>
                    <div>
                        <img height="100px" src={Img_water_cups}/>
                        <h4 style={{marginBottom: '.5em', marginTop: '1em'}}>1M</h4>
                        <p>
                            Water cups
                        </p>
                    </div>
                </div>
            </div>

            <div className="resize" id="content-followers" style={{background: '#fff', color: '#464646', padding: '2% 14%', textAlign: 'center', fontWeight: 'bold'}}>

                <h2>How to get followers on Welnes?</h2>
                <div className="line" style={{display: 'inline-block', background: '#bbb', height: '1px', width: '100px'}}></div>

                <div className="resize-block" style={{display: 'inline-block', marginTop: '7%', display: 'flex', justifyContent: 'space-between', fontWeight: 'normal'}}>
                    <div style={{flexBasis: 0, flexGrow: .333}}>
                        <img height="100px" src={Img_capsules}/>
                        <h4 style={{marginBottom: '.5em', marginTop: '1em'}}>Share capsules</h4>
                        <p>
                            Post 30 sec. educational videos
                        </p>
                    </div>
                    <div style={{flexBasis: 0, flexGrow: .333}}>
                        <img height="100px" src={Img_answer}/>
                        <h4 style={{marginBottom: '.5em', marginTop: '1em'}}>Answer questions</h4>
                        <p>
                            Give users answers to their inquiries
                        </p>
                    </div>
                    <div style={{flexBasis: 0, flexGrow: .333}}>
                        <img height="100px" src={Img_quiz}/>
                        <h4 style={{marginBottom: '.5em', marginTop: '1em'}}>Create quizzes (Coming soon)</h4>
                        <p>
                            Gamify the learning experience
                        </p>
                    </div>
                </div>
            </div>

            <div className="resize" id="content-weight" style={{background: '#1E516F', color: '#fff', padding: '2% 14%', borderTop: '1px solid #aaa', borderBottom: '1px solid #aaa', textAlign: 'center'}}>

                <h2>Weight transformation just became fun!</h2>
                <h5>A fun and efficient tracking tool</h5>
                <div className="line" style={{display: 'inline-block', background: '#bbb', height: '1px', width: '100px'}}></div>

                <div className="resize-block" style={{display: 'inline-block', marginTop: '7%', display: 'flex', justifyContent: 'space-evenly', fontSize: '1.1em'}}>
                    <div className="resize-block-div" style={{width: '30%'}}>
                        <img height="600" src={Img21} style={{display: 'inline-block', marginBottom: '1em'}}/>
                        <p>
                            Check what your clients eat on a daily basis
                        </p>
                    </div>
                    <div className="resize-block-div" style={{width: '30%'}}>
                        <img height="600" src={Img22} style={{display: 'inline-block', marginBottom: '1em'}}/>
                        <p>
                            Check the measurements of your clients
                        </p>
                    </div>
                    <div className="resize-block-div" style={{width: '30%'}}>
                        <img height="600" src={Img23} style={{display: 'inline-block', marginBottom: '1em'}}/>
                        <p>
                            Motivate, give badges and see the top score
                        </p>
                    </div>
                </div>
            </div>

            <div className="resize" id="content-faqs" style={{background: '#fff', color: '#464646', padding: '2% 14%', textAlign: 'center'}}>

                <h2>FAQs</h2>
                <div className="line" style={{display: 'inline-block', background: '#bbb', height: '1px', width: '100px'}}></div>

                <div className="resize-block" style={{display: 'inline-block', marginTop: '7%', display: 'flex', justifyContent: 'space-evenly'}}>
                    <div className="resize-block-div" style={{textAlign: 'left', margin: '0 2em', width: '50%'}}>
                        <h4 style={{marginBottom: '.5em'}}>As a nutritionist, can I start a challenge with a meal plan only?</h4>
                        <p style={{paddingBottom: '1em'}}>
                            Yes, you can start a challenge with a meal plan only without providing a workout plan. You can also collaborate with a certified fitness coach on Welnes to provide a workout plan and answer questions that are related to it.
                        </p>
                        <h4 style={{marginBottom: '.5em'}}>As a nutritionist, do I have to upload new recipes to create a meal plan?</h4>
                        <p style={{paddingBottom: '1em'}}>
                            It’s recommended to upload your unique recipes but of course you can choose from hundreds of recipes on Welnes.
                        </p>
                    </div>
                    <div className="resize-block-div" style={{textAlign: 'left', margin: '0 2em', width: '50%'}}>
                        <h4 style={{marginBottom: '.5em'}}>As a fitness coach, can I start a challenge with a workout plan only?</h4>
                        <p style={{paddingBottom: '1em'}}>
                            Yes, you can start a challenge with a workout plan only without providing a meal plan. You can also collaborate with a certified nutritionist on Welnes to provide a meal plan and answer questions that are related to it.
                        </p>
                        <h4 style={{marginBottom: '.5em'}}>As a fitness coach, do I have to shoot videos of all exercises I use in my workout plan?</h4>
                        <p style={{paddingBottom: '1em'}}>
                            It’s recommended to use your own videos but of course you can use Welnes’ videos for the exercises in your workout plan.
                        </p>
                    </div>
                </div>
            </div>

            <div className="resize testimonial" id="content-program" style={{background: '#1E516F', color: '#fff', padding: '2% 14%', borderTop: '1px solid #aaa', textAlign: 'center', fontWeight: 'normal'}}>

                <h2>Start your program</h2>
                <div className="line" style={{display: 'inline-block', background: '#bbb', height: '1px', width: '100px'}}></div>
                        
                <p style={{marginTop: '1em'}}>
                    We have changed lives by connecting dietitians and fitness coaches with clients in a fun and engaging way
                </p>

                <div className="resize-block" style={{display: 'inline-block', marginTop: '7%', display: 'flex', justifyContent: 'space-between', fontSize: '15px'}}>
                    <div className="resize-block-div" style={{margin: '1.5em', border: '1px solid rgba(200,200,200,.7)', borderRadius: '.5em', width: '30%', textAlign: 'left', padding: '1.5em', borderRadius: '40px', overflow: 'hidden'}}>
                        <p>
                            Welnes provides me with a great platform to host a big number of customers while giving me the chance to interact with them on daily basis by posts, videos and even live streams.
                        </p>
                        <div>
                            <img height="60" src={'https://graph.facebook.com/1623906884428561/picture?type=large&redirect=true&width=500&height=500'} style={{display: 'inline-block', float: 'left', borderRadius: '30px', marginRight: '.5em'}}/>
                            <div style={{height: '60px', fontSize: '13px', fontWeight: 'bold'}}>

                                <span style={{display: 'block', whiteSpace: 'nowrap'}}>Waad Adel Amin</span>
                                <span style={{display: 'block', whiteSpace: 'nowrap', opacity: .7}}>Fitness nutritionist</span>
                                <span style={{display: 'block', whiteSpace: 'nowrap'}}>Intermitent Fasting Group</span>
                            </div>
                        </div>
                    </div>
                    <div className="resize-block-div" style={{margin: '1.5em', border: '1px solid rgba(200,200,200,.7)', borderRadius: '.5em', width: '30%', textAlign: 'left', padding: '1.5em', borderRadius: '40px', overflow: 'hidden'}}>
                        <p>
                            Welnes is providing me as a nutritionist with the tools to grow my practice online in a simple yet innovative way. My clients became more engaged and my work is getting easier day after day.
                        </p>
                        <div>
                            <img height="60" src={'https://graph.facebook.com/2657903561146420/picture?type=large&redirect=true&width=500&height=500'} style={{display: 'inline-block', float: 'left', borderRadius: '30px', marginRight: '.5em'}}/>
                            <div style={{height: '60px', fontSize: '13px', fontWeight: 'bold'}}>

                                <span style={{display: 'block', whiteSpace: 'nowrap'}}>Kareem Gamal</span>
                                <span style={{display: 'block', whiteSpace: 'nowrap', opacity: .7}}>Consultant of Behavioral Nutrition</span>
                                <span style={{display: 'block', whiteSpace: 'nowrap'}}>The Slim Game</span>
                            </div>
                        </div>
                    </div>
                    <div className="resize-block-div" style={{margin: '1.5em', border: '1px solid rgba(200,200,200,.7)', borderRadius: '.5em', width: '30%', textAlign: 'left', padding: '1.5em', borderRadius: '40px', overflow: 'hidden'}}>
                        <p>
                            The most important thing welnes helped me with is adding value to more people, make it accessible for everyone to start their health & fitness journey and leaving a successful impact on their life.                        
                        </p>
                        <div>
                            <img height="60" src={'https://graph.facebook.com/10158924679640479/picture?type=large&redirect=true&width=500&height=500'} style={{display: 'inline-block', float: 'left', borderRadius: '30px', marginRight: '.5em'}}/>
                            <div style={{height: '60px', fontSize: '13px', fontWeight: 'bold'}}>

                                <span style={{display: 'block', whiteSpace: 'nowrap'}}>Bahaa Elbrolosy</span>
                                <span style={{display: 'block', whiteSpace: 'nowrap', opacity: .7}}>Strength And Conditioning Coach</span>
                                <span style={{display: 'block', whiteSpace: 'nowrap'}}>Functional Training</span>
                            </div>
                        </div>
                    </div>
                </div>
            
                <Button style={{background: '#D18129', padding: '.7em 2em', margin: '2em 0'}} onClick={()=>{openWizard()}}>Start a program</Button>

            </div>

        </div>

        <Modal show={Boolean(wizard)} onHide={() => {}}>
            {(nutritionistStatus === 'pending' || nutritionistStatus === 'approved' || nutritionistStatus === 'rejected' /*|| (user.role && user.role.includes('nutritionist'))*/)?

            <Modal.Body style={{textAlign: 'center', padding: '5em 0'}}>

                <div>
                    <span style={{position: 'absolute', top: '15px', right: '15px', cursor: 'pointer'}} onClick={() => {setWizard(0)}}>Exit</span>
                </div>

                <h5 style={{marginBottom: '2em'}}>Your profile has been submitted successfully.</h5>

                <Button variant="secondary" style={{background: '#1F516F'}} className="submit-button" onClick={(e) => {window.open('https://dashboard.welnes.app')}}>
                    open dashboard
                </Button>

                <Button variant="secondary" style={{background: '#1F516F', marginLeft: '1em'}} className="submit-button" onClick={(e) => {window.open('https://welnes.app/download')}}>
                    download app
                </Button>

            </Modal.Body>
            :
            <Modal.Body>

                <div>
                
                    <span style={{paddingRight: '10px', marginRight: '10px', borderRight: '1px solid #f5f5f5'}}><img height="26" src={logoImg}/></span>
                    <span style={{}}>step {wizard} of {nSteps}</span>
                    <span style={{float: 'right', color: '#1F516F', cursor: 'pointer'}} onClick={() => {setWizard(0)}}>Exit</span>
                </div>

                <div style={{marginTop: '10px', width: '100%', height: '3px', background: '#f0f0f0'}}>
                    
                    <div style={{width: ((wizard/nSteps)*100)+'%', height: '3px', background: '#1F516F'}}>
                        
                    </div>
                </div>

                <Form className="form" style={{marginTop: '10px'}} onSubmit={(e) => {
                    //submitForm(e)
                }}>
                    {wizard === 1 &&
                    <Form.Group>

                        <h3 style={{margin: '2em 0'}}>Tell us more about you</h3>

                        <Form.Row>
                        <Col xs={6}>
                        <Form.Label>Name</Form.Label>
                        <Form.Control style={{borderRadius: '5px'}} required placeholder="" value={formValue.name} onChange={e => {
                            const formValueClone = _.clone(formValue);
                            formValueClone.name = e.target.value;
                            setFormValue(formValueClone);
                        }} />
                        </Col>
                        <Col xs={6}>
                        <Form.Label>Mobile number</Form.Label>
                        <Form.Control style={{borderRadius: '5px'}} required placeholder="" value={formValue.mobileNumber} onChange={e => {
                            const formValueClone = _.clone(formValue);
                            formValueClone.mobileNumber = e.target.value;
                            setFormValue(formValueClone);
                        }} />
                        </Col>
                        </Form.Row>
                        <br/>


                        <Form.Label>Title</Form.Label>
                        <Form.Control style={{borderRadius: '5px'}} required placeholder="Nutritionist, Fitness coach" value={formValue.nutritionistTitle} onChange={e => {
                            const formValueClone = _.clone(formValue);
                            formValueClone.nutritionistTitle = e.target.value;
                            setFormValue(formValueClone);
                        }} />
                        <br/>
                        <Form.Label>Biography</Form.Label>
                        <Form.Control style={{borderRadius: '5px'}} as="textarea" rows="3" required placeholder="I'm a clinical dietitian with 5 years experience .. etc." value={formValue.nutritionistBio} onChange={e => {
                            const formValueClone = _.clone(formValue);
                            formValueClone.nutritionistBio = e.target.value;
                            setFormValue(formValueClone);
                        }} />
                        <br/>

                    </Form.Group>
                    }

                    {wizard === 2 &&

                    <Form.Group>
                    
                        <h3 style={{margin: '2em 0'}}>Upload your certificates</h3>

                        <Form.Label>Certificates</Form.Label>

                        <div className="d-flex photo-container">
                            <div className="uploader">
                                <input id="file-upload" type="file" name="fileUpload" multiple accept="image/*" required
                                    onChange={(e) => onImageUpload(e)} />
                                <label htmlFor="file-upload" id="file-drag"
                                    onDragOver={(e) => {
                                        fileDragHover(e)
                                    }} onDragLeave={(e) => {
                                        fileDragHover(e)
                                    }} onDrop={(e) => {
                                        fileSelectHandler(e)
                                    }}>
                                    <div id="start">
                                        <span id="file-upload-btn" className="btn btn-light" style={{color: '#1F516F', border: '1px solid #ddd'}}>Add image files</span>
                                    </div>
                                    <div id="response" className="hidden">
                                        <div id="messages"></div>
                                    </div>
                                </label>
                            </div>
                        </div>

                    </Form.Group>
                    }

                    {wizard === 3 &&

                    <Form.Group>

                        <Form.Row>
                        <Col xs={6}>
                        <Form.Label>Exercise Name</Form.Label>
                        <Form.Control required placeholder="e.g squats" value={formValue.name} onChange={e => {
                            const formValueClone = _.clone(formValue);
                            formValueClone.name = e.target.value;
                            setFormValue(formValueClone);
                        }} />
                        </Col>
                        <br/>

                        {/*<Form.Label>Duration</Form.Label>
                        <Form.Control required placeholder="duration of exercise in minutes" type="number" value={formValue.duration} onChange={e => {
                            const formValueClone = _.clone(formValue);
                            formValueClone.duration = e.target.value;
                            setFormValue(formValueClone);
                        }} />
                        <br/>*/}

                        <Col xs={6}>
                        <Form.Label>No. of sets</Form.Label>
                        <Form.Control required placeholder="minimum no. of sets" type="number" value={formValue.sets} onChange={e => {
                            const formValueClone = _.clone(formValue);
                            formValueClone.sets = e.target.value;
                            setFormValue(formValueClone);
                        }} />
                        </Col>
                        {/*<Col xs={6}>
                        <Form.Label>Count per set</Form.Label>
                        <Form.Control placeholder="no. of reps" type="number" value={formValue.reps} onChange={e => {
                            const formValueClone = _.clone(formValue);
                            formValueClone.reps = e.target.value;
                            setFormValue(formValueClone);
                        }} />
                        </Col>*/}
                        </Form.Row>
                        <br/>

                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows="3" placeholder="description of exercise" value={formValue.description} onChange={e => {
                            const formValueClone = _.clone(formValue);
                            formValueClone.description = e.target.value;
                            setFormValue(formValueClone);
                        }} />
                        <br/>

                        <Form.Label>Video Link</Form.Label>
                        <Form.Control placeholder="Youtube link" value={formValue.videoLink} onChange={e => {
                            const formValueClone = _.clone(formValue);
                            formValueClone.videoLink = e.target.value;
                            setFormValue(formValueClone);
                        }} />
                    </Form.Group>
                    }


                    {wizard === 4 &&

                    <Form.Group>

                        <Form.Label>Workout Plan</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control as="select" placeholder="Select a workout plan" value={formValue.workoutPlanId} onChange={e => {
                                const formValueClone = _.clone(formValue);
                                formValueClone.workoutPlanId = e.target.value;
                                const index = props.workoutPlans.findIndex((o) => {
                                    return o.key === formValueClone.workoutPlanId;
                                });
                                if (index > -1) {
                                    formValueClone.workoutPlanName = props.workoutPlans[index].name;
                                }
                                setFormValue(formValueClone);
                            }}><option value="" key="">Select Workout Plan</option>
                                {[].map((workoutPlan, i) => {
                                    return (<option value={1} key={1}>{1}</option>)
                                })}
                            </Form.Control>
                        </div>
                        <br/>
                        <br/>

                        <Form.Label>Day</Form.Label>
                        <div className="selectWrapper">
                            <Form.Control required as="select" value={formValue.day} placeholder="Day" onChange={e => {
                                const formValueClone = _.clone(formValue);
                                formValueClone.day = Number(e.target.value);
                                setFormValue(formValueClone);
                            }} >
                                {Array.from(Array(10), (a, i) => {
                                    return (<option value={i + 1} key={i + 1}>{i + 1}</option>)
                                })}
                            </Form.Control>
                        </div>
                        <br/>
                        <br/>

                    </Form.Group>
                    }

                    <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                        <Button variant={wizard===1?"light":"secondary"} style={wizard===1?{color: '#1F516F', border: '1px solid #1F516F', pointerEvents:'none'}:{background: '#1F516F', pointerEvents:'auto'}} className="float-right submit-button" onClick={() => {setWizard(wizard-1)}}>
                            Previous
                        </Button>
                        <Button variant="secondary" style={{background: '#1F516F'}} className="float-right submit-button" onClick={(e) => {wizard === nSteps? submitForm(e) : (formValue.name&&formValue.nutritionistTitle&&formValue.nutritionistBio&&formValue.mobileNumber)? (!formValue.mobileNumber.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g)? alert('please enter a valid phone number') : setWizard(wizard+1)) : alert('please enter required fields')}}>
                            {isSubmitLoader ? <Spinner animation="border" size={'sm'} role="status" /> : wizard==nSteps?'Submit': 'Continue'}
                        </Button>
                    </div>
                    
                </Form>
            </Modal.Body>}
        </Modal>
    </>)
}
