
import React, {useEffect, useState} from 'react';
//import ReactPlayer from 'react-player'

import { Spinner } from "react-bootstrap";
import { firestore, storage } from "../services/firebase";
import Masonry from 'react-masonry-css'
import moment from 'moment';
import * as _ from "lodash";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faComments} from '@fortawesome/free-solid-svg-icons'
import logoImg from '../assets/icons/icon.png';
import like from '../assets/images/dc-like-grey.png';
import likeActive from '../assets/images/dc-like.png';
import facebook from '../assets/images/fb.png';
import googlePlay from '../assets/images/google-play.png';
import appleStore from '../assets/images/apple-store.png';
import comingsoon from '../assets/images/comingsoon.png';
import './Download.scss';



export default function Download(props) {

    const [loader, setLoader] = useState(true);
    const [submitLoader, setIsSubmitLoader] = useState([]);
    const [imageFile, setImageFile] = useState();
    const [post, setPosted] = useState();

    const [feed, setFeed] = useState([]);
    const [challenges, setChallenges] = useState([]);
    const [nutritionists, setNutritioinists] = useState([]);


    useEffect(() => {

        function getMobileOperatingSystem() {
          var userAgent = navigator.userAgent || navigator.vendor || window.opera;

              // Windows Phone must come first because its UA also contains "Android"
            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone";
            }

            if (/android/i.test(userAgent)) {
                return "Android";
            }

            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }
         
            return "unknown";
        }  

        var platform = getMobileOperatingSystem()
        if(platform == "iOS") window.location = "https://apps.apple.com/eg/app/welnes/id1513854681"
        if(platform == "Android") window.location = "https://play.google.com/store/apps/details?id=com.welnes"


        return () => {}

    }, []);


    async function postImage(challenge, groups, targetGroups){

        setIsSubmitLoader(true);

        const fileName = 'video_' + Number(new Date());
        const uploaded = await storage().ref().child(`/feed_Home/${fileName}`).put(imageFile);
        const child = await storage().ref().child(uploaded.metadata.fullPath);
        const videoLink = await child.getDownloadURL();
        const fullPath = uploaded.metadata.fullPath;

        var userDetails = (JSON.parse(localStorage.getItem('userDetails')));

        var post = {

            challengeName: challenge.name,
            challengeId: challenge.id,
            userId: userDetails.id,
            userName: userDetails.name,
            type: 'Nutritionist Video',
            videoLink: videoLink,
            fileType: 'video',
            likesCount: 0,
            groupIds: ['none'],
            userImage: userDetails.image,
            datetime: new Date().toISOString(),
        }

        Array.prototype.push.apply(post.groupIds, groups.map((d)=>d.id))

        //var targetGroups = [];
        //groups.map((group) => {if(targetGroups.includes(group.id)) {targetGroups.push(group.id)}})
        //post.groups = groups.filter((group) => )

        console.log(post)

        firestore("feeds").add(post)
            .then(function(doc) {

                setIsSubmitLoader(false);
                //setVideoPosted('video posted to '+ post.challengeName + ' feed')
                setPosted('posted to challenge feed.')
                setTimeout(function(){setPosted(false)}, 10000)

                return
            })
            .catch(function(error) {

                console.error("Error finding/updating user document: ", error);
            });
    }

    function fbImage(imageLink){

        if(!imageLink) return imageLink
        if(!imageLink.split) return imageLink
        var query = imageLink.split('asid=')[1]
        if(!query) return imageLink
        var fbId = query.split('&')[0]
        if(!fbId) return imageLink
            
        return `https://graph.facebook.com/${fbId}/picture?type=large&redirect=true&width=500&height=500`
    }


    //return (<div style={{textAlign: 'center'}}><img src={comingsoon} style={{padding: '10em'}}/></div>)

    /*return (
        <div className="page-container">
            <div className="data-container w-100 h-100 d-flex text-center justify-content-center flex-column">
                <div className="login-container shadow-lg">
                    <img
                        height="150"
                        src={logoImg}
                        alt="Welnes"
                    />
                    <div className="welcome-message-container">Welcome to Welnes!</div>

                </div>
            </div>
        </div>
    );*/


    return (<>
        <div id="Download" className="home-download" style={{position: 'fixed',height:'100vh',overflow:'hidden'}}>

            <div className="welcome">
                <div className="shade"></div>
                <div className="join" style={{paddingTop: '20vh', position: 'absolute'}}>

                    <span className="logo"><img height="60" src={logoImg}/></span>

                    <div className="download welnes-links">
                        <span onClick={() => {window.open('https://play.google.com/store/apps/details?id=com.welnes&hl=en')}}><img height="60" src={googlePlay}/></span>
                        <span onClick={() => {window.open('https://apps.apple.com/eg/app/welnes/id1513854681')}}><img height="60" src={appleStore}/></span>
                    </div>
                    
                    <div className="download gorilla-links">
                        <span onClick={() => {window.open('https://play.google.com/store/apps/details?id=com.dietchallenge')}}><img height="60" src={googlePlay}/></span>
                        <span onClick={() => {window.open('https://apps.apple.com/eg/app/gorilla-fit/id1498609215')}}><img height="60" src={appleStore}/></span>
                    </div>

                    {/*<img className="facebook" height="60" src={facebook}/>  to join dieters and nutritionists from all over the world*/}

                    <span className="note welnes-note">Download welnes app to start the challenge</span>
                    <span className="note gorilla-note">Download Gorilla Fit app to start the challenge</span>

                </div>
            </div>

            <div className="background-health-container">
                <div className="background-health-shade">

                </div>
            </div>
        </div>
    </>)
}
