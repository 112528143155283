
import React, {useEffect, useState} from 'react';
//import ReactPlayer from 'react-player'

import { Button, Spinner } from "react-bootstrap";
import { firestore, storage } from "../services/firebase";
import Masonry from 'react-masonry-css'
import moment from 'moment';
import * as _ from "lodash";
import firebase from "firebase";
import { NavLink, useHistory } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faComments} from '@fortawesome/free-solid-svg-icons'
import logoImg from '../assets/icons/icon.png';
import like from '../assets/images/dc-like-grey.png';
import likeActive from '../assets/images/dc-like.png';
import facebook from '../assets/images/fb.png';
import googlePlay from '../assets/images/google-play.png';
import appleStore from '../assets/images/apple-store.png';
import comingsoon from '../assets/images/comingsoon.png';
import gorilla from '../assets/images/gorilla.png';

import './AppPage.scss';


export function AppPage(props) {

    useEffect(() => {

        function getMobileOperatingSystem() {
          var userAgent = navigator.userAgent || navigator.vendor || window.opera;

              // Windows Phone must come first because its UA also contains "Android"
            if (/windows phone/i.test(userAgent)) {
                return "Windows Phone";
            }

            if (/android/i.test(userAgent)) {
                return "Android";
            }

            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "iOS";
            }

            return "unknown";
        }  

        var platform = getMobileOperatingSystem()

        if(platform == "Windows Phone") document.getElementById("text").innerHTML = "sorry, diet challenge app currently doesn't support windows phones"
        if(platform == "iOS") window.location = "https://apps.apple.com/eg/app/gorilla-fit/id1498609215"
        if(platform == "Android") window.location = "https://play.google.com/store/apps/details?id=com.dietchallenge"
        if(platform == "unknown"){

          document.getElementById("text").innerHTML = '<br><img id="apple" onclick="javascript:window.open(\'https://apps.apple.com/eg/app/gorilla-fit/id1498609215\');" src="'+appleStore+'">'
          document.getElementById("text").innerHTML += '<br><img id="google-play" onclick="javascript:window.open(\'https://play.google.com/store/apps/details?id=com.dietchallenge\');" src="'+googlePlay+'">'
        }

        return () => {}

    }, []);


    return (<>
        <div id="app">
            <img id="logo" src={gorilla}/>
            <div id="text">detecting device..</div>
        </div>
    </>)
}
