
import React, {useEffect, useState} from 'react';
import firebase from "firebase"
import FirebaseAuth from "react-firebaseui/FirebaseAuth"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import { firestore, storage } from "../../services/firebase";
import { events } from "../../components/events";
import { Button, Spinner, Container, Row, Col, Tabs, Tab, Form } from "react-bootstrap";
import { NavLink, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import './LoginModal.scss'

/* images */
import logoImg from '../../assets/icons/icon.png';
import facebook from '../../assets/images/fb.png';
import { rudderAnalytics } from '../../services/trackers';

export function Login(props) {

    useEffect(() => {

        events.add({page: 'login', challenge: {id: props.challenge.id, name: props.challenge.name || props.challenge.nutritionistName}})

        return () => {

            console.log('Do some cleanup');
        }

    }, []);

    async function logout() {
        localStorage.clear();
        await firebase.auth().signOut();
    }

    async function createUser(user, cb){

        var userObject = {

            id: user.uid.substring(0,20),
            datetime: moment.utc().format(),
            created: firebase.firestore.FieldValue.serverTimestamp(),
            name: user.displayName,
            image: user.photoURL?user.photoURL+'?type=large&width=500&height=500':'',
            email: user.email,
            uid: user.uid,
            type: (user.providerData && user.providerData.length)? user.providerData[0].providerId : '',
            web: true
        }

        try {

            const resp = await axios.get('https://ipapi.co/json/');
            console.log('geo', resp.data)
            userObject.country = resp.data.country_code

        } 
        catch (err){

            userObject.country = 'N/A'
            console.error('get location error', err);
        }

        firestore('users').doc(userObject.id).set(userObject).then(userRef => {
        //firestore('users').add(userObject).then(userRef => {

            //userObject.id = userRef.id;

            localStorage.setItem('userDetails', JSON.stringify(userObject));

            if(window.mixpanel) window.mixpanel.identify(userObject.id);

            if(window.mixpanel) window.mixpanel.track("signup", {user: userObject.name});
            rudderAnalytics.identify(userObject.uid);
            rudderAnalytics.track('User_Sign_Up',{ userObject });

            cb(userObject)
        });
    }
    
    return (<>

        <div id="Login-Modal">

            <div className="welcome">
                <div className="join">


                    {/*<img className="facebook" height="60" src={facebook}/> to join the challenge*/}

                    <FirebaseAuth
                        firebaseAuth={firebase.auth()}
                        uiConfig={{
                            signInFlow: "popup",
                            signInOptions: [
                                firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                                'apple.com',
                                firebase.auth.EmailAuthProvider.PROVIDER_ID,
                            ],
                            callbacks: {
                                //signInSuccess: () => false,
                                signInSuccessWithAuthResult: (authResult) => {

                                    if(authResult.user && authResult.user.uid) {

                                        const user = authResult.user

                                        firestore('users').where('uid', '==', user.uid).limit(1).get().then(snapshot => {

                                            if(snapshot && snapshot.docs && snapshot.docs.length) {

                                                const userDoc = snapshot.docs[0];
                                                var userDetails = userDoc.data();

                                                /*if(redirect === 'checkout'){

                                                    if(userDetails.gender && userDetails.birthday && userDetails.height && userDetails.weight){

                                                        window.location = '/checkout';
                                                    }
                                                    else{

                                                        window.location = '/profile';
                                                    }
                                                }
                                                else if(redirect){  //back to nutritionist page

                                                    if(userDetails.gender && userDetails.birthday && userDetails.height && userDetails.weight){

                                                        window.location = '/'+redirect;
                                                    }
                                                    else{
                                                        
                                                        window.location = '/profile/'+redirect;
                                                    }
                                                }
                                                else{

                                                    window.location = '/profile';
                                                }*/

                                                if(window.mixpanel) window.mixpanel.track("signin", {user: userDetails.name});

                                                events.add({page: 'signin', challenge: {id: props.challenge.id, name: props.challenge.name || props.challenge.nutritionistName}, ...(userDetails) && {user: {id: userDetails.id, name: userDetails.name, type: userDetails.type || ''}}}, ()=> {

                                                    if(props.callback) props.callback(userDetails);
                                                })
                                            }
                                            else{   //new user

                                                createUser(user, (userDetails) => {

                                                    //window.location = '/profile';
                                                    let provider= (user.providerData && user.providerData.length)? user.providerData[0].providerId : '';
                                                    rudderAnalytics.track('User_Sign_Up', { provider: provider });
                                                    events.add({page: 'signup', challenge: {id: props.challenge.id, name: props.challenge.name || props.challenge.nutritionistName}, ...(userDetails) && {user: {id: userDetails.id, name: userDetails.name, type: userDetails.type || ''}}}, ()=> {

                                                        if(props.callback) props.callback(userDetails);
                                                    })
                                                });
                                            }
                                        });
                                    }
                                    else{

                                        //window.location = '/profile';
                                    }
                                    
                                    // Do not automatically redirect.
                                    return false;
                                },  
                                //signInSuccessUrl: '/checkout',
                            }
                        }}
                    />

                    {/*<div>to join the challenge</div>*/}
                    {/*<div>to proceed with payment</div>*/}

                </div>
            </div>
        </div>
    </>);
}
